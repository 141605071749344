import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { AirspaceElement, CopiedMapData, FilterLayer, MapLayers, MapLayersType } from '../model/map.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { layersStyle } from '../map/layersStyle';
import { Styles } from '../model/layers.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private http = inject(HttpClient);
  private layerSelectBehaviorSubject = new BehaviorSubject<string | undefined>(undefined);
  private $formLayerSelect = this.layerSelectBehaviorSubject.asObservable();

  private selectedRow = new BehaviorSubject<CopiedMapData | undefined>(undefined);
  private $selectedRow = this.selectedRow.asObservable();
  readonly API_URL: string = '/api/airspace';
  readonly API_URL_OBSERVER: string = '/api/airspace/aup';
  readonly layerStyles = layersStyle;

  mapLayers: WritableSignal<MapLayers> = signal({
    type: 'RQA',
    data: []
  });
  filterLayerTypes: WritableSignal<FilterLayer[]> = signal([]);

  fetchMapLayers(layersType: MapLayersType): Observable<AirspaceElement[]> {
    return this.http.get<AirspaceElement[]>(layersType === 'AUP' ? this.API_URL_OBSERVER : this.API_URL).pipe(
      tap((layers) => {
        this.mapLayers.set({
          type: layersType,
          data: layers.map((layer) => ({
            ...layer,
            styles: this.setStylesForLayer(layer.type)
          }))
        });
      })
    );
  }

  updateMapLayers(designators: string[], type: MapLayersType) {
    const newLayers = this.mapLayers()
      .data.filter((value) => designators.includes(value.designator) && value.type !== type)
      .map((airspace) => ({
        ...airspace,
        type: type
      }));

    this.mapLayers.update((current) => ({
      ...current,
      data: [...current.data.filter((el) => el.type !== type), ...newLayers]
    }));
  }

  setLayerSelect(data: string | undefined) {
    this.layerSelectBehaviorSubject.next(data);
  }

  getLayerSelect(): Observable<string | undefined> {
    return this.$formLayerSelect;
  }

  selectRowToCopy(data: CopiedMapData | undefined) {
    this.selectedRow.next(data);
  }

  getSelectedRowToCopy(): Observable<CopiedMapData | undefined> {
    return this.$selectedRow;
  }

  setFilterLayerTypes(filterLayerTypes: FilterLayer[]) {
    this.filterLayerTypes.set(filterLayerTypes);
  }

  private setStylesForLayer(layerType: string): Styles {
    return this.layerStyles[layerType.toLowerCase()].styles;
  }
}
