<form [formGroup]="dateAndAltitudes">
  <div class="flex flex-row gap-2 mb-2 align-items-center" formGroupName="datesGroup">
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lat">{{ 'adhoc.timeFrom' | translate }}</label>

      <p-calendar
        [minDate]="minDate"
        appendTo="body"
        dateFormat="yy-mm-dd"
        formControlName="startDate"
        [showTime]="true"
        [showIcon]="true"
        [hideOnDateTimeSelect]="false"
        #startDateCalendar
      >
        <ng-template pTemplate="footer">
          <div class="flex justify-content-between">
            <p-button
              [label]="'buttons.cancel' | translate"
              [text]="true"
              size="small"
              icon="pi pi-times"
              (click)="startDateCalendar.overlayVisible = false"
            ></p-button>
            <p-button
              [label]="'buttons.confirm' | translate"
              icon="pi pi-check"
              size="small"
              (click)="startDateCalendar.overlayVisible = false"
            ></p-button>
          </div>
        </ng-template>
      </p-calendar>
      <div class="h-1rem">
        <rqa-validator [control]="dateAndAltitudes.get('datesGroup')?.get('startDate')"></rqa-validator>
        <rqa-validator [formGroup]="dateAndAltitudes.get('datesGroup')"></rqa-validator>
      </div>
    </div>
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lon">{{ 'adhoc.timeTo' | translate }}</label>

      <p-calendar
        [minDate]="minDate"
        formControlName="endDate"
        appendTo="body"
        dateFormat="yy-mm-dd"
        [showTime]="true"
        [showIcon]="true"
        [showSeconds]="false"
        [hideOnDateTimeSelect]="false"
        #endDateCalendar
      >
        <ng-template pTemplate="footer">
          <div class="flex justify-content-between">
            <p-button
              [label]="'buttons.cancel' | translate"
              [text]="true"
              size="small"
              icon="pi pi-times"
              (click)="endDateCalendar.overlayVisible = false"
            ></p-button>
            <p-button
              [label]="'buttons.confirm' | translate"
              icon="pi pi-check"
              size="small"
              (click)="endDateCalendar.overlayVisible = false"
            ></p-button>
          </div>
        </ng-template>
      </p-calendar>
      <rqa-validator [control]="dateAndAltitudes.get('datesGroup')?.get('endDate')" class="h-1rem"></rqa-validator>
    </div>
  </div>
  <div class="flex flex-row gap-2 mb-2 align-items-center" formGroupName="altitudesGroup">
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lat">{{ 'adhoc.heightFrom' | translate }}</label>
      <p-autoComplete
        appendTo="body"
        [suggestions]="filteredAltitudes"
        formControlName="lowerAltitude"
        (completeMethod)="searchAltitude($event, 'lowerAltitude')"
        [forceSelection]="true"
        [completeOnFocus]="true"
      ></p-autoComplete>
      <div class="h-1rem">
        <rqa-validator
          [control]="dateAndAltitudes.get('altitudesGroup')?.get('lowerAltitude')"
          class="h-1rem"
        ></rqa-validator>
        <rqa-validator [formGroup]="dateAndAltitudes.get('altitudesGroup')"></rqa-validator>
      </div>
    </div>
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lon">{{ 'adhoc.heightTo' | translate }}</label>
      <p-autoComplete
        appendTo="body"
        formControlName="upperAltitude"
        [suggestions]="filteredAltitudes"
        (completeMethod)="searchAltitude($event, 'upperAltitude')"
        [forceSelection]="true"
        [completeOnFocus]="true"
      ></p-autoComplete>
      <rqa-validator
        [control]="dateAndAltitudes.get('altitudesGroup')?.get('upperAltitude')"
        class="h-1rem"
      ></rqa-validator>
    </div>
  </div>
</form>
