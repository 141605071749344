<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
    <div>
      <h1 class="text-2xl mx-0 my-0 title">{{ 'observer.title' | translate }}</h1>
    </div>
  </section>
  <section>
    <p-table
      #dt
      [value]="aupList$ | async"
      dataKey="uuid"
      [styleClass]="'pansa-table'"
      [scrollable]="true"
      scrollHeight="600px"
      [responsive]="true"
      responsiveLayout="stack"
      [breakpoint]="'960px'"
    >
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th class="text-xs" pSortableColumn="name">
            {{ 'observer.table.name' | translate }} <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="dateFrom">
            {{ 'observer.table.date' | translate }} <p-sortIcon field="dateFrom"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="status">
            {{ 'observer.table.status' | translate }} <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="text-sm cursor-pointer">
          <td (click)="showDetails(item)">
            <span class="p-column-title">{{ 'observer.table.name' | translate }}</span>
            {{ item.name }}
          </td>
          <td (click)="showDetails(item)">
            <span class="p-column-title">{{ 'observer.table.date' | translate }}</span>
            {{ item.dateFrom }} - {{ item.dateTo }}
          </td>
          <td class="py-1">
            <span class="p-column-title">{{ 'observer.table.status' | translate }}</span>
            <rqa-status-info [statusName]="item.status" class="flex" />
          </td>
          <td class="py-1 text-sm justify-content-center">
            <p-button
              icon="pi pi-angle-right"
              [rounded]="true"
              [text]="true"
              (click)="showDetails(item)"
              styleClass="hidden lg:block"
            />
            <p-button
              label="{{ 'observer.table.showDetails' | translate }}"
              icon="pi pi-angle-right"
              (click)="showDetails(item)"
              styleClass="lg:hidden"
            />
          </td>
        </tr>
      </ng-template>
    </p-table>
  </section>
</div>
