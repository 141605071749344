import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { altitudeArray } from 'src/app/model/altitudes.model';
import { COORDS_DECIMAL_REGEX, COORDS_DMS_REGEX } from '../utils/coordinates.utils';
import { DATE_TIME_FORMAT } from '../constants/date.constants';

const findDistinctChars = (string1: string, string2: string): string[] => {
  const set1 = new Set(string1);
  const set2 = new Set(string2);
  return [...set1].filter((char) => !set2.has(char));
};

export const remarksValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let value = control.value;
  const regex = new RegExp("^[A-Z0-9-?:().,'=/+\\\\\\n\\r]{0,1000}$");
  if (regex.test(value)) {
    return null;
  }
  if (value) {
    const errors: ValidationErrors = {};
    value = value.replace(/[^A-Z0-9-?:().,'=/+ \\ \n\r]+/g, '');
    const values = findDistinctChars(control.value, value);
    if (values.length) {
      errors['pattern'] = true;
      errors['values'] = findDistinctChars(control.value, value);
      return errors;
    }
  }
  return null;
};

export const checkPasswords = (group: FormGroup): ValidationErrors | null => {
  const pass = group.controls['password'].value;
  const confirmPass = group.controls['confirm'].value;

  return pass === confirmPass ? null : { notSame: true };
};

export function validateLatitude(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const valueAsString = control.value.toString();
      const splittedLatDegrees: number = +valueAsString.substring(0, 2);
      const splittedLatMinutes: number = +valueAsString.substring(2, 4);
      const splittedLatSeconds: number = +valueAsString.substring(4);

      if (control.value.length === 6 && !control.value.match(COORDS_DMS_REGEX)) {
        return { pattern: true };
      }

      if (splittedLatDegrees < 49 || splittedLatDegrees > 55 || splittedLatMinutes >= 60 || splittedLatSeconds >= 60) {
        return { invalidLatitude: true };
      }
      if (splittedLatDegrees === 54 && splittedLatMinutes > 50) {
        return { invalidLatitude: true };
      }
      if (splittedLatDegrees === 54 && splittedLatMinutes === 50 && splittedLatSeconds > 0) {
        return { invalidLatitude: true };
      }
    }

    return null;
  };
}

export function validateLongitude(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const valueAsString = control.value.toString();
      const splitLonDegrees: number = valueAsString.substring(0, 2);
      const splitLonMinutes: number = valueAsString.substring(2, 4);
      const splitLonSeconds: number = valueAsString.substring(4);

      if (control.value.length === 6 && !control.value.match(COORDS_DMS_REGEX)) {
        return { pattern: true };
      }

      if (splitLonDegrees < 14 || splitLonDegrees > 25 || splitLonMinutes >= 60 || splitLonSeconds >= 60) {
        return { invalidLongitude: true };
      }

      if (splitLonDegrees === 24 && splitLonMinutes > 9) {
        return { invalidLongitude: true };
      }

      if (splitLonDegrees === 24 && splitLonMinutes === 9 && splitLonSeconds > 0) {
        return { invalidLongitude: true };
      }
    }

    return null;
  };
}

export function validateDecimalLongitude(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value && control.value !== 0) {
      return null;
    }

    if (control.value.length === 7 && !control.value.match(COORDS_DECIMAL_REGEX)) {
      return { pattern: true };
    }

    const val = parseFloat(control.value);
    // Sprawdzenie czy wartość jest liczbą i mieści się w zakresie dla długości geograficznej
    if (isNaN(val) || val < -180 || val > 180) {
      return { invalidFormat: true };
    }

    // Sprawdzenie czy wartość mieści się w zakresie dla Polski
    if (val < 14.122 || val > 24.145) {
      return { outOfPoland: true };
    }

    return null;
  };
}
export function validateDecimalLatitude(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value && control.value !== 0) {
      return null;
    }

    if (control.value.length === 7 && !control.value.match(COORDS_DECIMAL_REGEX)) {
      return { pattern: true };
    }

    const val = parseFloat(control.value);
    // Sprawdzenie czy wartość jest liczbą i mieści się w zakresie dla szerokości geograficznej
    if (isNaN(val) || val < -90 || val > 90) {
      return { invalidFormat: true };
    }

    // Sprawdzenie czy wartość mieści się w zakresie dla Polski
    if (val < 49.0 || val > 54.83) {
      return { outOfPoland: true };
    }

    return null;
  };
}

export function startDateBeforeEndDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const startDate = control.get('startDate')?.value;
  const endDate = control.get('endDate')?.value;

  if (startDate && endDate && moment(startDate).isSameOrAfter(moment(endDate).format(DATE_TIME_FORMAT), 'minutes')) {
    return { startDateAfterEndDate: true };
  }

  return null;
}

export function altitudesValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const lowerAltitude = control.get('lowerAltitude')?.value;
  const upperAltitude = control.get('upperAltitude')?.value;
  const lowerIdx = altitudeArray.findIndex((el) => el === lowerAltitude);
  const upperIdx = altitudeArray.findIndex((el) => el === upperAltitude);

  if (lowerAltitude && upperAltitude && lowerIdx >= upperIdx) {
    return { lowerAltitudeHigherThanUpper: true };
  }

  return null;
}

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value) {
      const regex = /^[+]?[0-9 ]{7,19}$/;
      const valid = regex.test(control.value);
      return valid ? null : { invalidPhone: true };
    }
    return null;
  };
}
