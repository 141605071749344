import { inject, Injectable, signal, WritableSignal } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AirspaceElement, MapLayersType } from 'src/app/model/map.model';
import { layersStyle } from 'src/app/map/layersStyle';
import { Styles } from 'src/app/model/layers.model';

@Injectable({
  providedIn: 'root'
})
export class AupMapService {
  private http = inject(HttpClient);
  readonly API_URL: string = '/api/airspace/aup';
  readonly layerStyles = layersStyle;

  mapLayers: WritableSignal<AirspaceElement[]> = signal([]);

  fetchAupMapLayers(): Observable<AirspaceElement[]> {
    return this.http.get<AirspaceElement[]>(this.API_URL).pipe(
      tap((layers) => {
        this.mapLayers.set(
          layers.map((layer) => ({
            ...layer,
            styles: this.setStylesForLayer(layer.type)
          }))
        );
      })
    );
  }

  updateMapLayers(designators: string[], type: MapLayersType) {
    const newLayers = this.mapLayers()
      .filter((value) => designators.includes(value.designator) && value.type !== type)
      .map((airspace) => ({
        ...airspace,
        type: type
      }));
    this.mapLayers.update((current) => [...current.filter((el) => el.type !== type), ...newLayers]);
  }

  private setStylesForLayer(layerType: string): Styles {
    return this.layerStyles[layerType.toLowerCase()].styles;
  }
}
