import { Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { generatePluralNames } from 'src/app/shared/utils/plural-names.util';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogManagerService } from 'src/app/services/dialog-manager.service';
import { TooltipModule } from 'primeng/tooltip';
import { RqaTableComponent } from './rqa-table/rqa-table.component';
import { RqaFormComponent } from './rqa-form/rqa-form.component';
import { SaveTemplateDialogComponent } from '../../templates/save-template-dialog/save-template-dialog.component';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
@Component({
  selector: 'rqa-create',
  templateUrl: './rqa-create.component.html',
  styleUrls: ['./rqa-create.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    PanelModule,
    DividerModule,
    ButtonModule,
    InputTextModule,
    RqaTableComponent,
    RqaFormComponent,
    TranslateModule,
    TooltipModule
  ]
})
export class RqaCreateComponent {
  @ViewChild(RqaFormComponent) rqaFormRef!: RqaFormComponent;
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  private dialogManager = inject(DialogManagerService);
  private layoutUtilsService = inject(LayoutUtilsService);
  reservationsCount: string = `0 ${this.translateService.instant('multipleDesignator')}`;
  panelCollapsed: boolean = true;
  templateName: string = '';
  resetTableAfterSend = false;
  isValidTable: boolean = false;
  ref: DynamicDialogRef;

  countChange(reservationsCount: number): void {
    this.reservationsCount = `${reservationsCount} ${generatePluralNames(
      [
        this.translateService.instant('singularDesignator'),
        this.translateService.instant('pluralDesignator'),
        this.translateService.instant('multipleDesignator')
      ],
      reservationsCount
    )}`;
  }

  checkValidTable(isValid: boolean): void {
    this.isValidTable = isValid;
  }

  sendRqa(): void {
    this.panelCollapsed = false;
    this.rqaFormRef.onNgSubmit();
  }

  saveTemplate(): void {
    this.ref = this.dialogService.open(SaveTemplateDialogComponent, {
      header: this.translateService.instant('dialogs.saveTemplate.header'),
      contentStyle: { overflow: 'auto' },
      width: '500px',
      baseZIndex: 10000,
      data: { remarks: this.rqaFormRef.rqaForm.get('remarks')?.value }
    });
    this.dialogManager.addDialog(this.ref);
  }

  onPanelToggle(): void {
    setTimeout(() => {
      this.layoutUtilsService.calculateTableHeightForRqa();
    }, 500);
  }
}
