import { Component, effect, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NotesService } from 'src/app/services/note.service';
import { NoteDTO } from 'src/app/model/notes.model';
import { ValidatorComponent } from '../validator/validator.component';
import { MessageService } from 'primeng/api';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'rqa-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    InputTextareaModule,
    ValidatorComponent
  ]
})
export class NotesComponent implements OnInit {
  private layoutUtilsService = inject(LayoutUtilsService);
  private fb = inject(FormBuilder);
  private noteService = inject(NotesService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private dateService = inject(DateService);
  isPanelVisible: boolean = false;
  form = this.createForm();
  notes: NoteDTO[] = [];

  constructor() {
    effect(() => {
      this.isPanelVisible = this.layoutUtilsService.notesVisible();
    });
  }

  ngOnInit(): void {
    this.getNotes();
  }

  togglePanel(): void {
    this.layoutUtilsService.notesVisible.set(false);
  }

  addNote(): void {
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      this.noteService.create(payload.content as string).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.noteAdded'),
            life: 3000
          });
          this.form.reset();
          this.getNotes();
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            life: 3000
          });
        }
      });
    }
  }

  private getNotes() {
    this.noteService.getNotes().subscribe((notes) => {
      this.notes = notes.map((el) => {
        return {
          ...el,
          created: this.dateService.convertDateToUserTimezone(el.created)
        };
      });
    });
  }

  private createForm() {
    return this.fb.group({
      content: ['', [Validators.required, Validators.maxLength(1500)]]
    });
  }
}
