import { Injectable, signal, WritableSignal } from '@angular/core';
import { WindowSize } from '../model/layout.model';

@Injectable({
  providedIn: 'root'
})
export class LayoutUtilsService {
  isMobileDevice: WritableSignal<boolean | undefined> = signal(false);
  windowSize: WritableSignal<WindowSize> = signal({ width: window.innerWidth, height: window.innerHeight });
  notesVisible: WritableSignal<boolean> = signal(false);
  contentHeight: WritableSignal<number> = signal(0);
  rqaTableHeight: WritableSignal<string> = signal('');

  calculateRemToPx(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  calculateContentHeight(): void {
    const headerHeight = document.querySelector('.page-header')?.clientHeight || 0;
    const holidaysHeight = document.querySelector('.holidays-info')?.clientHeight || 0;
    const totalOffset = headerHeight + holidaysHeight + 2;
    this.contentHeight.set(window.innerHeight - totalOffset);
  }

  getTableHeightForOrders(): string {
    const contentHeight = this.contentHeight();
    const ordersHeader = document.querySelector('.orders-header')?.clientHeight || 0;
    const tabView = document.querySelector('.p-tabview-nav')?.clientHeight || 0;
    const tableHeader = document.querySelector('.p-datatable-header')?.clientHeight || 0;
    const tableFooter = document.querySelector('.p-datatable-tfoot')?.clientHeight || 0;
    const paddings = this.calculateRemToPx(2);
    return `${contentHeight - ordersHeader - tabView - tableHeader - tableFooter - paddings}px`;
  }

  calculateTableHeightForRqa(): void {
    if (this.isMobileDevice()) {
      this.rqaTableHeight.set('100%');
      return;
    }
    const contentHeight = this.contentHeight();
    const rqaHeader = document.querySelector('.dashboard-header')?.clientHeight || 0;
    const bookerPanel = document.querySelector('.booker-panel')?.clientHeight || 0;
    const tableButtons = document.querySelector('.table-buttons')?.clientHeight || 0;
    const tableHeader = document.querySelector('.p-datatable-header')?.clientHeight || 0;
    const paddings = this.calculateRemToPx(6);
    const result = contentHeight - rqaHeader - bookerPanel - tableButtons - tableHeader - paddings;
    if (result > 150) {
      this.rqaTableHeight.set(`${result}px`);
    } else {
      this.rqaTableHeight.set('150px');
    }
  }

  getTableHeightForAupReservations(showGraph: boolean): string {
    if (this.isMobileDevice()) {
      return '100%';
    }
    const contentHeight = this.contentHeight();
    const filtersHeight = showGraph ? 0 : document.querySelector('.filters-form')?.clientHeight || 0;
    const aupHeader = document.querySelector('.aup-header')?.clientHeight || 0;
    const paddings = this.calculateRemToPx(8);
    const graphHeight = showGraph ? this.calculateRemToPx(13) : 0;

    return `${contentHeight - filtersHeight - aupHeader - graphHeight - paddings}px`;
  }

  getTableHeightForTemplates(): string {
    if (this.isMobileDevice()) {
      return '100%';
    }
    const contentHeight = this.contentHeight();
    const templatesHeader = document.querySelector('.templates-header')?.clientHeight || 0;
    const tableFooter = document.querySelector('.p-datatable-tfoot')?.clientHeight || 0;
    const paddings = this.calculateRemToPx(2);
    return `${contentHeight - templatesHeader - tableFooter - paddings}px`;
  }
}
