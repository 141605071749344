import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FilterService } from 'primeng/api';
import { DATE_TIME_FORMAT } from '../shared/constants/date.constants';

@Injectable({
  providedIn: 'root'
})
export class CustomFilterService {
  constructor(private filterService: FilterService) {}

  configureFilterService(): Promise<void> {
    this.filterService.register('dateSameAfter', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;
      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format(DATE_TIME_FORMAT) : null;
      return formattedDate ? moment(formattedDate).isSameOrAfter(moment(filter)) : true;
    });

    this.filterService.register('dateSameBefore', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;
      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format(DATE_TIME_FORMAT) : null;
      return formattedDate ? moment(formattedDate).isSameOrBefore(moment(filter)) : true;
    });

    const containsFilter = this.filterService.filters['contains'];

    this.filterService.register('containsWithDateFormat', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;

      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format(DATE_TIME_FORMAT)?.toString() : null;

      return formattedDate ? formattedDate.includes(filter) : containsFilter(value, filter);
    });
    return Promise.resolve();
  }
}
