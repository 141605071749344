<div class="flex justify-content-start">
  <span class="switch">
    <input
      [id]="'switch-rounded-' + label"
      type="checkbox"
      (change)="changed.emit($event.target.checked)"
      [checked]="checked"
    />
    <label [for]="'switch-rounded-' + label" [class]="className" [ngClass]="{ disabled: !checked }"></label>
  </span>
  <span class="pl-1">{{ label }}</span>
</div>
