import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InputMaskModule } from 'primeng/inputmask';
import { CheckboxModule } from 'primeng/checkbox';
import { ReservationService } from 'src/app/services/reservation.service';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { phoneValidator, remarksValidator } from 'src/app/shared/form/validators';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { markFormGroupDirty } from 'src/app/shared/utils/form.utils';
import { UserConfigService } from 'src/app/services/user-config.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { RqaMapService } from '../../rqa-map/rqa-map.service';
import { MapLayerTypeEnum } from 'src/app/model/map.model';
import { TemplatesService } from '../../../../services/templates.service';
import { NoPolishCharsDirective } from 'src/app/shared/directives/no-polish-chars.directive';

@Component({
  selector: 'rqa-form',
  templateUrl: './rqa-form.component.html',
  styleUrls: ['./rqa-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    InputTextareaModule,
    CheckboxModule,
    AutoCompleteModule,
    ValidatorComponent,
    TranslateModule,
    NoPolishCharsDirective
  ]
})
export class RqaFormComponent implements OnInit {
  private reservationService = inject(ReservationService);
  private messageService = inject(MessageService);
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private userConfigService = inject(UserConfigService);
  private rqaMapService = inject(RqaMapService);
  private templateService = inject(TemplatesService);

  rqaForm: FormGroup = this.fb.group({
    phoneNumber: this.fb.control('', [phoneValidator(), Validators.required]),
    contactPerson: this.fb.control('', [Validators.maxLength(50)]),
    addRemarks: this.fb.control(false),
    remarks: this.fb.control('', [remarksValidator, Validators.maxLength(1000)])
  }) as FormGroup;
  phoneNumbers: string[];
  filteredPhoneNumbers: string[] | undefined;

  ngOnInit(): void {
    this.userConfigService.userConfig$.subscribe((userConfig) => {
      if (userConfig) {
        this.phoneNumbers = userConfig?.phoneNumbers;
      }
    });
    this.templateService.getRemarks().subscribe((value) => {
      if (value) {
        this.rqaForm.controls['addRemarks'].patchValue(true);
        this.rqaForm.controls['remarks'].patchValue(value);
      }
    });
  }

  filterPhone(event: AutoCompleteCompleteEvent) {
    const query = event.query.toLowerCase();
    this.filteredPhoneNumbers = this.phoneNumbers.filter((el) => el.toLowerCase().includes(query));
  }

  onNgSubmit() {
    markFormGroupDirty(this.rqaForm);
    if (this.rqaForm.valid) {
      this.confirmationService.confirm({
        acceptLabel: this.translateService.instant('dialogs.confirm'),
        acceptButtonStyleClass: 'p-button',
        rejectLabel: this.translateService.instant('dialogs.reject'),
        rejectButtonStyleClass: 'p-button-text p-button-plain',
        message: this.translateService.instant('dialogs.sendRqa.msg'),
        header: this.translateService.instant('dialogs.sendRqa.header'),
        accept: () => this.sendRqa()
      });
    }
  }

  sendRqa(): void {
    const payload = this.rqaForm.getRawValue();
    this.reservationService.send(payload).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.rqaSentSuccess'),
          life: 3000
        });
        this.rqaForm.reset();
        this.reservationService.setClearTable(true);
        this.rqaMapService.updateMapLayers([], MapLayerTypeEnum.RQA);
      },
      error: (err) => {
        if (err) {
          console.log(err);
          let message;
          if (err.error['validation-errors'].length) {
            message = err.error['validation-errors'][0].message;
          }
          this.messageService.add({
            severity: 'error',
            detail: message ?? this.translateService.instant('notifications.rqaSentError'),
            life: 3000
          });
        }
      }
    });
  }
}
