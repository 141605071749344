import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { AupReservation, ChartBoxOverlap, ChartLegendItem } from 'src/app/model/observer.model';
import { Chart, registerables } from 'chart.js';
import ChartAnnotation, { EventContext } from 'chartjs-plugin-annotation';
import {
  formatTimestampToStringTime,
  normalizeFromAupTime,
  normalizeUntilAupTime
} from 'src/app/shared/utils/time.utils';
import { ButtonModule } from 'primeng/button';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { BadgeModule } from 'primeng/badge';
import * as moment from 'moment';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { altitudeArrayObserver, getAltitudeIndex } from 'src/app/model/altitudes.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-designator-graph',
  templateUrl: './designator-graph.component.html',
  styleUrls: ['./designator-graph.component.scss'],
  standalone: true,
  imports: [CommonModule, ChartModule, ButtonModule, BadgeModule, OverlayPanelModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignatorGraphComponent implements OnInit, OnChanges {
  @Input() data: AupReservation[] = [];
  designators: { designator: string; count: number }[] = [];
  chart: Chart | null = null;
  legendData: { [responsibleUnit: string]: ChartLegendItem } = {};
  responsibleUnitToIndexes: { [responsibleUnit: string]: number[] } = {};
  selectedResponsibleUnits: Record<string, boolean> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  boxAnnotations: Record<string, any> = {};
  responsibleUnitColors: Map<string, number> = new Map();

  ngOnInit(): void {
    document.addEventListener('click', (event: MouseEvent) => {
      this.hideTooltip();
      this.resetBoxBorderWidth(event);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.mapResponsibleUnitColors();
      this.updateChart();
      this.designators = this.countDesignators();
    }
  }

  mapResponsibleUnitColors(): void {
    this.responsibleUnitColors.clear();
    let colorIndex = 0;
    const uniqueResponsibleUnits = Array.from(new Set(this.data.map((reservation) => reservation.responsibleUnit)));

    uniqueResponsibleUnits.forEach((responsibleUnit) => {
      if (!this.responsibleUnitColors.has(responsibleUnit)) {
        this.responsibleUnitColors.set(responsibleUnit, colorIndex % 9);
        colorIndex++;
      }
    });
  }

  drawChart() {
    Chart.register(...registerables, ChartAnnotation);
    const canvas = document.getElementById('chartCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              min: this.getMinFromValue(),
              max: this.getMaxUntilValue(),
              afterBuildTicks: (scale) => {
                const tickValues: number[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(this.boxAnnotations).forEach((box: any) => {
                  tickValues.push(box.xMin, box.xMax);
                });
                const uniqueTicks = Array.from(new Set(tickValues)).sort((a, b) => a - b);
                scale.ticks = uniqueTicks.map((value) => ({ value }));
              },
              ticks: {
                callback: (value) => {
                  const date = new Date(value);
                  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
                },
                font: {
                  size: 11
                }
              }
            },
            y: {
              min: 0,
              max: this.getMaxFlMaxValue(),
              afterBuildTicks: (scale) => {
                const tickValues: number[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(this.boxAnnotations).forEach((box: any) => {
                  tickValues.push(box.yMin, box.yMax);
                });
                const uniqueTicks = Array.from(new Set(tickValues)).sort((a, b) => a - b);
                scale.ticks = uniqueTicks.map((value) => ({ value }));
              },
              ticks: {
                callback: (value) => {
                  return value;
                },
                font: {
                  size: 11
                }
              }
            }
          },
          plugins: {
            annotation: {
              click: (context: EventContext, e: ChartEvent) => {
                this.showTooltip(context, e);
                this.handleAnnotationClick(context);
              },
              annotations: this.generateBoxAnnotations()
            }
          }
        }
      });
    }
  }

  updateChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.drawChart();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showTooltip(context: any, event: any) {
    const idx = context.element.options.id;
    const reservation = this.data[idx];

    if (!reservation) return;

    const tooltipElement = document.getElementById('tooltip');

    if (tooltipElement) {
      tooltipElement.style.display = 'block';
      tooltipElement.style.left = `${event.x - 40}px`;
      tooltipElement.style.top = `${event.y - 30}px`;
      tooltipElement.innerHTML = `${reservation.designator} - ${reservation.responsibleUnit}<br> ${reservation.flMin} - ${reservation.flMax} <br>${reservation.from} - ${reservation.until}`;
    }
  }

  hideTooltip() {
    const tooltipElement = document.getElementById('tooltip');
    if (tooltipElement) {
      tooltipElement.style.display = 'none';
    }
  }

  onOverlayShow(op: OverlayPanel): void {
    setTimeout(() => {
      if (op.container) {
        const panelHeight = op.container.offsetHeight;
        const margin = 20;
        if (op.target) {
          const targetRect = op.target.getBoundingClientRect();
          op.container.style.top = targetRect.top - panelHeight - margin + 'px';
        }
      }
    }, 0);
  }

  getLegendKeys(): string[] {
    return Object.keys(this.legendData);
  }

  onCheckboxChange(responsibleUnit: string, event: Event) {
    if (!this.chart) return;
    const checked = (event.target as HTMLInputElement).checked;

    this.selectedResponsibleUnits[responsibleUnit] = checked;

    const indexes = this.responsibleUnitToIndexes[responsibleUnit] || [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const annotations = this.chart.options.plugins?.annotation?.annotations as Record<string, any>;
    if (annotations) {
      indexes.forEach((index) => {
        if (annotations[index]) {
          annotations[index].display = checked;
          if (checked) {
            let maxZ = 0;
            Object.keys(annotations).forEach((k) => {
              if (annotations[k].z !== undefined && annotations[k].z > maxZ) {
                maxZ = annotations[k].z;
              }
            });
            annotations[index].z = maxZ + 1;
          }
        }
      });
      this.chart.update();
    }
  }

  private handleAnnotationClick(context: EventContext) {
    if (!this.chart) return;
    const clickedId = context.element?.options.id;
    if (clickedId !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const annotations = this.chart.options.plugins?.annotation?.annotations as Record<string, any>;
      if (annotations && annotations[clickedId]) {
        let maxZ = 0;
        Object.keys(annotations).forEach((k) => {
          annotations[k].borderWidth = 3;
          if (annotations[k].z !== undefined && annotations[k].z > maxZ) {
            maxZ = annotations[k].z;
          }
        });
        annotations[clickedId].z = maxZ + 1;
        annotations[clickedId].borderWidth = 5;
        this.chart.update();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateBoxAnnotations(): any {
    this.legendData = {};
    this.responsibleUnitToIndexes = {};
    this.selectedResponsibleUnits = {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const annotations: { [key: string]: any } = {};

    this.data.forEach((reservation, index) => {
      const responsibleUnit = reservation.responsibleUnit;

      if (!this.responsibleUnitToIndexes[responsibleUnit]) {
        this.responsibleUnitToIndexes[responsibleUnit] = [];
        this.selectedResponsibleUnits[responsibleUnit] = true;
      }

      this.responsibleUnitToIndexes[responsibleUnit].push(index);

      const colorIndex = this.responsibleUnitColors.get(responsibleUnit) || 0;

      if (!this.legendData[responsibleUnit]) {
        this.legendData[responsibleUnit] = {
          label: responsibleUnit,
          color: this.generateRandomRgbaColorByIndex(colorIndex),
          borderColor: this.generateRandomRgbaColorByIndex(colorIndex, true),
          overlap: [],
          id: colorIndex
        } as ChartLegendItem;
      }

      const overlaps = this.getOverlappingDetails(index);
      if (overlaps.length > 0) {
        this.legendData[responsibleUnit].overlap = [...(this.legendData[responsibleUnit].overlap || []), ...overlaps];
      }

      annotations[index] = {
        type: 'box',
        xMin: normalizeFromAupTime(reservation.from),
        xMax: normalizeUntilAupTime(reservation.until),
        yMin: this.convertStringToNumber(reservation.flMin),
        yMax: this.convertStringToNumber(reservation.flMax),
        backgroundColor: this.generateRandomRgbaColorByIndex(colorIndex),
        borderColor: this.generateRandomRgbaColorByIndex(colorIndex, true),
        borderWidth: 3,
        id: index
      };
    });

    Object.keys(this.legendData).forEach((responsibleUnit) => {
      const uniqueOverlaps = this.removeDuplicateOverlaps(this.legendData[responsibleUnit].overlap);
      this.legendData[responsibleUnit].overlap = uniqueOverlaps;
    });

    this.boxAnnotations = annotations;
    return annotations;
  }

  private getMaxFlMaxValue(): number {
    if (!this.data.length) {
      return 500;
    }

    let highestFlMax = this.convertStringToNumber(this.data[0].flMax);
    for (const item of this.data) {
      const flMaxValue = this.convertStringToNumber(item.flMax);
      if (flMaxValue > highestFlMax) {
        highestFlMax = flMaxValue;
      }
    }
    return highestFlMax;
  }

  private getMaxUntilValue(): number {
    if (!this.data.length) {
      return normalizeUntilAupTime('06:00');
    }

    let highestUntil = normalizeUntilAupTime(this.data[0].until);
    for (const item of this.data) {
      const until = normalizeUntilAupTime(item.until);
      if (until > highestUntil) {
        highestUntil = until;
      }
    }

    return highestUntil;
  }

  private getMinFromValue(): number {
    if (!this.data.length) {
      return normalizeUntilAupTime('06:00');
    }

    let lowestFrom = normalizeFromAupTime(this.data[0].from);
    for (const item of this.data) {
      const from = normalizeFromAupTime(item.from);
      if (from < lowestFrom) {
        lowestFrom = from;
      }
    }

    if (moment(lowestFrom).hour() <= 7) {
      return normalizeFromAupTime('06:00');
    }
    const substractHour = moment(lowestFrom).subtract(1, 'hours').valueOf();
    return substractHour;
  }

  private convertStringToNumber(value: string): number {
    if (value === 'GND' || value === 'SFC') {
      return 0;
    }

    const numericPart = value.substring(1);
    return parseInt(numericPart, 10);
  }

  private getOverlappingDetails(currentIndex: number): ChartBoxOverlap[] {
    const details: ChartBoxOverlap[] = [];
    const currentReservation = this.data[currentIndex];

    if (!currentReservation) return details;

    const xMinCurrent = normalizeFromAupTime(currentReservation.from);
    const xMaxCurrent = normalizeUntilAupTime(currentReservation.until);
    const yMinCurrent = getAltitudeIndex(currentReservation.flMin);
    const yMaxCurrent = getAltitudeIndex(currentReservation.flMax);

    this.data.forEach((otherReservation, index) => {
      if (index === currentIndex) return;

      const xMinOther = normalizeFromAupTime(otherReservation.from);
      const xMaxOther = normalizeUntilAupTime(otherReservation.until);
      const yMinOther = getAltitudeIndex(otherReservation.flMin);
      const yMaxOther = getAltitudeIndex(otherReservation.flMax);

      const timeOverlap = xMinCurrent < xMaxOther && xMaxCurrent > xMinOther;
      const heightOverlap = yMinCurrent < yMaxOther && yMaxCurrent > yMinOther;

      if (timeOverlap && heightOverlap) {
        const overlapStartTime = Math.max(xMinCurrent, xMinOther);
        const overlapEndTime = Math.min(xMaxCurrent, xMaxOther);
        const formattedOverlapTime = `${formatTimestampToStringTime(overlapStartTime)}-${formatTimestampToStringTime(overlapEndTime)}`;

        const overlapStartHeight = Math.max(yMinCurrent, yMinOther);
        const overlapEndHeight = Math.min(yMaxCurrent, yMaxOther);
        const formattedOverlapHeight = `${altitudeArrayObserver[overlapStartHeight]}-${altitudeArrayObserver[overlapEndHeight]}`;

        details.push({
          label: otherReservation.responsibleUnit,
          overlapTime: formattedOverlapTime,
          overlapHeight: formattedOverlapHeight
        });
      }
    });

    return details;
  }

  private generateRandomRgbaColorByIndex(index: number, withoutTransparent?: boolean): string {
    switch (index) {
      case 0:
        return withoutTransparent ? 'rgba(240, 80, 100, 1)' : 'rgba(240, 80, 100, 0.05)';
      case 1:
        return withoutTransparent ? 'rgb(0, 128, 226)' : 'rgba(80, 170, 240, 0.05)';
      case 2:
        return withoutTransparent ? 'rgb(0, 143, 0)' : 'rgba(80, 230, 80, 0.05)';
      case 3:
        return withoutTransparent ? 'rgb(80, 57, 38)' : 'rgba(120, 240, 60, 0.05)';
      case 4:
        return withoutTransparent ? 'rgba(100, 50, 190, 1)' : 'rgba(100, 50, 190, 0.05)';
      case 5:
        return withoutTransparent ? 'rgba(240, 160, 20, 1)' : 'rgba(240, 160, 20, 0.05)';
      case 6:
        return withoutTransparent ? 'rgb(27, 94, 94)' : 'rgba(70, 230, 230, 0.05)';
      case 7:
        return withoutTransparent ? 'rgb(245, 55, 245)' : 'rgba(240, 80, 240, 0.05)';
      case 8:
        return withoutTransparent ? 'rgb(102, 109, 4)' : 'rgba(240, 140, 90, 0.05)';
      default:
        return withoutTransparent ? 'rgba(240, 140, 90, 1)' : 'rgba(240, 140, 90, 0.05)';
    }
  }

  private countDesignators(): { designator: string; count: number }[] {
    const counts = this.data.reduce(
      (acc, curr) => {
        acc[curr.designator] = (acc[curr.designator] || 0) + 1;
        return acc;
      },
      {} as { [key: string]: number }
    );

    return Object.entries(counts).map(([designator, count]) => ({ designator, count }));
  }

  private resetBoxBorderWidth(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.chart-container')) {
      if (this.chart) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const annotations = this.chart.options.plugins?.annotation?.annotations as Record<string, any>;
        if (annotations) {
          Object.keys(annotations).forEach((k) => {
            annotations[k].borderWidth = 3;
          });
          this.chart.update();
        }
      }
    }
  }

  private removeDuplicateOverlaps(overlaps: ChartBoxOverlap[]): ChartBoxOverlap[] {
    const uniqueMap = new Map<string, ChartBoxOverlap>();

    overlaps.forEach((overlap) => {
      const key = `${overlap.label}-${overlap.overlapTime}-${overlap.overlapHeight}`;
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, overlap);
      }
    });

    return Array.from(uniqueMap.values());
  }
}
