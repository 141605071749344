import { Component, OnInit, Signal, ViewChild, computed, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { StepperModule } from 'primeng/stepper';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { CoordinatesFormat } from 'src/app/model/map.model';
import { AdhocService } from 'src/app/services/adhoc.service';
import { Message, MessageService } from 'primeng/api';
import { AdHocDTO, AdhocFormGroup } from 'src/app/model/adhoc.model';
import { concat, timer } from 'rxjs';
import { MessagesModule } from 'primeng/messages';
import { AirspaceService } from 'src/app/services/airspace.service';
import { SplitterModule } from 'primeng/splitter';
import { AddPolyComponent } from './add-poly/add-poly.component';
import { AddCircleComponent } from './add-circle/add-circle.component';
import { AdhocMapComponent } from '../adhoc-map/adhoc-map.component';
import { AddTimesComponent } from './add-times/add-times.component';
import { AdhocFormService } from 'src/app/services/adhoc-form.service';

@Component({
  selector: 'rqa-adhoc-stepper',
  templateUrl: './adhoc-stepper.component.html',
  styleUrls: ['./adhoc-stepper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    RadioButtonModule,
    TabViewModule,
    TranslateModule,
    IconComponent,
    StepperModule,
    MessagesModule,
    AddPolyComponent,
    AddCircleComponent,
    AddTimesComponent,
    SplitterModule,
    AdhocMapComponent
  ]
})
export class AdhocStepperComponent implements OnInit, OnDestroy {
  @ViewChild(AddPolyComponent) polyComponent: AddPolyComponent;
  @ViewChild(AddCircleComponent) circleComponent: AddCircleComponent;
  coordinatesFormat = CoordinatesFormat;
  selectedCoordinatesFormat: CoordinatesFormat = CoordinatesFormat.DMS;
  adhocName: string;
  adhocPayload: AdHocDTO;
  hasGeoJsonSignal: Signal<boolean> = computed(() => this.adhocService.geoJsonSignal() !== null);
  resetForm: boolean = false;
  validationMessage: Message[] | undefined;
  activeStep: number = 0;
  activeTab: number = 0;
  private adhocService = inject(AdhocService);
  private adhocFormService = inject(AdhocFormService);
  private messageService = inject(MessageService);
  private airspaceService = inject(AirspaceService);
  adhocForm: AdhocFormGroup;

  ngOnInit(): void {
    this.getNewAdhocName();
    this.createAdhocForm();
    this.observeAdhocData();
  }

  onTabChange(event: TabViewChangeEvent): void {
    this.activeTab = event.index;
    this.adhocService.setCircleToDraw(null);
    this.adhocService.setPolyToDraw(null);
  }

  draw(): void {
    if (this.activeTab === 0) {
      this.circleComponent.draw();
    } else {
      this.polyComponent.draw();
    }
  }

  private createAdhocForm(): void {
    this.adhocForm = this.adhocFormService.createAdhocForm();
  }

  private observeAdhocData(): void {
    this.adhocFormService.observeAdhocData().subscribe((adhocData) => {
      if (adhocData && adhocData.formData) {
        this.activeStep = adhocData.activeStep;
        this.adhocForm = this.adhocFormService.patchValuesToForm(this.adhocForm, adhocData.formData);
        this.activeTab = adhocData.activeTab;
      }
    });
  }

  private getNewAdhocName() {
    this.adhocService.getNewAdHocName().subscribe({
      next: (response) => {
        this.adhocName = response.value;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          detail: error.error.message,
          life: 3000
        });
      }
    });
  }

  createAdhoc(): void {
    const formValue = this.adhocForm.getRawValue();
    this.adhocPayload = {
      designator: this.adhocName,
      startDate: formValue.dateAndAltitudes.datesGroup.startDate,
      endDate: formValue.dateAndAltitudes.datesGroup.endDate,
      geoJSON: this.adhocService.geoJsonSignal(),
      lowerAltitude: formValue.dateAndAltitudes.altitudesGroup.lowerAltitude,
      upperAltitude: formValue.dateAndAltitudes.altitudesGroup.upperAltitude
    };
    this.adhocService.createAddHoc(this.adhocPayload).subscribe({
      next: (response) => {
        this.adhocService.setCircleToDraw(null);
        this.adhocService.setPolyToDraw(null);
        this.adhocForm.reset();
        this.messageService.add({
          severity: 'success',
          detail: response.value,
          life: 3000
        });
        this.activeStep = 0;
        concat(timer(5000), this.airspaceService.fetchAirspace()).subscribe();
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          detail: error.error.message,
          life: 3000
        });
      }
    });
  }

  ngOnDestroy(): void {
    const formData = this.adhocForm.getRawValue();
    this.adhocFormService.setAdhocData({
      activeStep: this.activeStep,
      formData: formData,
      activeTab: this.activeTab
    });
  }
}
