import { CommonModule } from '@angular/common';
import { Component, inject, Input, signal, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';

@Component({
  selector: 'rqa-status-info',
  templateUrl: './status-info.component.html',
  styleUrl: './status-info.component.scss',
  imports: [CommonModule, TranslateModule],
  standalone: true
})
export class StatusInfoComponent {
  @Input() statusName: string = '';
  private layoutUtilsService = inject(LayoutUtilsService);
  isMobileDevice: Signal<boolean | undefined> = this.layoutUtilsService.isMobileDevice;
  isStatusTextVisible = signal(false);

  showStatusText(): void {
    this.isStatusTextVisible.set(true);
    setTimeout(() => {
      this.isStatusTextVisible.set(false);
    }, 2000);
  }
}
