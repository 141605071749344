import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
  ViewChild,
  effect,
  inject,
  OnDestroy
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObserverService } from 'src/app/services/observer.service';
import { Table, TableModule, TableRowSelectEvent } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  AupDetails,
  AupReservation,
  AupReservationFilters,
  AupReservationFiltersFormGroup
} from 'src/app/model/observer.model';
import { ActivatedRoute } from '@angular/router';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { altitudeArrayObserver } from 'src/app/model/altitudes.model';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { SortEvent, TreeNode } from 'primeng/api';
import { StatusInfoComponent } from 'src/app/ui/status-info/status-info.component';
import { DesignatorGraphComponent } from './designator-graph/designator-graph.component';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { debounceTime, distinctUntilChanged, interval, Subscription, switchMap } from 'rxjs';
import { MapLayerTypeEnum } from 'src/app/model/map.model';
import { AupMapService } from '../aup-map/aup-map.service';
import { TableKeyboardNavigationDirective } from 'src/app/shared/directives/table-keyboard-navigation.directive';
import { TimeControlDirective } from 'src/app/shared/directives/time-control.directive';
import { altitudesRangeValidator, timeRangeValidator } from 'src/app/shared/form/validators';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { DropdownModule } from 'primeng/dropdown';
import { TreeSelectModule } from 'primeng/treeselect';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { customTableSortDesignator } from 'src/app/shared/utils/custom-sort-designator.util';

@Component({
  selector: 'rqa-aup-reservations',
  templateUrl: './aup-reservations.component.html',
  styleUrls: ['./aup-reservations.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FloatLabelModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputMaskModule,
    StatusInfoComponent,
    ToggleButtonComponent,
    DesignatorGraphComponent,
    TableKeyboardNavigationDirective,
    TimeControlDirective,
    ValidatorComponent,
    DropdownModule,
    TreeSelectModule,
    ChipModule,
    InputTextModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AupReservationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dt') pTable!: Table;
  @ViewChild('multiSelectDesignator') multiSelectDesignator: MultiSelect;
  @ViewChild('multiSelectResponsibleUnit') multiSelectResponsibleUnit: MultiSelect;
  private route = inject(ActivatedRoute);
  private observerService = inject(ObserverService);
  private fb = inject(FormBuilder);
  private _location = inject(Location);
  private layoutUtilsService = inject(LayoutUtilsService);
  private intervalSubscription: Subscription;
  private aupMapService = inject(AupMapService);
  private translateService = inject(TranslateService);

  isUup: boolean = this.route.snapshot.url[1].path === 'uup';
  filtersForm: AupReservationFiltersFormGroup = this.fb.group({
    designator: this.fb.control<TreeNode[]>([]),
    responsibleUnit: this.fb.control<string[]>([]),
    timeRange: this.fb.group(
      {
        from: this.fb.control<string>(''),
        until: this.fb.control<string>('')
      },
      { validators: [timeRangeValidator] }
    ),
    altitudeRange: this.fb.group(
      {
        flMin: this.fb.control<string | null>(null),
        flMax: this.fb.control<string | null>(null)
      },
      { validators: [altitudesRangeValidator] }
    ),
    status: this.fb.control<{ name: string; code: string } | null>(
      this.isUup
        ? {
            name: this.translateService.instant('observer.status.activated'),
            code: 'ACTIVATED'
          }
        : null
    ),
    remarks: this.fb.control<string>('')
  }) as AupReservationFiltersFormGroup;

  readonly filters: Signal<AupReservationFilters> = this.observerService.filtersSignal;
  readonly reservations: Signal<AupReservation[]> = this.observerService.filteredReservation;

  aupDetails: Signal<AupDetails> = this.observerService.aupDetails;
  aupDate: string | null = this.route.snapshot.paramMap.get('date');
  designatorOptionsTree: TreeNode[] | null = [];
  unitOptions: string[] = [];
  statusOptions: { name: string; code: string }[] = [];
  altitudes: string[] = altitudeArrayObserver;
  filteredAltitudes: string[] = [];
  isEmited = false;
  tableScrollHeight: string;
  visualizationChecked = false;
  isGraphVisible = false;
  reservationsForChart: AupReservation[] = [];
  customSortFunction = customTableSortDesignator;

  constructor() {
    effect(
      () => {
        if (this.filters() && !this.isEmited) {
          this.filtersForm.get('timeRange')?.setValue({ from: this.filters().from, until: this.filters().until });
          this.filtersForm.get('altitudeRange')?.setValue({ flMin: this.filters().flMin, flMax: this.filters().flMax });
        }
      },
      { allowSignalWrites: true }
    );
    effect(() => {
      if (this.reservations().length) {
        if (!this.filtersForm.get('designator')?.value || this.filtersForm.get('designator')?.value.length === 0) {
          this.updateDesignatorOptions();
        }
        if (
          !this.filtersForm.get('responsibleUnit')?.value ||
          this.filtersForm.get('responsibleUnit')?.value.length === 0
        ) {
          this.updateUnitOptions();
        }
        this.updateStatusOptions();
      }
    });
  }

  ngOnInit(): void {
    this.fetchAupDetails();
    this.fetchAupSubscription();
    this.onFiltersFormChange();
    this.observerService.selectedRow.set(null);
  }

  ngAfterViewInit(): void {
    this.calculateTableHeight(false);
  }

  goBack(): void {
    this._location.back();
  }

  searchAltitude(event: AutoCompleteCompleteEvent, controlName: string): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
    if (this.filteredAltitudes.length === 1) {
      this.filtersForm.get('altitudeRange')?.get(controlName)?.setValue(this.filteredAltitudes[0]);
      this.filtersForm.get('altitudeRange')?.markAsDirty();
    }
  }

  clearFilters(): void {
    this.isEmited = true;
    this.filtersForm.reset();

    if (this.multiSelectDesignator) {
      this.multiSelectDesignator.resetFilter();
    }
    if (this.multiSelectResponsibleUnit) {
      this.multiSelectResponsibleUnit.resetFilter();
    }
  }

  clearSort(): void {
    this.observerService.updateSort(null);
    this.pTable.reset();
  }

  customSort(event: SortEvent) {
    const currentSort = this.observerService.sortSignal();
    if (!currentSort || currentSort.field !== event.field || currentSort.order !== event.order) {
      setTimeout(() => {
        this.observerService.updateSort(event);
      }, 100);
    }
  }

  onRowSelect(event: TableRowSelectEvent) {
    this.observerService.selectedRow.set({ rowData: event.data, isVisualizationChecked: this.visualizationChecked });
    this.isGraphVisible = this.visualizationChecked;
    this.setChartData();
    if (this.visualizationChecked) {
      this.updateMapFiltersAfterSelection(event.data);
    }
  }

  onRowUnselect() {
    this.observerService.selectedRow.set(null);
    this.isGraphVisible = false;
    this.setChartData();
  }

  onToggleVisualization(checked: boolean): void {
    this.visualizationChecked = checked;
    this.isGraphVisible = checked && !!this.observerService.selectedRow();
    this.clearFilters();
    setTimeout(() => {
      this.calculateTableHeight(checked);
    }, 100);

    if (!checked) {
      this.observerService.selectedRow.set(null);
    }
  }

  removeDesignatorChip(node: TreeNode, event: Event) {
    event.stopPropagation();

    const currentSelection = this.filtersForm.get('designator')?.value || [];

    if (node.children?.length) {
      const updatedSelection = currentSelection
        .map((n: TreeNode) => {
          if (node.children && node.children.some((child) => child.key === n.key)) {
            return {
              ...n,
              parent: node
            };
          }
          return n;
        })
        .filter((n: TreeNode) => n.key !== node.key);

      this.filtersForm.get('designator')?.setValue(updatedSelection);
    } else {
      const updatedSelection = currentSelection.filter((n: TreeNode) => n.key !== node.key);
      if (updatedSelection.length === 1 && updatedSelection[0].children?.length) {
        this.filtersForm.get('designator')?.setValue([]);
      } else {
        this.filtersForm.get('designator')?.setValue(updatedSelection);
      }
    }
  }

  private setChartData(): void {
    const selectedRow = this.observerService.selectedRow();
    if (selectedRow) {
      this.reservationsForChart = this.reservations().filter((item) => {
        if (item.designator === selectedRow.rowData.designator) return true;

        if (item.designatorBase === selectedRow.rowData.designatorBase) {
          if (item.designatorSuffix && selectedRow.rowData.designatorSuffix) {
            return item.designatorSuffix
              .split('')
              .some((letter) => selectedRow.rowData.designatorSuffix.includes(letter));
          }
        }
        return false;
      });
    } else {
      this.reservationsForChart = [];
    }
  }

  private fetchAupDetails(): void {
    this.observerService
      .fetchAupDetails([
        {
          localDate: this.aupDate || ''
        }
      ])
      .subscribe();
  }

  private fetchAupSubscription(): void {
    this.intervalSubscription = interval(5 * 60 * 1000) // Co 5 minut
      .pipe(
        switchMap(() =>
          this.observerService.fetchAupDetails([{ localDate: this.aupDate || '' }]).pipe(
            switchMap((details: AupDetails) => {
              const mapType = details.name === 'AUP' ? MapLayerTypeEnum.AUP : MapLayerTypeEnum.UUP;
              return this.aupMapService.fetchAupMapLayers(this.aupDate || '', mapType);
            })
          )
        )
      )
      .subscribe({
        next: () => {
          console.log('Dane zostały zaktualizowane');
        },
        error: (err) => {
          console.error('Błąd podczas pobierania danych', err);
        }
      });
  }

  private onFiltersFormChange(): void {
    this.filtersForm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      if (this.filtersForm.valid) {
        this.isEmited = true;
        const filtersValue = this.filtersForm.getRawValue();
        const payload: AupReservationFilters = {
          ...filtersValue,
          designator: this.mapDesignatorFilter(filtersValue.designator),
          from: filtersValue.timeRange.from,
          until: filtersValue.timeRange.until,
          flMin: filtersValue.altitudeRange.flMin,
          flMax: filtersValue.altitudeRange.flMax,
          status: filtersValue.status?.code || 'ALL'
        };
        if (Object.values(filtersValue).some((value) => value !== null)) {
          this.observerService.updateFilters(payload);
        } else {
          this.observerService.updateFilters(null);
        }
        setTimeout(() => {
          this.isEmited = false;
        }, 200);
      }
    });
  }

  private updateUnitOptions(): void {
    this.unitOptions = [...new Set(this.reservations().map((item) => item.responsibleUnit))];
  }

  private updateDesignatorOptions(): void {
    const optionsApi = this.aupDetails().designatorGroups;

    this.designatorOptionsTree = Object.keys(optionsApi).map((key) => {
      const children = optionsApi[key].map((child) => ({
        key: child,
        label: child,
        data: child
      }));
      return {
        key: key,
        label: key,
        data: key,
        ...(children.length && { children })
      };
    });
  }

  private updateStatusOptions(): void {
    const statuses = [...new Set(this.aupDetails().reservationShortDTOS.map((item) => item.status))]
      .map((item) => {
        return {
          name: this.translateService.instant('observer.status.' + item.toLowerCase()),
          code: item
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    this.statusOptions = [{ name: this.translateService.instant('observer.status.all'), code: 'ALL' }, ...statuses];
  }

  private calculateTableHeight(showGraph: boolean): void {
    const contentHeight = this.layoutUtilsService.contentHeight();
    const filtersHeight = showGraph ? 0 : document.querySelector('.filters-form')?.clientHeight || 0;
    const aupHeader = document.querySelector('.aup-header')?.clientHeight || 0;
    const paddings = this.layoutUtilsService.calculateRemToPx(8);
    const graphHeight = showGraph ? this.layoutUtilsService.calculateRemToPx(13) : 0;

    if (contentHeight) {
      this.tableScrollHeight = `${contentHeight - filtersHeight - aupHeader - graphHeight - paddings}px`;
    }
  }

  private updateMapFiltersAfterSelection(selectedRow: AupReservation): void {
    const filtersValue: AupReservationFilters = {
      designator: [],
      responsibleUnit: [],
      from: selectedRow.from,
      until: selectedRow.until,
      flMin: selectedRow.flMin,
      flMax: selectedRow.flMax,
      remarks: ''
    };
    this.aupMapService.filterMapLayers(filtersValue);
  }

  private mapDesignatorFilter(nodes: TreeNode[]): { name: string; prefix?: string }[] {
    if (!nodes?.length) return [];
    const result: { name: string; prefix?: string }[] = [];
    const isExistInResult = (name: string) => result.some((item) => item.name === name);
    let currentPrefix = '';
    nodes.forEach((node) => {
      if (!node.partialSelected && !isExistInResult(node.data as string)) {
        if (node.children?.length) {
          currentPrefix = node.data as string;
        }
        if (node.parent) {
          currentPrefix = node.parent.data as string;
        }
        if (!node.children?.length) {
          result.push({ name: node.data as string, prefix: currentPrefix });
        }
      }
    });
    return result;
  }

  ngOnDestroy() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
}
