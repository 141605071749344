import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { SliderChangeEvent, SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
import { altitudeArrayObserver } from 'src/app/model/altitudes.model';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { WindowSize } from 'src/app/model/layout.model';

@Component({
  selector: 'rqa-map-height',
  templateUrl: './map-height.component.html',
  styleUrls: ['./map-height.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, SliderModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapHeightComponent {
  @Input()
  set filterHeightRange(value: string[]) {
    if (value) {
      this.heightRange = [
        altitudeArrayObserver.indexOf(value[0] || altitudeArrayObserver[0]),
        altitudeArrayObserver.indexOf(value[1] || altitudeArrayObserver[altitudeArrayObserver.length - 1])
      ];
      this.minHeight = value[0] || altitudeArrayObserver[0];
      this.maxHeight = value[1] || altitudeArrayObserver[altitudeArrayObserver.length - 1];
      this.updateTooltips();
    } else {
      this.heightRange = [0, this.alititudeArray.length - 1];
      this.minHeight = this.alititudeArray[0];
      this.maxHeight = this.alititudeArray[this.alititudeArray.length - 1];
      this.updateTooltips();
    }
  }
  @Input() disabled: boolean;
  @Output() heightRangeChange = new EventEmitter<string[]>();
  private layoutUtilsService = inject(LayoutUtilsService);
  windowSize: Signal<WindowSize> = this.layoutUtilsService.windowSize;
  alititudeArray = altitudeArrayObserver;
  heightRange: number[] = [];
  minHeight: string = this.alititudeArray[0];
  maxHeight: string = this.alititudeArray[this.alititudeArray.length - 1];
  tooltipPositionMin: number = 0;
  tooltipPositionMax: number = 100;
  sliderHeightStyle = { height: '28rem' };
  private sliderChangeTimeout: ReturnType<typeof setTimeout> | null = null;

  get currentRange(): string[] {
    return [this.minHeight, this.maxHeight];
  }

  onHeightRangeChange(event: SliderChangeEvent): void {
    this.minHeight = this.alititudeArray[this.heightRange[0]];
    this.maxHeight = this.alititudeArray[this.heightRange[1]];
    this.updateTooltips();
    if (event?.event?.type === 'keydown') {
      if (this.sliderChangeTimeout) {
        clearTimeout(this.sliderChangeTimeout);
      }
      this.sliderChangeTimeout = setTimeout(() => {
        this.onHeightRangeChangeComplete();
        this.sliderChangeTimeout = null;
      }, 500);
    }
  }

  onHeightRangeChangeComplete(): void {
    this.heightRangeChange.emit([this.minHeight, this.maxHeight]);
    this.updateTooltips();
  }

  private updateTooltips(): void {
    this.tooltipPositionMin = (this.heightRange[0] / this.alititudeArray.length) * 100 - 2;
    this.tooltipPositionMax = (this.heightRange[1] / this.alititudeArray.length) * 100 + 2;
  }
}
