<section class="left-side pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
    <div>
      <h1 class="text-2xl mx-0 my-0 title">{{ 'adhoc.title' | translate }}</h1>
      <p class="mx-0 my-0 subtitle">{{ 'adhoc.subtitle' | translate }}</p>
    </div>
  </section>
  <section>
    <p-card>
      <ng-template pTemplate="content">
        <form [formGroup]="adhocForm">
          <h2>{{ 'adhoc.designator' | translate }}: {{ adhocName }}</h2>
          <p-divider></p-divider>
          <p-stepper orientation="vertical" [(activeStep)]="activeStep">
            <!-- STEP 1 -->
            <p-stepperPanel [header]="'adhoc.saveFormat' | translate">
              <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                <div class="flex flex-wrap gap-3">
                  <div class="flex align-items-center">
                    <p-radioButton
                      name="format"
                      [value]="coordinatesFormat.DMS"
                      formControlName="format"
                      inputId="dms"
                    ></p-radioButton>
                    <label for="dms" class="ml-2">{{ 'adhoc.ddmmssLabel' | translate }}</label>
                  </div>

                  <div class="flex align-items-center">
                    <p-radioButton
                      name="format"
                      [value]="coordinatesFormat.DECIMAL"
                      formControlName="format"
                      inputId="decimal"
                    ></p-radioButton>
                    <label for="decimal" class="ml-2">{{ 'adhoc.decimalLabel' | translate }}</label>
                  </div>
                </div>
                <div class="flex py-4 justify-content-end">
                  <p-button
                    [label]="'buttons.next' | translate"
                    (onClick)="nextCallback.emit()"
                    icon="pi pi-chevron-right"
                    iconPos="right"
                  /></div
              ></ng-template>
            </p-stepperPanel>

            <!-- STEP 2 -->
            <p-stepperPanel [header]="'adhoc.coordinates' | translate">
              <ng-template
                pTemplate="content"
                let-prevCallback="prevCallback"
                let-nextCallback="nextCallback"
                let-index="index"
              >
                <div class="flex flex-column h-28rem">
                  <p-tabView (onChange)="onTabChange($event)" [activeIndex]="activeTab">
                    <p-tabPanel [header]="'adhoc.circle' | translate">
                      <rqa-add-circle
                        [circleForm]="adhocForm.controls['circle']"
                        [coordinatesFormat]="selectedCoordinatesFormat"
                        [resetForm]="resetForm"
                      ></rqa-add-circle>
                    </p-tabPanel>
                    <p-tabPanel [header]="'adhoc.polygon' | translate">
                      <rqa-add-poly
                        [polyForm]="adhocForm.controls['poly']"
                        [coordinatesFormat]="selectedCoordinatesFormat"
                        [resetForm]="resetForm"
                      ></rqa-add-poly>
                    </p-tabPanel>
                  </p-tabView>
                </div>
                <div class="flex py-4 gap-2 justify-content-end">
                  <p-button
                    [label]="'buttons.prev' | translate"
                    severity="secondary"
                    icon="pi pi-chevron-left"
                    (onClick)="prevCallback.emit()"
                  />
                  <p-button
                    [label]="'buttons.next' | translate"
                    (onClick)="draw(); nextCallback.emit()"
                    icon="pi pi-chevron-right"
                  /></div
              ></ng-template>
            </p-stepperPanel>

            <!-- STEP 3 -->
            <p-stepperPanel [header]="'adhoc.validityPeriod' | translate">
              <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                <div class="flex flex-column h-18rem">
                  <div class="flex flex-column h-18rem">
                    <rqa-add-times [dateAndAltitudes]="adhocForm.controls['dateAndAltitudes']"></rqa-add-times>
                    <p-messages [(value)]="validationMessage" [closable]="false" [enableService]="false" />
                  </div>
                </div>
                <div class="flex py-4 gap-2 justify-content-end">
                  <p-button
                    [label]="'buttons.prev' | translate"
                    severity="secondary"
                    icon="pi pi-chevron-left"
                    (onClick)="prevCallback.emit()"
                  />
                  <p-button
                    [label]="'buttons.save' | translate"
                    (onClick)="createAdhoc()"
                    icon="pi pi-check"
                    [disabled]="!adhocForm.get('dateAndAltitudes').valid || !hasGeoJsonSignal()"
                  /></div
              ></ng-template>
            </p-stepperPanel>
          </p-stepper>
        </form>
      </ng-template>
    </p-card>
  </section>
</section>
