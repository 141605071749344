import * as moment from 'moment';

export const normalizeFromAupTime = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);

  if (hours === 6 && minutes === 0) {
    return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
  }

  if (hours >= 0 && hours < 6) {
    return moment().add(1, 'days').startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
  }
  return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
};

export const normalizeUntilAupTime = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  if (hours >= 6 && minutes !== 0) {
    return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
  }
  if (hours >= 0 && hours <= 6) {
    return moment().add(1, 'days').startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
  }
  return moment().startOf('day').add(hours, 'hours').add(minutes, 'minutes').valueOf();
};

export const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const formatTimestampToStringTime = (timestamp: number): string => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};
