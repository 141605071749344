<div class="flex flex-column gap-2">
  <label for="date">{{ dialogConfig.data.label }}</label>
  <p-calendar
    [(ngModel)]="date"
    id="date"
    [iconDisplay]="'input'"
    [showIcon]="true"
    appendTo="body"
    dateFormat="yy-mm-dd"
    [minDate]="minDate"
    [maxDate]="maxDate"
  ></p-calendar>
  <div class="flex mt-5 mb-4 justify-content-between">
    <p-button
      [label]="'buttons.cancel' | translate"
      [text]="true"
      [plain]="true"
      icon="pi pi-minus-circle"
      (click)="close()"
    ></p-button>
    <p-button [label]="'buttons.confirm' | translate" icon="pi pi-check" (click)="save()" [disabled]="!date"></p-button>
  </div>
</div>
