import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NoteDTO } from '../model/notes.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(private http: HttpClient) {}

  NOTE_PATH = 'api/note';

  getNotes(): Observable<NoteDTO[]> {
    return this.http.get<NoteDTO[]>(`${this.NOTE_PATH}`);
  }

  getNote(uuid: string): Observable<NoteDTO> {
    return this.http.get<NoteDTO>(`${this.NOTE_PATH}${uuid}`);
  }

  create(content: string): Observable<NoteDTO> {
    return this.http.post<NoteDTO>(`${this.NOTE_PATH}`, { content });
  }

  delete(uuid: string): Observable<NoteDTO> {
    return this.http.delete<NoteDTO>(`${this.NOTE_PATH}/${uuid}`);
  }

  edit(note: NoteDTO): Observable<NoteDTO> {
    return this.http.put<NoteDTO>(`${this.NOTE_PATH}/${note.id}`, { content: note.content });
  }
}
