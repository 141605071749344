<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes" [disabledAreas]="disabledAreas"></rqa-map-toolbar>
  <!-- <div class="airspace-info">
    <p-accordion
      (activeIndexChange)="onToggleAccordion($event)"
      [styleClass]="'box-accordion'"
      [activeIndex]="activeTabIndex"
    >
      <p-accordionTab *ngFor="let layer of selectedLayers; trackBy: trackByFn" [id]="layer.id">
        <ng-template pTemplate="header">
          <rqa-airspace-info [airspaceInfo]="layer" (click)="onAccordionHeaderClick(layer.id)"></rqa-airspace-info>
        </ng-template>
        <rqa-airspace-table
          [availabilities]="layer.availabilities"
          [designator]="layer.designator"
        ></rqa-airspace-table>
      </p-accordionTab>
    </p-accordion>
  </div> -->
  <div
    id="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
</div>
