import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rqa-status-info',
  templateUrl: './status-info.component.html',
  styleUrl: './status-info.component.scss',
  imports: [CommonModule],
  standalone: true
})
export class StatusInfoComponent {
  @Input() statusName: string;
}
