import { AfterViewInit, Component, effect, inject, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from 'src/app/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AirspaceElement } from 'src/app/model/map.model';
import { AccordionModule } from 'primeng/accordion';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { circleToPolygon, convertCoordinate } from 'src/app/shared/utils/coordinates.utils';
import { AdhocService } from 'src/app/services/adhoc.service';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPoint } from 'src/app/model/adhoc.model';
import { MapToolbarComponent } from 'src/app/map/map-toolbar/map-toolbar.component';
import { RqaMapService } from '../../rqa-order/rqa-map/rqa-map.service';

@Component({
  selector: 'rqa-adhoc-map',
  templateUrl: './adhoc-map.component.html',
  styleUrls: ['./adhoc-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    AccordionModule,
    IconComponent,
    TooltipModule,
    ButtonModule,
    ToggleButtonComponent,
    MapToolbarComponent
  ]
})
export class AdhocMapComponent extends MapComponent implements AfterViewInit {
  private rqaMapService = inject(RqaMapService);
  private adhocService = inject(AdhocService);
  readonly adhocMapLayers: Signal<AirspaceElement[]> = this.rqaMapService.mapLayers;
  drawnItems = new L.FeatureGroup();
  constructor() {
    super();

    effect(
      () => {
        this.getDisabledAreas();
        if (this.adhocMapLayers().length) {
          this.createLayers(this.adhocMapLayers());
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngAfterViewInit(): void {
    this.listenDrawCircle();
    this.listenDrawPoly();
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.clickMap(e);
  }

  private cleanDrawLayers(): void {
    const drawnItems = this.drawnItems.getLayers();
    if (drawnItems.length) {
      drawnItems.forEach((layer) => {
        this.map.removeLayer(layer);
      });
    }
    if (this.map) {
      this.map.setView(new L.LatLng(environment.center[0], environment.center[1]), 6);
    }
    this.adhocService.setGeoJson(null);
  }

  private listenDrawCircle(): void {
    this.adhocService
      .getCircleToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((circleData) => {
        this.cleanDrawLayers();
        if (circleData) {
          const latLngConverted = new L.LatLng(
            convertCoordinate(circleData.latitude),
            convertCoordinate(circleData.longitude)
          );
          const circleLayer = L.circle(latLngConverted, { radius: circleData.radius });
          circleLayer.addTo(this.map);
          this.drawnItems.addLayer(circleLayer);
          this.map.fitBounds(circleLayer.getBounds());
          this.adhocService.setGeoJson(circleToPolygon(circleLayer));
        }
      });
  }

  private listenDrawPoly(): void {
    this.adhocService
      .getPolyToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((polyData) => {
        this.cleanDrawLayers();
        const latLng: L.LatLng[] = [];
        if (polyData) {
          polyData.points.forEach((point: IPoint) => {
            if (point.lat && point.lon) {
              const coords = new L.LatLng(convertCoordinate(point.lat), convertCoordinate(point.lon));
              latLng.push(coords);
            }
          });
          const polyLayer = L.polygon(latLng);
          polyLayer.addTo(this.map);
          this.drawnItems.addLayer(polyLayer);
          this.map.fitBounds(polyLayer.getBounds());
          this.adhocService.setGeoJson(polyLayer.toGeoJSON());
        }
      });
  }
}
