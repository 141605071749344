import { AfterViewInit, Component, effect, inject, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from 'src/app/map/map.component';
import { RqaMapService } from './rqa-map.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AirspaceElement } from 'src/app/model/map.model';
import { AccordionModule } from 'primeng/accordion';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { layersStyle } from 'src/app/map/layersStyle';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { AirspaceInfoComponent } from './airspace-info/airspace-info.component';
import { AirspaceTableComponent } from './airspace-table/airspace-table.component';
import { MapToolbarComponent } from 'src/app/map/map-toolbar/map-toolbar.component';

@Component({
  selector: 'rqa-create-map',
  templateUrl: './rqa-map.component.html',
  styleUrls: ['./rqa-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    AccordionModule,
    IconComponent,
    TooltipModule,
    ButtonModule,
    ToggleButtonComponent,
    AirspaceInfoComponent,
    AirspaceTableComponent,
    MapToolbarComponent
  ]
})
export class RqaCreateMapComponent extends MapComponent implements AfterViewInit {
  private rqaMapService = inject(RqaMapService);
  readonly rqaMapLayers: Signal<AirspaceElement[]> = this.rqaMapService.mapLayers;
  activeAccordionIndex: number | null = null;
  constructor() {
    super();

    effect(
      () => {
        this.getDisabledAreas();
        if (this.rqaMapLayers().length) {
          this.createLayers(this.rqaMapLayers());
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngAfterViewInit(): void {
    this.listenSelectLayerOnTable();
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.clickMap(e);
    this.activeAccordionIndex = this.selectedLayers.length === 1 ? 0 : null;
  }

  onToggleAccordion(index: number): void {
    if (index !== null) {
      const toggledLayer = this.selectedLayers[index];
      const layer = this.layersRef.find((el) => el.name === toggledLayer.designator)?.layer;
      if (layer) {
        if (this.lastActiveLayer) {
          const lastToggledLayer = this.layersRef.find((el) => el.name === this.lastActiveLayer.designator);
          lastToggledLayer?.layer?.setStyle(layersStyle[toggledLayer.type.toLowerCase()].styles.activated);
        }

        const watchStyle = layersStyle[toggledLayer.type.toLowerCase()].styles;
        layer.setStyle(watchStyle.highlight);
        layer.bringToFront();
        this.lastActiveLayer = toggledLayer;
      }
    } else {
      this.resetLayerStyles();
    }
  }

  onAccordionHeaderClick(event: MouseEvent, index: number): void {
    event.stopPropagation();
    this.activeAccordionIndex = index;
  }

  private listenSelectLayerOnTable(): void {
    this.rqaMapService
      .getLayerSelect()
      .pipe()
      .subscribe((selectedLayer) => {
        this.selectedLayers = [];
        this.resetLayerStyles();
        if (selectedLayer) {
          const layerRef = this.layersRef.find((el) => el.name === selectedLayer);
          if (layerRef) {
            const styles = layersStyle[layerRef.type].styles;
            layerRef.layer.setStyle(styles.highlight);
            this.map.fitBounds(layerRef.layer.getBounds(), { maxZoom: 8 });
            layerRef.layer.bringToFront();
            this.selectedLayers = this.rqaMapLayers().filter((el: AirspaceElement) => el.designator === selectedLayer);
          }
        }
        this.activeAccordionIndex = this.selectedLayers.length === 1 ? 0 : null;
        // this.cdr.detectChanges();
      });
  }
}
