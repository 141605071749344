<section class="left-side pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between lg:mb-5 px-3 lg:px-0 align-items-center w-full flex-column lg:flex-row">
    <div>
      <h1 class="mx-0 my-0 title">
        {{ 'dashboard.title' | translate }} <p-badge [value]="reservationsCount.toString()"></p-badge>
      </h1>
      <p class="mx-0 my-0 subtitle">{{ 'dashboard.subtitle' | translate }}</p>
    </div>
    <div class="flex justify-content-between lg:justify-content-end mt-3 lg:mt-0 w-full lg:w-auto">
      <p-button
        [label]="'dashboard.saveTemplate' | translate"
        [text]="true"
        [plain]="true"
        [disabled]="!isValidTable"
        (click)="saveTemplate()"
        icon="pi pi-save"
      ></p-button>
      <p-button
        [label]="'dashboard.sendRqa' | translate"
        icon="pi pi-send"
        (click)="sendRqa()"
        [pTooltip]="!isValidTable && 'tooltips.confirmRows' | translate"
        tooltipPosition="right"
        [disabled]="!isValidTable"
        [style]="{ 'pointer-events': 'auto' }"
      ></p-button>
    </div>
  </section>
  <p-divider [styleClass]="'mt-0 hidden lg:block'"></p-divider>

  <p-panel [toggleable]="true" [styleClass]="'mt-5 mb-4'" [(collapsed)]="panelCollapsed">
    <ng-template pTemplate="header">
      <div>
        <h3 class="text-lg mb-1 mt-0">{{ 'dashboard.booker' | translate }}</h3>
        <p class="m-0 text-sm font-normal">{{ 'dashboard.bookerSubtitle' | translate }}</p>
      </div>
    </ng-template>
    <rqa-form #rqaFormRef class="w-full"></rqa-form>
  </p-panel>

  <rqa-table
    (countChange)="countChange($event)"
    (checkValidTable)="checkValidTable($event)"
    [resetTable]="resetTableAfterSend"
    class="w-full"
  ></rqa-table>
</section>
