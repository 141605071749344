import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, Observable, tap } from 'rxjs';
import { AupMapLayer, MapLayerTypeEnum } from 'src/app/model/map.model';
import { layersStyle, setStyleAdditionalLayer } from 'src/app/map/layersStyle';
import { AupMinMaxValues, AupReservation, AupReservationFilters } from 'src/app/model/observer.model';
import { getAltitudeIndex } from 'src/app/model/altitudes.model';
import { normalizeFromAupTime, normalizeUntilAupTime } from 'src/app/shared/utils/time.utils';
@Injectable({
  providedIn: 'root'
})
export class AupMapService {
  private http = inject(HttpClient);
  readonly API_URL: string = '/api/airspace/aup';
  readonly layerStyles = layersStyle;
  allMapLayers: AupMapLayer[] = [];
  mapLayers: WritableSignal<AupMapLayer[]> = signal([]);

  fetchAupMapLayers(date: string, mapType: MapLayerTypeEnum): Observable<AupMapLayer[]> {
    return this.http.get<AupMapLayer[]>(`${this.API_URL}/${date}`).pipe(
      tap((layers) => {
        const aupMapLayers: AupMapLayer[] = [];
        layers.map((layer) => {
          const styles = layersStyle[layer.type];
          if (layer.reservations.length) {
            aupMapLayers.push({
              ...layer,
              ...this.getMinValuesFromReservations(layer.reservations),
              displayFlMin: layer.flMin,
              displayFlMax: layer.flMax,
              type: mapType,
              styles: setStyleAdditionalLayer(styles)
            });
          } else {
            aupMapLayers.push({
              ...layer,
              reservations: [],
              displayFlMin: layer.flMin,
              displayFlMax: layer.flMax,
              styles: styles
            });
          }
        });

        this.allMapLayers = aupMapLayers;
        this.mapLayers.set(aupMapLayers);
      })
    );
  }

  filterMapLayers(filters: AupReservationFilters | null): void {
    if (filters) {
      const filteredLayers = this.allMapLayers.filter((layer) => {
        const matchesDesignator =
          filters.designator && filters.designator.length ? filters.designator?.includes(layer.designator) : true;
        const matchesResponsibleUnit = this.matchesResponsibleUnit(layer, filters);
        const matchesFromUntil = this.matchesFromUntil(layer, filters);
        const matchesFlMinMax = this.matchesFlMinMax(layer, filters);
        const matchesStatus = this.matchesStatus(layer, filters);
        return matchesDesignator && matchesResponsibleUnit && matchesFromUntil && matchesFlMinMax && matchesStatus;
      });
      this.mapLayers.set(filteredLayers);
    } else {
      this.mapLayers.set(this.allMapLayers);
    }
  }

  private getMinValuesFromReservations(reservations: AupReservation[]): AupMinMaxValues {
    const result = reservations.reduce(
      (acc, reservation: AupReservation) => {
        if (!acc.flMin || getAltitudeIndex(reservation.flMin) < getAltitudeIndex(acc.flMin)) {
          acc.flMin = reservation.flMin;
        }

        if (!acc.flMax || getAltitudeIndex(reservation.flMax) > getAltitudeIndex(acc.flMax)) {
          acc.flMax = reservation.flMax;
        }

        return acc;
      },
      {
        flMin: '',
        flMax: ''
      }
    );
    return result;
  }

  private matchesFlMinMax(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    const flMinFilter = filters.flMin ? getAltitudeIndex(filters.flMin) : getAltitudeIndex('GND');
    const flMaxFilter = filters.flMax ? getAltitudeIndex(filters.flMax) : getAltitudeIndex('UNL');

    const itemFlMin = getAltitudeIndex(layer.flMin);
    const itemFlMax = getAltitudeIndex(layer.flMax);

    if (layer.type === 'SECTOR') {
      return true;
    }
    return (
      (itemFlMin >= flMinFilter && itemFlMin <= flMaxFilter) || (itemFlMax >= flMinFilter && itemFlMax <= flMaxFilter)
    );
  }

  private matchesFromUntil(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    const filterFrom = filters.from ? filters.from.replace(/_/g, '0') : '06:00';
    const filterUntil = filters.until ? filters.until.replace(/_/g, '0') : '06:00';
    if (filterFrom === '06:00' && filterUntil === '06:00') return true;
    let matchesFromUntil = true;

    const filterFromMinutes = normalizeFromAupTime(filterFrom);
    const filterUntilMinutes = normalizeUntilAupTime(filterUntil);

    if (layer.reservations.length > 0) {
      matchesFromUntil = layer.reservations.some((reservation) => {
        if (reservation.from === '06:00' && reservation.until === '06:00') return true;
        const fromMinutes = normalizeFromAupTime(reservation.from);
        const untilMinutes = normalizeUntilAupTime(reservation.until);
        return (
          (fromMinutes >= filterFromMinutes && fromMinutes <= filterUntilMinutes) ||
          (untilMinutes >= filterFromMinutes && untilMinutes <= filterUntilMinutes)
        );
      });
    } else {
      if (layer.intervals.length === 0) return true;
      matchesFromUntil = layer.intervals.some((interval) => {
        if (interval.startTime === '06:00' && interval.endTime === '06:00') return true;
        const startTimeMinutes = normalizeFromAupTime(interval.startTime);
        const endTimeMinutes = normalizeUntilAupTime(interval.endTime);
        return (
          (startTimeMinutes >= filterFromMinutes && startTimeMinutes <= filterUntilMinutes) ||
          (endTimeMinutes >= filterFromMinutes && endTimeMinutes <= filterUntilMinutes)
        );
      });
    }

    return matchesFromUntil;
  }

  private matchesResponsibleUnit(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    if (filters.responsibleUnit?.length && layer.reservations.length) {
      return layer.reservations.some((reservation) => {
        return filters.responsibleUnit.includes(reservation.responsibleUnit);
      });
    }
    return true;
  }

  private matchesStatus(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    if (filters.status !== 'ALL' && filters.status?.length) {
      return layer.reservations.some((reservation) => {
        if (!filters.status) return true;
        return filters.status.toLowerCase() === reservation.status.toLowerCase();
      });
    }
    return true;
  }
}
