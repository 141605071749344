<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes" [disabledAreas]="disabledAreas"></rqa-map-toolbar>
  <div class="airspace-info">
    <p-accordion
      (activeIndexChange)="onToggleAccordion($event)"
      [styleClass]="'box-accordion'"
      [activeIndex]="activeAccordionIndex"
    >
      <p-accordionTab
        [ngClass]="{ 'region-disabled': layer.type === 'SECTOR' }"
        *ngFor="let layer of selectedLayers; let i = index; trackBy: trackByFn"
        [id]="layer.id"
      >
        <ng-container *ngIf="layer.type !== 'SECTOR'; else sector">
          <ng-template pTemplate="header">
            <rqa-airspace-info [airspaceInfo]="layer" (click)="onAccordionHeaderClick($event, i)"></rqa-airspace-info>
          </ng-template>
          <rqa-airspace-table
            [availabilities]="layer.availabilities"
            [designator]="layer.designator"
          ></rqa-airspace-table>
        </ng-container>
        <ng-template #sector>
          <ng-template pTemplate="header">
            <rqa-airspace-info [airspaceInfo]="layer" (click)="onAccordionHeaderClick($event, i)"></rqa-airspace-info>
          </ng-template>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div
    id="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
</div>
