@if (orders) {
  <p-table
    #dt
    [value]="orders"
    dataKey="uuid"
    [styleClass]="'pansa-table'"
    [scrollable]="true"
    [scrollHeight]="tableHeight"
    [rowExpandMode]="'single'"
    [(selection)]="selectedOrders"
    [breakpoint]="'960px'"
    [globalFilterFields]="['designator', 'startDate', 'endDate', 'upperAltitude', 'lowerAltitude', 'activityType']"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center lg:flex-row flex-column">
        <div>
          <span class="p-input-icon-left w-full lg:w-auto">
            <i class="pi pi-search"></i>
            <input
              class="w-full"
              pInputText
              type="text"
              (input)="filterReservations($event, 'containsWithDateFormat')"
              [placeholder]="'table.search' | translate"
            />
          </span>
        </div>
        <div class="flex align-items-center flex-column lg:flex-row p-2 lg:p-0">
          <p-calendar
            [(ngModel)]="dateRange"
            [dateFormat]="dateFormat"
            [iconDisplay]="'input'"
            [showIcon]="true"
            selectionMode="range"
            [readonlyInput]="true"
            [maxDate]="maxDate"
            [minDate]="minDate"
            (onSelect)="filterByDate($event)"
            #calendar
          >
            <ng-template pTemplate="footer">
              <div class="flex justify-content-between">
                <p-button
                  [label]="'primeng.clear' | translate"
                  [text]="true"
                  icon="pi pi-times"
                  (click)="clearFilters()"
                ></p-button>
              </div>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="text-xs">
        <th style="width: 4rem">
          <p-tableHeaderCheckbox />
        </th>
        <th class="text-xs pl-3 pr-2" pSortableColumn="designator">
          {{ 'table.header.designator' | translate }}
          <p-sortIcon field="designator"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="startDate">
          {{ 'table.header.startDate' | translate }}
          <p-sortIcon field="startDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="endDate">
          {{ 'table.header.endDate' | translate }}
          <p-sortIcon field="endDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="lowerAltitude">
          {{ 'table.header.lowerAltitude' | translate }}
          <p-sortIcon field="lowerAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="upperAltitude">
          {{ 'table.header.upperAltitude' | translate }}
          <p-sortIcon field="upperAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="activityType">
          {{ 'table.header.activityType' | translate }}
          <p-sortIcon field="activityType"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-expanded="expanded">
      <tr class="text-sm">
        <td>
          <p-tableCheckbox [value]="order" />
        </td>
        <td class="p-1 pl-3">
          <span class="p-column-title">{{ 'table.header.designator' | translate }}</span
          >{{ order.designator }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.startDate' | translate }}</span
          >{{ order.startDate | date: 'yyyy-MM-dd HH:mm' }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.endDate' | translate }}</span
          >{{ order.endDate | date: 'yyyy-MM-dd HH:mm' }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.lowerAltitude' | translate }}</span
          >{{ order.lowerAltitude }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.upperAltitude' | translate }}</span
          >{{ order.upperAltitude }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.activityType' | translate }}</span
          >{{ order.activityType }}
        </td>
        <td>
          <p-button
            type="button"
            pRipple
            (onClick)="op.toggle($event)"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            icon="pi pi-calendar-clock"
          />
          <p-overlayPanel #op (onShow)="showOrderHistory(order)" [styleClass]="'w-16rem'">
            <div *ngIf="order.reservationHistory?.length">
              <ul class="m-0 p-0 list-none">
                <li *ngFor="let history of order.reservationHistory">
                  <strong>{{ 'orders.history.' + history.historyEvent | translate }}:</strong> {{ history.eventDate }}
                </li>
              </ul>
            </div>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="8">
          <p-button
            [label]="'buttons.loadToRqa' | translate"
            [disabled]="selectedOrders.length === 0"
            (click)="onLoadSelectedOrders()"
            icon="pi pi-upload"
            [text]="true"
            [plain]="true"
          >
          </p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
