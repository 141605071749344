import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapHighlightService {
  private highlightSignal = signal<string | null>(null);

  readonly highlightedDesignator = this.highlightSignal.asReadonly();

  highlightLayer(designator: string | null) {
    this.highlightSignal.set(designator);
  }
}
