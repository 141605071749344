<p-calendar
  [(ngModel)]="value"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [dateFormat]="dateFormat"
  [showTime]="showTime"
  [showIcon]="false"
  [iconDisplay]="iconDisplay"
  [touchUI]="touchUI"
  [appendTo]="appendTo"
  [disabled]="disabled"
  (ngModelChange)="onModelChange($event)"
  [hideOnDateTimeSelect]="hideOnDateTimeSelect"
  class="rqa-calendar'"
  #calendar
>
  <ng-template pTemplate="footer">
    <div class="flex flex-column gap-2">
      @if (showTime) {
        <div class="flex align-items-center justify-content-center gap-2">
          <p-inputNumber
            [ngModel]="value | date: 'HH'"
            (ngModelChange)="updateTime($event, 'hours')"
            [showButtons]="true"
            buttonLayout="vertical"
            [min]="0"
            [max]="23"
            [size]="2"
            [maxlength]="2"
            inputId="stacked"
            [inputStyleClass]="'w-4rem p-1 text-sm text-center'"
          />
          <span>:</span>
          <p-inputNumber
            [ngModel]="value | date: 'mm'"
            (ngModelChange)="updateTime($event, 'minutes')"
            [showButtons]="true"
            buttonLayout="vertical"
            [min]="0"
            [max]="59"
            [size]="2"
            [maxlength]="2"
            [inputStyleClass]="'w-4rem p-1 text-sm text-center'"
          />
        </div>
      }
      @if (!hideOnDateTimeSelect) {
        <div class="flex justify-content-between">
          <p-button
            [label]="'buttons.cancel' | translate"
            [text]="true"
            icon="pi pi-times"
            size="small"
            (click)="onCancelCalendar()"
          ></p-button>
          <p-button
            [label]="'buttons.confirm' | translate"
            icon="pi pi-check"
            size="small"
            (click)="onConfirmCalendar()"
          ></p-button>
        </div>
      }
    </div>
  </ng-template>
</p-calendar>
