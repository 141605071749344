import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';
import { Template, TemplateDetails, ValidationErrors } from 'src/app/model/template.model';
import { TemplatesService } from 'src/app/services/templates.service';
import { ActivatedRoute } from '@angular/router';
import { Table, TableModule } from 'primeng/table';
import { AirspaceService } from 'src/app/services/airspace.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ReservationService } from 'src/app/services/reservation.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { generatePluralNames } from 'src/app/shared/utils/plural-names.util';
import { InputTextModule } from 'primeng/inputtext';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'rqa-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    DividerModule,
    InputMaskModule,
    InputTextModule,
    AutoCompleteModule,
    NgxMaterialTimepickerModule,
    IconComponent
  ]
})
export class EditTemplateComponent implements OnInit {
  private messageService = inject(MessageService);
  private reservationService = inject(ReservationService);
  private airSpaceService = inject(AirspaceService);
  private translateService = inject(TranslateService);
  private templateService = inject(TemplatesService);
  private confirmationService = inject(ConfirmationService);
  private dateService = inject(DateService);
  private route = inject(ActivatedRoute);
  private location = inject(Location);
  template: Template;
  @ViewChild('dt') pTable!: Table;
  ref: DynamicDialogRef | undefined;
  rows: TemplateDetails[] = [];
  selectedRows: TemplateDetails[] = [];
  clonedRows: { [s: string]: TemplateDetails } = {};
  designators: string[] = [];
  filteredDesignators: string[] = [];
  altitudes: string[];
  filteredAltitudes: string[];
  isEditedRow: boolean;
  isRequiredActivityType: boolean = false;
  rowsCount: string = '';
  isSavedTemplate = true;

  ngOnInit(): void {
    this.fetchDesignators();
    this.getTemplate();
  }

  saveTemplate(): void {
    const payload: Template = {
      ...this.template,
      templateDetailsDTOS: this.rows.map((details) => ({
        ...details,
        startTime: this.dateService.convertLocalTimeToUtc(details.startTime),
        endTime: this.dateService.convertLocalTimeToUtc(details.endTime)
      }))
    };
    this.templateService.updateTemplate(payload).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.updateTemplateSuccess'),
          life: 3000
        });
        this.goBack();
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('notifications.updateTemplateError'),
          life: 3000
        });

        console.log(err.error['validation-errors']);
        this.setErrorsInRow(err.error['validation-errors']);
      }
    });
    this.isSavedTemplate = true;
  }

  countChange(rowsCount: number): void {
    this.rowsCount = `${rowsCount} ${generatePluralNames(
      [
        this.translateService.instant('singularDesignator'),
        this.translateService.instant('pluralDesignator'),
        this.translateService.instant('multipleDesignator')
      ],
      rowsCount
    )}`;
  }

  onRowEditInit(row: TemplateDetails): void {
    this.pTable.initRowEdit(row);
    this.isEditedRow = true;
    this.clonedRows[row.uuid as string] = { ...row };
    if (row.designator) {
      this.onDesignatorSelect({ value: row.designator } as AutoCompleteSelectEvent);
    }
  }

  onRowEditSave(row: TemplateDetails, index: number): void {
    this.rows[index] = { ...row, validationErrors: [] };
    this.isEditedRow = false;
    this.isSavedTemplate = false;
  }

  onRowEditCancel(row: TemplateDetails, index: number): void {
    this.isEditedRow = false;
    this.rows[index] = this.clonedRows[row.uuid as string];
    delete this.clonedRows[row.uuid as string];
    this.countChange(this.rows.length);
  }

  onDeaggregate(): void {
    this.templateService.deaggregate(this.selectedRows).subscribe({
      next: (response) => {
        this.selectedRows.forEach((value) => {
          this.rows = this.rows.filter((row) => row !== value);
        });
        response.forEach((template) => {
          this.rows.push(template);
        });
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.deaggregateSuccess'),
          life: 3000
        });
        this.selectedRows = [];
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  onAggregate(): void {
    this.templateService.aggregate(this.selectedRows).subscribe({
      next: (response) => {
        this.selectedRows.forEach((value) => {
          this.rows = this.rows.filter((row) => row !== value);
        });
        response.forEach((template) => {
          this.rows.push(template);
        });
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.aggregateSuccess'),
          life: 3000
        });
        this.selectedRows = [];
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  searchDesignator(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();
    this.filteredDesignators = this.designators.filter((el) => el.toLowerCase().includes(query));
  }

  searchAltitude(
    event: AutoCompleteCompleteEvent,
    row: TemplateDetails,
    field: 'lowerAltitude' | 'upperAltitude'
  ): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
    if (this.filteredAltitudes.length === 1) {
      const rowIdx = this.rows.findIndex((el) => el.uuid === row.uuid);
      this.rows[rowIdx][field] = this.filteredAltitudes[0];
    }
  }

  onDesignatorSelect(event: AutoCompleteSelectEvent): void {
    const airSpace = this.airSpaceService.findAirspaceByDesignator(event.value);
    this.isRequiredActivityType = airSpace?.type !== 'D';
    const designatorId = airSpace?.id as number;
    if (airSpace?.id) {
      this.fetchAltitudes(designatorId);
    }
  }

  filterRows($event: Event): void {
    this.pTable.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
  }

  goBack(): void {
    if (!this.isSavedTemplate) {
      this.confirmationService.confirm({
        acceptLabel: this.translateService.instant('dialogs.confirm'),
        acceptButtonStyleClass: 'p-button',
        rejectLabel: this.translateService.instant('dialogs.reject'),
        rejectButtonStyleClass: 'p-button-text p-button-plain',
        message: this.translateService.instant('dialogs.leavePage.message'),
        header: this.translateService.instant('dialogs.leavePage.header'),
        accept: () => this.location.back()
      });
    } else {
      this.location.back();
    }
  }

  private getTemplate(): void {
    const templateId = this.route.snapshot.paramMap.get('id') as string;
    this.templateService.getTemplate(templateId).subscribe((res) => {
      this.template = res;
      this.rows = res.templateDetailsDTOS.map((details) => ({
        ...details,
        startTime: this.dateService.convertUtcToLocalTime(details.startTime),
        endTime: this.dateService.convertUtcToLocalTime(details.endTime)
      }));
    });
  }

  private fetchDesignators(): void {
    this.airSpaceService.fetchDesigators().subscribe((res: string[]) => {
      this.designators = res;
    });
  }

  private fetchAltitudes(designatorId: number): void {
    this.reservationService.fetchAltitudes(designatorId).subscribe((res: string[]) => {
      this.altitudes = res;
    });
  }

  private setErrorsInRow(errorList: ValidationErrors[]): void {
    this.rows.map((row) => {
      const errors = errorList.filter((err) => err.uuid === row.uuid);
      row.validationErrors = errors;
    });
  }
}
