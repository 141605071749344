import { Component, HostListener, inject, Input, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { SplitterModule } from 'primeng/splitter';
import { MapService } from 'src/app/map/map.service';

@Component({
  selector: 'rqa-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule, SplitterModule]
})
export class SplitterComponent {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  @Input() leftComponent!: Type<any>;
  @Input() rightComponent!: Type<any>;
  private mapService = inject(MapService);

  isMobile: boolean = window.innerWidth <= 768;
  visibleMap: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    this.isMobile = (event.target as Window).innerWidth <= 768;
  }

  onResizeSplitter(): void {
    this.mapService.setResizeMap();
  }

  onVisibleMap(): void {
    this.visibleMap = !this.visibleMap;
    if (this.visibleMap) {
      this.mapService.setResizeMap();
    }
  }
}
