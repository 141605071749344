import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { RqaMapService } from './rqa-map.service';
import { AirspaceElement } from 'src/app/model/map.model';

@Injectable({
  providedIn: 'root'
})
export class RqaMapResolver implements Resolve<AirspaceElement[]> {
  constructor(private rqaMapService: RqaMapService) {}

  resolve(): Observable<AirspaceElement[]> {
    return this.rqaMapService.fetchRqaMapLayers();
  }
}
