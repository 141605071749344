import { Directive, HostListener, Input } from '@angular/core';
import { Table } from 'primeng/table';

@Directive({
  selector: '[rqaTableKeyboardNavigation]',
  standalone: true
})
export class TableKeyboardNavigationDirective {
  @Input() dataKey?: string;

  constructor(private table: Table) {
    this.table.selectionChange.subscribe(() => {
      this.clearPreviousSelection();
    });
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.table.value || !this.table.value.length) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.moveSelection(1);
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.moveSelection(-1);
        break;
    }
  }

  private moveSelection(direction: number) {
    const currentSelection = this.table.selection;
    const data = this.table.value;

    let currentIndex = -1;
    if (currentSelection) {
      currentIndex = data.findIndex((item) =>
        this.dataKey ? item[this.dataKey] === currentSelection[this.dataKey] : item === currentSelection
      );
    }

    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < data.length) {
      const newSelection = data[newIndex];

      this.clearPreviousSelection();

      const rows = Array.from(this.table.el.nativeElement.querySelectorAll('tbody tr')) as HTMLTableRowElement[];

      const newRow = rows[newIndex];
      if (newRow) {
        newRow.classList.add('p-highlight');
        newRow.classList.add('p-selected');
      }

      this.table.selection = newSelection;
      this.table.onRowSelect.emit({
        originalEvent: new MouseEvent('click'),
        data: newSelection,
        type: 'row',
        index: newIndex
      });
    }
  }

  private clearPreviousSelection() {
    const rows = Array.from(this.table.el.nativeElement.querySelectorAll('tbody tr')) as HTMLTableRowElement[];
    rows.forEach((row) => {
      row.classList.remove('p-highlight', 'p-selected');
    });
  }
}
