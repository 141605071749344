import { AfterViewInit, Component, effect, inject, OnDestroy, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from 'src/app/map/map.component';
import { RqaMapService } from './rqa-map.service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AirspaceElement, MapLayerTypeEnum, WebrqaGeoJSONOptions } from 'src/app/model/map.model';
import { AccordionModule } from 'primeng/accordion';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { AirspaceInfoComponent } from './airspace-info/airspace-info.component';
import { AirspaceTableComponent } from './airspace-table/airspace-table.component';
import { MapToolbarComponent } from 'src/app/map/map-toolbar/map-toolbar.component';
import * as Turf from '@turf/turf';
import * as L from 'leaflet';
import { MapToolbarAction, MapToolbarPayload } from 'src/app/map/map-toolbar/map-toolbar-actions';
import { MapToolbarService } from 'src/app/map/map-toolbar/map-toolbar.service';
import { AdhocService } from 'src/app/services/adhoc.service';
import { circleToPolygon, convertCoordinate } from 'src/app/shared/utils/coordinates.utils';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IPoint } from 'src/app/model/adhoc.model';
import { StyleType } from 'src/app/model/layers.model';
import { Router } from '@angular/router';
import { MapHeightComponent } from 'src/app/map/map-height/map-height.component';
import { altitudeArrayObserver, getAltitudeIndex } from 'src/app/model/altitudes.model';
import { NavigationStart } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'rqa-create-map',
  templateUrl: './rqa-map.component.html',
  styleUrls: ['./rqa-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    AccordionModule,
    IconComponent,
    TooltipModule,
    ButtonModule,
    ToggleButtonComponent,
    AirspaceInfoComponent,
    AirspaceTableComponent,
    MapToolbarComponent,
    MapHeightComponent
  ]
})
export class RqaCreateMapComponent extends MapComponent implements AfterViewInit, OnDestroy {
  private rqaMapService = inject(RqaMapService);
  private toolbarService = inject(MapToolbarService);
  private adhocService = inject(AdhocService);
  private router = inject(Router);
  readonly rqaMapLayers: Signal<AirspaceElement[]> = this.rqaMapService.mapLayers;
  readonly toolbarAction: Signal<{ action: MapToolbarAction; payload?: MapToolbarPayload } | null> =
    this.toolbarService.actionSignal;
  activeAccordionIndex: number | null = null;
  selectedLayers: AirspaceElement[] = [];
  fetchLayersCount = 0;
  drawnItems = new L.FeatureGroup();
  heightRange: string[] = [altitudeArrayObserver[0], altitudeArrayObserver[altitudeArrayObserver.length - 1]];
  constructor() {
    super();

    effect(
      () => {
        this.getDisabledAreas();
        if (this.rqaMapLayers().length) {
          this.selectedLayers = [];
          this.createLayers(this.rqaMapLayers());
          this.fetchLayersCount++;
        } else {
          this.clearAllLayers();
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      if (this.toolbarAction()) {
        this.handleToolbarAction(this.toolbarAction());
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.resetAccordionState();
        this.rqaMapService.resetLayerSelection();
      });
  }

  ngAfterViewInit(): void {
    this.listenSelectLayerOnTable();
    this.listenDrawCircle();
    this.listenDrawPoly();
    this.toolbarService.clearAction();
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.resetLayerStyles();
    this.findIntersectingLayers(e.latlng);
    this.addMarker(e.latlng);
    this.activeAccordionIndex = this.selectedLayers.length === 1 ? 0 : null;
  }

  onToggleAccordion(index: number): void {
    if (index !== null) {
      const toggledLayer = this.selectedLayers[index];
      const layers = this.groupedLayers[toggledLayer.type].getLayers();
      layers.forEach((layer) => {
        const layerOptions = (layer as L.GeoJSON).options as WebrqaGeoJSONOptions;
        if (layerOptions.designator === toggledLayer.designator) {
          this.onLayerClick(layer as L.GeoJSON);
        }
      });
    } else {
      this.resetLayerStyles();
    }
  }

  onHeightRangeChange(heightRange: string[]): void {
    this.heightRange = heightRange;
    const minHeight = heightRange[0];
    const maxHeight = heightRange[1];

    this.filterLayersByHeightRange(minHeight, maxHeight);
  }

  private createLayers(layers: AirspaceElement[]): void {
    this.groupedLayers = {};
    this.clearAllLayers();
    layers.forEach((layerData) => {
      const { designator, type, geometry, styles, availabilities, info } = layerData;
      if (!this.groupedLayers[type]) {
        this.groupedLayers[type] = L.layerGroup().addTo(this.map);
      }

      const layer = new L.GeoJSON(
        geometry as Turf.GeometryObject,
        {
          designator,
          type,
          styles,
          availabilities,
          info,
          className: designator
        } as WebrqaGeoJSONOptions
      );

      if (layer) {
        this.groupedLayers[type].addLayer(layer);
        this.setLayerStyles(layer, styles, StyleType.ACTIVATED);
        layer.on('click', () => {
          this.selectedLayers = [];
          this.onLayerClick(layer);
          this.selectedLayers = [layer.options as AirspaceElement];
        });
      }
    });

    if (this.filterLayerTypes.length === 0) {
      this.filterLayerTypes = Object.keys(this.groupedLayers).map((type) => {
        const dynamicLayerTypes: string[] = [MapLayerTypeEnum.RQA, MapLayerTypeEnum.ORDER, MapLayerTypeEnum.TEMPLATE];
        return { type: type, visible: dynamicLayerTypes.includes(type), checked: dynamicLayerTypes.includes(type) };
      });
    } else {
      Object.keys(this.groupedLayers).map((type) => {
        const isExist = this.filterLayerTypes.find((el) => el.type === type);
        if (!isExist) {
          const dynamicLayerTypes: string[] = [MapLayerTypeEnum.RQA, MapLayerTypeEnum.ORDER, MapLayerTypeEnum.TEMPLATE];
          this.filterLayerTypes.push({
            type: type,
            visible: dynamicLayerTypes.includes(type),
            checked: dynamicLayerTypes.includes(type)
          });
        }
      });
    }
    this.filterLayerTypes = [...this.filterLayerTypes];

    this.filterLayerTypes.map((el) => {
      let checked = el.checked;
      if (this.router.url === '/') {
        checked = this.disabledAreas.includes(el.type) ? false : true;
      }
      this.toggleLayerGroupVisibility(checked, el.type);
    });
  }

  private handleToolbarAction(event: { action: MapToolbarAction; payload?: MapToolbarPayload } | null): void {
    if (event) {
      switch (event.action) {
        case 'zoomIn':
          this.zoomIn();
          break;
        case 'zoomOut':
          this.zoomOut();
          break;
        case 'search':
          this.onSearch();
          break;
        case 'toggleLayerGroup':
          if (event.payload) {
            this.toggleLayerGroupVisibility(event.payload.visible, event.payload.groupId);
            this.setDisabledAreas();
            this.filterLayersByHeightRange(this.heightRange[0], this.heightRange[1]);
            this.bringToFrontLayers([MapLayerTypeEnum.RQA, MapLayerTypeEnum.ORDER, MapLayerTypeEnum.TEMPLATE]);
          }
          break;
        case 'filters':
          this.toogleFilters();
      }
    }
  }

  onLayerClick(layer: L.GeoJSON): void {
    this.activeAccordionIndex = null;
    this.map.fitBounds(layer.getBounds(), { maxZoom: 8 });
    this.resetLayerStyles();
    this.highlightLayer(layer);
  }

  private listenSelectLayerOnTable(): void {
    this.rqaMapService
      .getLayerSelect()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedLayer) => {
        this.selectedLayers = [];
        this.resetLayerStyles();
        if (selectedLayer) {
          this.selectedLayers = this.rqaMapLayers().filter((el: AirspaceElement) => el.designator === selectedLayer);
          this.map.eachLayer((layer) => {
            const layerOptions = (layer as L.GeoJSON).options as WebrqaGeoJSONOptions;
            if (layerOptions.designator === selectedLayer) {
              this.onLayerClick(layer as L.GeoJSON);
              this.selectedLayers = [layer.options as AirspaceElement];
            }
          });
        }
        this.activeAccordionIndex = this.selectedLayers.length === 1 ? 0 : null;
      });
  }

  private findIntersectingLayers(latlng: L.LatLng): void {
    this.selectedLayers = [];
    const layersContainingPoint: L.GeoJSON[] = [];
    this.map.eachLayer((layer) => {
      if (this.map.hasLayer(layer)) {
        if (this.isPointInLayer(latlng, layer as L.GeoJSON)) {
          const layerData = layer.options as WebrqaGeoJSONOptions;
          const isExistDesignator = this.selectedLayers.find(
            (el: AirspaceElement) => el.designator === layerData.designator
          );
          if (!isExistDesignator) {
            this.selectedLayers.push(layerData as AirspaceElement);
            layersContainingPoint.push(layer as L.GeoJSON);
          }
        }
      }
    });
    if (layersContainingPoint.length === 1) {
      this.onLayerClick(layersContainingPoint[0]);
      this.bringToFrontLayers([MapLayerTypeEnum.RQA, MapLayerTypeEnum.ORDER, MapLayerTypeEnum.TEMPLATE]);
    }
  }

  private highlightLayer(layer: L.GeoJSON): void {
    const styles = (layer.options as WebrqaGeoJSONOptions).styles;
    this.setLayerStyles(layer, styles, StyleType.HIGHLIGHT);
    layer.bringToFront();
  }

  private cleanDrawLayers(): void {
    const drawnItems = this.drawnItems.getLayers();
    if (drawnItems.length) {
      drawnItems.forEach((layer) => {
        this.map.removeLayer(layer);
      });
    }
    if (this.map) {
      this.map.setView(new L.LatLng(environment.center[0], environment.center[1]), 6);
    }
    this.adhocService.setGeoJson(null);
  }

  private listenDrawCircle(): void {
    this.adhocService
      .getCircleToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((circleData) => {
        this.cleanDrawLayers();
        if (circleData) {
          const latLngConverted = new L.LatLng(
            convertCoordinate(circleData.latitude),
            convertCoordinate(circleData.longitude)
          );
          const circleLayer = L.circle(latLngConverted, { radius: circleData.radius });
          circleLayer.addTo(this.map);
          this.drawnItems.addLayer(circleLayer);
          this.map.fitBounds(circleLayer.getBounds());
          this.adhocService.setGeoJson(circleToPolygon(circleLayer));
        }
      });
  }

  private listenDrawPoly(): void {
    this.adhocService
      .getPolyToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((polyData) => {
        this.cleanDrawLayers();
        const latLng: L.LatLng[] = [];
        if (polyData) {
          polyData.points.forEach((point: IPoint) => {
            if (point.lat && point.lon) {
              const coords = new L.LatLng(convertCoordinate(point.lat), convertCoordinate(point.lon));
              latLng.push(coords);
            }
          });
          const polyLayer = L.polygon(latLng);
          polyLayer.addTo(this.map);
          this.drawnItems.addLayer(polyLayer);
          this.map.fitBounds(polyLayer.getBounds());
          this.adhocService.setGeoJson(polyLayer.toGeoJSON());
        }
      });
  }

  private filterLayersByHeightRange(minHeight: string, maxHeight: string): void {
    const minHeightIndex = getAltitudeIndex(minHeight);
    const maxHeightIndex = getAltitudeIndex(maxHeight);

    this.filterLayerTypes
      .filter((layerType) => layerType.checked)
      .forEach((layerType) => {
        const groupKey = layerType.type;
        const layerGroup = this.groupedLayers[groupKey];

        if (!layerGroup) return;

        layerGroup.eachLayer((layer: L.Layer) => {
          const geoJsonLayer = layer as L.GeoJSON;
          const options = geoJsonLayer.options as WebrqaGeoJSONOptions;

          if (!options.availabilities || options.availabilities.length === 0) {
            return;
          }

          const meetsHeightCriteria = options.availabilities.some((availability) => {
            const lowerAltitudeIndex = getAltitudeIndex(availability.lowerAltitude);
            const upperAltitudeIndex = getAltitudeIndex(availability.upperAltitude);

            return (
              (lowerAltitudeIndex >= minHeightIndex && lowerAltitudeIndex <= maxHeightIndex) ||
              (upperAltitudeIndex >= minHeightIndex && upperAltitudeIndex <= maxHeightIndex) ||
              (lowerAltitudeIndex <= minHeightIndex && upperAltitudeIndex >= maxHeightIndex)
            );
          });

          if (meetsHeightCriteria) {
            if (!this.map.hasLayer(geoJsonLayer)) {
              geoJsonLayer.addTo(this.map);
            }
          } else {
            if (this.map.hasLayer(geoJsonLayer)) {
              this.map.removeLayer(geoJsonLayer);
            }
          }
        });
      });
  }

  private resetAccordionState(): void {
    this.selectedLayers = [];
    this.activeAccordionIndex = null;
    this.resetLayerStyles();
  }

  ngOnDestroy(): void {
    this.resetAccordionState();
  }
}
