import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';
import { TemplatesListComponent } from './templates-list/templates-list.component';
import { RqaCreateMapComponent } from '../rqa-order/rqa-map/rqa-map.component';

@Component({
  selector: 'rqa-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  standalone: true,
  imports: [CommonModule, TemplatesListComponent, SplitterComponent, RqaCreateMapComponent]
})
export class TemplatesComponent {
  leftSideComponent = TemplatesListComponent;
  rightSideComponent = RqaCreateMapComponent;
}
