import { Injectable, signal, WritableSignal } from '@angular/core';
import { WindowSize } from '../model/layout.model';

@Injectable({
  providedIn: 'root'
})
export class LayoutUtilsService {
  isMobileDevice: WritableSignal<boolean | undefined> = signal(false);
  windowSize: WritableSignal<WindowSize> = signal({ width: window.innerWidth, height: window.innerHeight });
  notesVisible: WritableSignal<boolean> = signal(false);
  contentHeight: WritableSignal<number> = signal(0);

  calculateRemToPx(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  calculateContentHeight(): void {
    const headerHeight = document.querySelector('.page-header')?.clientHeight || 0;
    const holidaysHeight = document.querySelector('.holidays-info')?.clientHeight || 0;
    const totalOffset = headerHeight + holidaysHeight + 2;
    this.contentHeight.set(window.innerHeight - totalOffset);
  }
}
