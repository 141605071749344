<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes"></rqa-map-toolbar>
  <div class="selected-layers-box">
    <rqa-selected-map-layers [layers]="layersContainingPoint"></rqa-selected-map-layers>
  </div>

  <div
    id="map"
    [style.height.px]="contentHeight()"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
  @if (showFilters) {
    <div class="time-container">
      <rqa-map-time
        [filterTimeRange]="timeRange"
        [disabled]="disabledFilters"
        (timeRangeChange)="onTimeRangeChange($event)"
      ></rqa-map-time>
    </div>
    <div class="height-container">
      <rqa-map-height
        [disabled]="disabledFilters"
        [filterHeightRange]="heightRange"
        (heightRangeChange)="onHeightRangeChange($event)"
      ></rqa-map-height>
    </div>
  }
</div>
