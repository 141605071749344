import { CommonModule } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { ReservationService } from 'src/app/services/reservation.service';

@Component({
  selector: 'rqa-holidays-info',
  templateUrl: './holidays-info.component.html',
  styleUrl: './holidays-info.component.scss',
  imports: [CommonModule, ButtonModule],
  standalone: true
})
export class HolidaysInfoComponent {
  private reservationService = inject(ReservationService);
  private layoutUtilsService = inject(LayoutUtilsService);
  showInfo: boolean = true;
  holidaysInfo = this.reservationService.holidaysInfo;

  constructor() {
    effect(
      () => {
        if (this.holidaysInfo()?.value && this.showInfo) {
          setTimeout(() => {
            this.layoutUtilsService.calculateContentHeight();
          });
        }
      },
      { allowSignalWrites: true }
    );
  }

  closeInfo(): void {
    this.showInfo = false;
    this.reservationService.holidaysInfo.set(null);
    setTimeout(() => {
      this.layoutUtilsService.calculateContentHeight();
    });
  }
}
