import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CreateTemplateDTO,
  LoadTemplate,
  LoadTemplateResponse,
  Template,
  TemplateDetails
} from '../model/template.model';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  constructor(private http: HttpClient) {}

  readonly API_URL: string = '/api/rqa/template';
  private remarks = new BehaviorSubject<string | null>(null);
  private $remarks = this.remarks.asObservable();

  setRemarks(data: string | null): void {
    this.remarks.next(data);
  }

  getRemarks(): Observable<string | null> {
    return this.$remarks;
  }

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.API_URL}`);
  }

  getTemplate(uuid: string): Observable<Template> {
    return this.http.get<Template>(`${this.API_URL}/${uuid}`);
  }

  postTemplate(template: CreateTemplateDTO): Observable<string> {
    return this.http.post<string>(`${this.API_URL}`, template);
  }

  updateTemplate(template: Template): Observable<string> {
    return this.http.put<string>(`${this.API_URL}`, template);
  }

  loadTemplate(loadTemplate: LoadTemplate): Observable<LoadTemplateResponse> {
    return this.http.patch<LoadTemplateResponse>(`${this.API_URL}/load`, loadTemplate);
  }

  deleteTemplates(uuids: string[]): Observable<string> {
    return this.http.post<string>(`${this.API_URL}/delete`, uuids);
  }

  aggregate(templateSegments: TemplateDetails[]): Observable<TemplateDetails[]> {
    return this.http.post<TemplateDetails[]>(`${this.API_URL}/aggregate`, templateSegments);
  }

  deaggregate(templateSegments: TemplateDetails[]): Observable<TemplateDetails[]> {
    return this.http.post<TemplateDetails[]>(`${this.API_URL}/deaggregate`, templateSegments);
  }
}
