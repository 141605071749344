import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RqaCreateMapComponent } from './rqa-map/rqa-map.component';
import { RqaCreateComponent } from './rqa-create/rqa-create.component';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';

@Component({
  selector: 'rqa-order',
  templateUrl: './rqa-order.component.html',
  styleUrls: ['./rqa-order.component.scss'],
  standalone: true,
  imports: [CommonModule, RqaCreateComponent, SplitterComponent, RqaCreateMapComponent]
})
export class RqaOrderComponent {
  leftSideComponent = RqaCreateComponent;
  rightSideComponent = RqaCreateMapComponent;
}
