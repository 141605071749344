<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes" [disabledAreas]="disabledAreas"></rqa-map-toolbar>
  <div class="airspace-info">
    <p-accordion
      (activeIndexChange)="onToggleAccordion($event)"
      [styleClass]="'box-accordion'"
      [activeIndex]="activeAccordionIndex"
    >
      <p-accordionTab
        *ngFor="let layer of aupLayersInfo; trackBy: trackByFn"
        [id]="layer.id"
        [header]="layer.designator"
      >
        <ul class="list-none p-0 m-0 w-10rem">
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.flMin' | translate }}</div>
            <div class="text-900 text-right">{{ layer.flMin }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.flMax' | translate }}</div>
            <div class="text-900 text-right">{{ layer.flMax }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.from' | translate }}</div>
            <div class="text-900 text-right">{{ layer.from }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.until' | translate }}</div>
            <div class="text-900 text-right">{{ layer.until }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.responsibleUnit' | translate }}</div>
            <div class="text-900 text-right">{{ layer.responsibleUnit }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.remarks' | translate }}</div>
            <div class="text-900 text-right">{{ layer.remarks }}</div>
          </li>
          <li class="flex justify-content-between py-1 px-2">
            <div class="text-500 font-medium w-8rem">{{ 'aup.table.status' | translate }}</div>
            <div class="text-900 text-right">{{ layer.status }}</div>
          </li>
        </ul>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div
    id="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
</div>
>
