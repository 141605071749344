import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';
import { RqaCreateMapComponent } from '../rqa-order/rqa-map/rqa-map.component';
import { OrdersListComponent } from './orders-list/orders-list.component';

@Component({
  selector: 'rqa-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  standalone: true,
  imports: [CommonModule, OrdersListComponent, SplitterComponent, RqaCreateMapComponent]
})
export class OrdersComponent {
  leftSideComponent = OrdersListComponent;
  rightSideComponent = RqaCreateMapComponent;
}
