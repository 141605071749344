<div class="flex justify-content-start mb-2">
  <span class="switch">
    <input
      [id]="'switch-rounded-' + label"
      type="checkbox"
      (change)="changed.emit($event.target.checked)"
      [checked]="checked"
    />
    <label [for]="'switch-rounded-' + label" [class]="label" [ngClass]="{ disabled: !checked }"></label>
  </span>
  <span class="pl-1">{{ 'map.layers.' + label | translate }} </span>
</div>
