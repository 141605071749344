<section [ngClass]="{ 'slide-in-panel': true, show: isPanelVisible }" class="flex flex-column h-full">
  <header class="flex align-items-center justify-content-between p-3">
    <h2 class="m-0">{{ 'notes.title' | translate }}</h2>
    <p-button icon="pi pi-times" (click)="togglePanel()" [text]="true" [plain]="true" [rounded]="true"> </p-button>
  </header>
  <section class="notes-list flex-grow-1">
    @for (note of notes; track note.id) {
      <div class="note-item p-3">
        <p class="m-0">{{ note.content }}</p>
        <div class="note-date pt-3 text-xs">{{ note.created | date: 'yyyy-MM-dd | HH:mm' }}</div>
      </div>
    }
  </section>
  <form [formGroup]="form" class="notes-form p-3">
    <div class="flex justify-content-between align-items-center">
      <label for="content" class="line-height-4">{{ 'notes.content' | translate }}</label>
      <div class="text-sm char-count">{{ form.get('content')?.value?.length ?? 0 }} / 1500</div>
    </div>
    <textarea
      rows="5"
      cols="30"
      pInputTextarea
      [autoResize]="true"
      class="w-full"
      formControlName="content"
      [placeholder]="'notes.addContent' | translate"
    ></textarea>
    <rqa-validator [control]="form.get('content')"></rqa-validator>
    <p-button
      [label]="'notes.addNote' | translate"
      [styleClass]="'mt-2 w-full'"
      (click)="addNote()"
      [disabled]="form.invalid"
    ></p-button>
  </form>
</section>
