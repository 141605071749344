import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AirspaceElement, EPDAirspaceDTO } from '../model/map.model';

@Injectable({
  providedIn: 'root'
})
export class AirspaceService {
  http = inject(HttpClient);
  designators: string[] = [];
  readonly API_URL: string = '/api/airspace';
  airspaceValues: WritableSignal<AirspaceElement[]> = signal([]);

  fetchDesigators(): Observable<string[]> {
    return this.http.get<string[]>(`${this.API_URL}/designators`);
  }

  fetchAirspace(): Observable<AirspaceElement[]> {
    return this.http
      .get<AirspaceElement[]>(`${this.API_URL}`)
      .pipe(tap((airspaces) => this.airspaceValues.set(airspaces)));
  }

  getEPDAirspace() {
    return this.http.get<EPDAirspaceDTO[]>(`${this.API_URL}/additions`);
  }

  findAirspace(id: number) {
    return this.airspaceValues().filter((value) => value.id === id)[0];
  }

  findAirspaceByDesignator(designator: string) {
    const values = this.airspaceValues().filter((value) => value.designator.toUpperCase() === designator);
    return values.length ? values[0] : null;
  }
}
