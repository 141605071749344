import { Component, inject, Input, OnInit } from '@angular/core';
import { Availability } from 'src/app/model/map.model';
import { ReservationDTO, TimeRange } from 'src/app/model/reservation.model';
import { ReservationService } from 'src/app/services/reservation.service';
import * as moment from 'moment';
import { DateService } from 'src/app/services/date.service';
import { MessageService } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TableModule } from 'primeng/table';
import { DatePipe, NgIf } from '@angular/common';
import { RqaMapService } from '../rqa-map.service';
import { ButtonModule } from 'primeng/button';
import { TYPES } from '../airspace-info/airspace-info.component';

@Component({
  selector: 'rqa-airspace-table',
  templateUrl: './airspace-table.component.html',
  styleUrls: ['./airspace-table.component.scss'],
  standalone: true,
  imports: [TableModule, TranslateModule, DatePipe, ButtonModule, NgIf]
})
export class AirspaceTableComponent implements OnInit {
  @Input() set availabilities(value: Availability[]) {
    this._availabilities = value.map((el) => {
      const availabilityCopy = { ...el };
      availabilityCopy.startDate = this.dateService.parseIsoDateToString(el.startDate);
      availabilityCopy.endDate = this.dateService.parseIsoDateToString(el.endDate);
      return availabilityCopy;
    });
  }

  @Input() designator: string;
  @Input() type: string;

  _availabilities: Availability[];
  commonInterval: TimeRange | null;
  private rqaMapService = inject(RqaMapService);
  private reservationService = inject(ReservationService);
  private dateService = inject(DateService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private router = inject(Router);

  ngOnInit(): void {
    const commonInterval = this.reservationService.commonDateInterval();
    if (commonInterval) {
      this.commonInterval = {
        startDate: this.dateService.parseIsoDateToString(commonInterval.startDate),
        endDate: this.dateService.parseIsoDateToString(commonInterval.endDate)
      };
    }
  }

  copyRow(availability: Availability): void {
    const reservation: ReservationDTO = {
      designator: this.designator,
      endDate: this.calculateEndDate(availability.endDate, availability.startDate),
      lowerAltitude: availability.lowerAltitude,
      startDate: this.calculateStartDate(availability.startDate, availability.endDate),
      upperAltitude: availability.upperAltitude
    };
    this.rqaMapService.selectRowToCopy({ row: reservation, designator: undefined });
    this.displayCopySuccess();
    this.router.navigate(['/']);
  }

  private calculateStartDate(rowStartDate: string, rowEndDate: string): string {
    if (this.commonInterval) {
      const startDate = moment(rowStartDate);
      const endDate = moment(rowEndDate);
      const intervalStartDate = moment(this.commonInterval.startDate);
      const intervalEndDate = moment(this.commonInterval.endDate);
      if (startDate.isSameOrAfter(intervalStartDate) && startDate.isSameOrBefore(intervalEndDate)) {
        return rowStartDate;
      } else if (endDate.isSameOrBefore(intervalStartDate)) {
        return '';
      } else {
        return this.dateService.parseDateToIsoFormat(this.commonInterval.startDate);
      }
    }
    return '';
  }

  private calculateEndDate(rowEndDate: string, rowStartDate: string): string {
    if (this.commonInterval) {
      const endDate = moment(rowEndDate);
      const startDate = moment(rowStartDate);
      const intervalStartDate = moment(this.commonInterval.startDate);
      const intervalEndDate = moment(this.commonInterval.endDate);
      if (endDate.isSameOrBefore(intervalEndDate) && startDate.isSameOrAfter(intervalStartDate)) {
        return rowEndDate;
      } else {
        if (startDate.isAfter(intervalStartDate) && endDate.isAfter(intervalEndDate)) {
          const date = moment(this.dateService.setReservationEndDate(startDate.toDate()));
          return date.isSameOrAfter(intervalEndDate)
            ? this.dateService.parseDateToIsoFormat(intervalEndDate.toString())
            : date.toString();
        } else if (startDate.isSameOrBefore(intervalStartDate)) {
          const date = moment(this.dateService.setReservationEndDate(intervalStartDate.toDate()));
          return date.isSameOrAfter(endDate) ? rowEndDate : date.toString();
        } else {
          return this.dateService.setReservationEndDate(intervalEndDate.toDate());
        }
      }
    }
    return '';
  }

  private displayCopySuccess(): void {
    this.messageService.add({
      severity: 'success',
      detail: this.translateService.instant('notifications.copyRowToTable'),
      life: 3000
    });
  }

  protected readonly TYPES = TYPES;
}
