<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  @if (template?.name) {
    <section
      class="flex justify-content-between lg:mb-5 px-3 lg:px-0 align-items-center w-full flex-column lg:flex-row"
    >
      <div>
        <h1 class="mx-0 my-0 title"><strong>Szablon:</strong> {{ template.name }}</h1>
      </div>
      <div class="flex justify-content-between lg:justify-content-end mt-3 lg:mt-0 w-full lg:w-auto">
        <div>
          <p-button
            [label]="'buttons.goBack' | translate"
            [text]="true"
            [plain]="true"
            (click)="goBack()"
            icon="pi pi-arrow-left"
          ></p-button>
          <p-button
            [disabled]="isEditedRow"
            [label]="'buttons.saveChanges' | translate"
            [text]="true"
            [plain]="true"
            (click)="saveTemplate()"
            icon="pi pi-save"
          ></p-button>
        </div>
      </div>
    </section>

    <p-table
      [styleClass]="'pansa-table'"
      [value]="rows"
      [responsive]="true"
      [responsiveLayout]="'stack'"
      [breakpoint]="'960px'"
      dataKey="uuid"
      editMode="row"
      #dt
      [scrollable]="true"
      scrollHeight="400px"
      [breakpoint]="'960px'"
      [(selection)]="selectedRows"
      [globalFilterFields]="['designator', 'startTime', 'endTime', 'upperAltitude', 'lowerAltitude', 'remarks']"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="filterRows($event)" [placeholder]="'table.search' | translate" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th>
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="designator">
            {{ 'table.header.designator' | translate }} <p-sortIcon field="designator"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="startTime">
            {{ 'table.header.startDate' | translate }} <p-sortIcon field="startTime"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="endTime">
            {{ 'table.header.endDate' | translate }} <p-sortIcon field="endTime"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="lowerAltitude">
            {{ 'table.header.lowerAltitude' | translate }} <p-sortIcon field="lowerAltitude"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="upperAltitude">
            {{ 'table.header.upperAltitude' | translate }} <p-sortIcon field="upperAltitude"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2" pSortableColumn="remarks">
            {{ 'table.header.activityType' | translate }} <p-sortIcon field="remarks"></p-sortIcon>
          </th>
          <th class="text-xs pl-1 pr-2"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-editing="editing" let-ri="rowIndex" let-columns="columns">
        <tr
          [pEditableRow]="row"
          class="reservation-row text-sm"
          [ngClass]="{ 'row-error': row?.validationErrors?.length }"
        >
          <td>
            <div class="flex align-items-center">
              <p-tableCheckbox [value]="row" [disabled]="isEditedRow"></p-tableCheckbox>
            </div>
          </td>
          <td class="p-1">
            <span class="p-column-title">{{ 'table.header.designator' | translate }}</span>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete
                  inputStyleClass="w-6rem"
                  appendTo="body"
                  [(ngModel)]="row.designator"
                  [autoHighlight]="true"
                  [suggestions]="filteredDesignators"
                  (completeMethod)="searchDesignator($event)"
                  (onSelect)="onDesignatorSelect($event)"
                  [forceSelection]="true"
                  [completeOnFocus]="true"
                ></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                <strong>{{ row.designator }}</strong>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-1">
            <span class="p-column-title">{{ 'table.header.startDate' | translate }}</span>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [ngxTimepicker]="startTime" [format]="24" [(ngModel)]="row.startTime" />
                <ngx-material-timepicker
                  #startTime
                  [timepickerClass]="'pansa-timepicker'"
                  [cancelBtnTmpl]="cancelBtn"
                  [confirmBtnTmpl]="confirmBtn"
                ></ngx-material-timepicker>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row.startTime }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-1">
            <p-cellEditor>
              <span class="p-column-title">{{ 'table.header.endDate' | translate }}</span>
              <ng-template pTemplate="input">
                <input pInputText [ngxTimepicker]="endTime" [format]="24" [(ngModel)]="row.endTime" />
                <ngx-material-timepicker
                  #endTime
                  [timepickerClass]="'pansa-timepicker'"
                  [cancelBtnTmpl]="cancelBtn"
                  [confirmBtnTmpl]="confirmBtn"
                ></ngx-material-timepicker>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row.endTime }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-1">
            <span class="p-column-title">{{ 'table.header.lowerAltitude' | translate }}</span>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete
                  appendTo="body"
                  inputStyleClass="w-4rem"
                  [(ngModel)]="row.lowerAltitude"
                  [suggestions]="filteredAltitudes"
                  (completeMethod)="searchAltitude($event, row, 'lowerAltitude')"
                  [forceSelection]="true"
                  [disabled]="!row.designator"
                  [completeOnFocus]="true"
                ></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row.lowerAltitude }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-1">
            <span class="p-column-title">{{ 'table.header.upperAltitude' | translate }}</span>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete
                  inputStyleClass="w-4rem"
                  appendTo="body"
                  [(ngModel)]="row.upperAltitude"
                  [suggestions]="filteredAltitudes"
                  (completeMethod)="searchAltitude($event, row, 'upperAltitude')"
                  [forceSelection]="true"
                  [disabled]="!row.designator"
                  [completeOnFocus]="true"
                ></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row.upperAltitude }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-1">
            <span class="p-column-title">{{ 'table.header.activityType' | translate }}</span>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="row.remarks" required oninput="this.value = this.value.toUpperCase()" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ row.remarks }}
              </ng-template>
            </p-cellEditor>
          </td>

          <td class="p-1">
            <div class="flex align-items-center justify-content-center">
              <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-pencil"
                (click)="onRowEditInit(row)"
                class="p-button-rounded p-button-text mr-2 h-2rem w-2rem"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onRowEditSave(row, ri)"
                class="p-button-rounded p-button-text p-button-success mr-2 h-2rem w-2rem"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onRowEditCancel(row, ri)"
                class="p-button-rounded p-button-text p-button-danger h-2rem w-2rem"
              ></button>
            </div>
          </td>
        </tr>
        <tr *ngIf="row?.validationErrors?.length">
          <td colspan="8" class="p-2">
            <ul class="m-0">
              @for (err of row.validationErrors; track err.field) {
                <li class="text-xs p-error">
                  <strong>{{ 'table.header.' + err.field | translate }}: </strong>{{ err.message }}
                </li>
              }
            </ul>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="text-center">
            @if (!rows.length) {
              {{ 'table.noResults' | translate }}
              <a href="#" (click)="onRowAdd()">{{ 'buttons.here' | translate }}</a>
            } @else {
              {{ 'table.noResultsFilter' | translate }}
            }
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="8">
            <div class="flex align-items-center">
              <p-button
                [label]="'buttons.disaggregate' | translate"
                [disabled]="!selectedRows.length || isEditedRow"
                (click)="onDeaggregate()"
                [text]="true"
                [plain]="true"
              >
                <rqa-icon iconName="file-minus" class="p-button-icon-left"></rqa-icon>
              </p-button>
              <p-button
                [label]="'buttons.aggregate' | translate"
                [disabled]="selectedRows.length < 2 || isEditedRow"
                (click)="onAggregate()"
                [text]="true"
                [plain]="true"
              >
                <rqa-icon iconName="file-plus" class="p-button-icon-left"></rqa-icon>
              </p-button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template #cancelBtn>
      <p-button
        [label]="'buttons.cancel' | translate"
        [text]="true"
        [plain]="true"
        icon="pi pi-minus-circle"
      ></p-button>
    </ng-template>
    <ng-template #confirmBtn>
      <p-button [label]="'buttons.save' | translate" icon="pi pi-check" (click)="save()"></p-button>
    </ng-template>
    <ng-container>
      <div class="mt-3 ml-2 card justify-center remarks-left-border">
        <div class="ml-2">
          <div>
            <p class="remarks-title">{{ 'templates.remarks' | translate }}</p>
          </div>
          <div class="flex flex-column gap-2 mt-4">
            <div class="ml-2 remarks" [formGroup]="form">
              <textarea
                class="w-full"
                rows="5"
                cols="30"
                pInputTextarea
                formControlName="remarks"
                rqaNoPolishChars
                oninput="this.value = this.value.toUpperCase()"
              ></textarea>
              <div>{{ form.get('remarks')?.value?.length || 0 }} / 1000</div>
              <rqa-validator [control]="form.get('remarks')"></rqa-validator>
              <small *ngIf="form.get('remarks')?.value && form.get('remarks')?.errors?.values" class="p-error">
                {{ form.controls.remarks?.errors['values'] }}
              </small>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  }
</div>
