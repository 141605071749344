import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AupMapService } from './aup-map.service';
import { AirspaceElement } from 'src/app/model/map.model';
import { AupService } from 'src/app/services/aup.service';

@Injectable({
  providedIn: 'root'
})
export class AupMapResolver implements Resolve<AirspaceElement[]> {
  constructor(
    private aupMapService: AupMapService,
    private aupService: AupService
  ) {}

  resolve(): Observable<AirspaceElement[]> {
    this.aupService.updateFilters(null);
    this.aupService.updateSort(null);
    return this.aupMapService.fetchAupMapLayers();
  }
}
