import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  EditMultipleDates,
  ReservationDTO,
  RqaCreateDTO,
  RqaReservationDTO,
  RqaStatus,
  TimeRange,
  WebrqaResponse
} from '../model/reservation.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  http = inject(HttpClient);
  readonly API_URL: string = '/api/rqa';
  clearTableSrc = new BehaviorSubject<boolean>(false);
  commonDateInterval$ = new BehaviorSubject<TimeRange | null>(null);
  holidaysInfoSubject = new BehaviorSubject<WebrqaResponse | null>(null);
  holidaysInfo$ = this.holidaysInfoSubject.asObservable();

  get clearTable$(): Observable<boolean> {
    return this.clearTableSrc.asObservable();
  }

  get commonInterval(): Observable<TimeRange | null> {
    return this.commonDateInterval$.asObservable();
  }

  setClearTable(clearTable: boolean): void {
    this.clearTableSrc.next(clearTable);
  }

  getReservations(): Observable<ReservationDTO[]> {
    return this.http.get<ReservationDTO[]>(`${this.API_URL}/reservation`);
  }

  getReservableInterval(): Observable<TimeRange> {
    return this.http.get<TimeRange>(`${this.API_URL}/reservable-interval`);
  }

  getCommonReservableInterval(): Observable<TimeRange> {
    return this.http.get<TimeRange>(`${this.API_URL}/reservable-interval/common`).pipe(
      tap((range: TimeRange) => {
        this.commonDateInterval$.next(range);
      })
    );
  }

  send(rqa: RqaCreateDTO) {
    return this.http.post<RqaStatus>(`${this.API_URL}`, rqa);
  }

  addReservation(reservation: ReservationDTO): Observable<ReservationDTO> {
    return this.http.post<ReservationDTO>(`${this.API_URL}/reservation`, reservation);
  }

  update(reservation: ReservationDTO) {
    return this.http.put<ReservationDTO>(`${this.API_URL}/reservation/${reservation.uuid}`, reservation);
  }

  addRadRsReservation(reservation: ReservationDTO): Observable<ReservationDTO[]> {
    return this.http.post<ReservationDTO[]>(`${this.API_URL}/reservation/restriction`, reservation);
  }

  updateMultiple(reservations: EditMultipleDates) {
    return this.http.patch<RqaReservationDTO[]>(`${this.API_URL}/reservation/update`, reservations);
  }

  delete(uuid: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.API_URL}/reservation/${uuid}`);
  }

  deleteMutliple(uuids: string[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.API_URL}/reservation/delete`, uuids);
  }

  aggregate(uuids: string[]): Observable<ReservationDTO[]> {
    return this.http.patch<ReservationDTO[]>(`${this.API_URL}/reservation/aggregate`, uuids);
  }

  deaggregate(uuids: string[]): Observable<ReservationDTO[]> {
    return this.http.patch<ReservationDTO[]>(`${this.API_URL}/reservation/deaggregate`, uuids);
  }

  fetchAltitudes(id: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.API_URL}/reservation/altitudes?id=${id}`);
  }

  deleteCartReservations(): Observable<boolean> {
    return this.http.delete<boolean>(`${this.API_URL}/reservation/`);
  }

  getHolidays(): Observable<WebrqaResponse> {
    return this.http
      .get<WebrqaResponse>(`${this.API_URL}/reservation/holidays`)
      .pipe(tap((res) => this.holidaysInfoSubject.next(res)));
  }
}
