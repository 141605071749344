import { Component, effect, inject, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from 'src/app/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AirspaceElement } from 'src/app/model/map.model';
import { AccordionModule } from 'primeng/accordion';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { AupMapService } from './aup-map.service';
import { MapToolbarComponent } from 'src/app/map/map-toolbar/map-toolbar.component';
import { AupService } from 'src/app/services/aup.service';
import { AupReservation } from 'src/app/model/observer.model';
import { layersStyle } from 'src/app/map/layersStyle';

@Component({
  selector: 'rqa-aup-map',
  templateUrl: './aup-map.component.html',
  styleUrls: ['./aup-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    AccordionModule,
    IconComponent,
    TooltipModule,
    ButtonModule,
    ToggleButtonComponent,
    MapToolbarComponent,
    AccordionModule
  ]
})
export class AupMapComponent extends MapComponent {
  private aupMapService = inject(AupMapService);
  private aupService = inject(AupService);
  readonly aupMapLayers: Signal<AirspaceElement[]> = this.aupMapService.mapLayers;
  readonly aupReservations: Signal<AupReservation[]> = this.aupService.filteredReservation;
  displayedAupReservations: AupReservation[] | null = null;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  aupLayersInfo: any[] | null = [];
  activeAccordionIndex: number = 0;
  constructor() {
    super();

    effect(
      () => {
        if (this.aupMapLayers().length) {
          this.disabledAreas = this.filterLayerTypes.filter((el) => el.type !== 'aup').map((el) => el.type);
          this.createLayers(this.aupMapLayers());
        }

        this.displayedAupReservations = this.aupReservations();
      },
      { allowSignalWrites: true }
    );
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.clickMap(e);
    this.setAupLayersInfo();
  }

  onToggleAccordion(index: number): void {
    if (index !== null) {
      const toggledLayer = this.selectedLayers[index];
      const layer = this.layersRef.find((el) => el.name === toggledLayer.designator)?.layer;
      if (layer) {
        if (this.lastActiveLayer) {
          const lastToggledLayer = this.layersRef.find((el) => el.name === this.lastActiveLayer.designator);
          lastToggledLayer?.layer?.setStyle(layersStyle[toggledLayer.type.toLowerCase()].styles.activated);
        }

        const watchStyle = layersStyle[toggledLayer.type.toLowerCase()].styles;
        layer.setStyle(watchStyle.highlight);
        layer.bringToFront();
        this.lastActiveLayer = toggledLayer;
      }
    } else {
      this.resetLayerStyles();
    }
  }

  private setAupLayersInfo(): void {
    if (this.selectedLayers.length === 0) {
      this.aupLayersInfo = [];
      return;
    }
    this.aupLayersInfo = this.selectedLayers.map((el) => {
      return this.displayedAupReservations?.find((layer) => layer.designator === el.designator);
    });
  }
}
