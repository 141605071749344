import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SelectedMapLayer } from 'src/app/model/map.model';
import { MapHighlightService } from 'src/app/services/map-highlight.service';
@Component({
  selector: 'rqa-selected-map-layers',
  templateUrl: './selected-map-layers.component.html',
  styleUrls: ['./selected-map-layers.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class SelectedMapLayersComponent {
  @Input() layers: SelectedMapLayer[] = [];
  private mapHighlightService = inject(MapHighlightService);

  onMouseEnter(item: SelectedMapLayer): void {
    item.showChildren = true;
    this.mapHighlightService.highlightLayer(item.designator);
  }

  onMouseLeave(item: SelectedMapLayer): void {
    item.showChildren = false;
    this.mapHighlightService.highlightLayer(null);
  }
}
