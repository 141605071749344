import { Component, effect, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NotesService } from 'src/app/services/note.service';
import { NoteDTO } from 'src/app/model/notes.model';
import { ValidatorComponent } from '../validator/validator.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DateService } from 'src/app/services/date.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'rqa-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    ButtonModule,
    InputTextareaModule,
    ValidatorComponent,
    ConfirmDialogModule
  ]
})
export class NotesComponent implements OnInit {
  private layoutUtilsService = inject(LayoutUtilsService);
  private fb = inject(FormBuilder);
  private noteService = inject(NotesService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private dateService = inject(DateService);
  private confirmationService = inject(ConfirmationService);

  isPanelVisible: boolean = false;
  form = this.createForm();
  notes: NoteDTO[] = [];
  editingNote: NoteDTO | null = null;

  constructor() {
    effect(() => {
      this.isPanelVisible = this.layoutUtilsService.notesVisible();
    });
  }

  ngOnInit(): void {
    this.getNotes();
  }

  togglePanel(): void {
    this.layoutUtilsService.notesVisible.set(false);
    this.form.reset();
    this.editingNote = null;
  }

  addNote(): void {
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      this.noteService.create(payload.content as string).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.noteAdded'),
            life: 3000
          });
          this.form.reset();
          this.getNotes();
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            life: 3000
          });
        }
      });
    }
  }

  editNote(note: NoteDTO): void {
    this.editingNote = note;
    this.form.patchValue({
      content: note.content
    });
  }

  updateNote(): void {
    if (this.form.valid && this.editingNote) {
      const payload = this.form.getRawValue();
      this.noteService.edit({ ...this.editingNote, content: payload.content as string }).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.noteUpdated'),
            life: 3000
          });
          this.form.reset();
          this.editingNote = null;
          this.getNotes();
        },
        error: (err: { error: { message: string } }) => {
          this.messageService.add({
            severity: 'error',
            detail: err.error.message,
            life: 3000
          });
        }
      });
    }
  }

  confirmDelete(note: NoteDTO): void {
    this.confirmationService.confirm({
      message: this.translateService.instant('dialogs.deleteNote.msg'),
      rejectLabel: this.translateService.instant('dialogs.reject'),
      rejectButtonStyleClass: 'p-button-text p-button-plain',
      header: this.translateService.instant('dialogs.deleteNote.header'),
      accept: () => {
        this.deleteNote(note);
      }
    });
  }

  private deleteNote(note: NoteDTO): void {
    this.noteService.delete(note.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.noteDeleted'),
          life: 3000
        });
        this.getNotes();
      },
      error: (err: { error: { message: string } }) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  private getNotes() {
    this.noteService.getNotes().subscribe((notes) => {
      this.notes = notes
        .map((el) => {
          return {
            ...el,
            created: this.dateService.convertDateToUserTimezone(el.created)
          };
        })
        .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    });
  }

  private createForm() {
    return this.fb.group({
      content: ['', [Validators.required, Validators.maxLength(1500)]]
    });
  }
}
