import { Component, OnInit, ViewChild, inject, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersService } from 'src/app/services/orders.service';
import { CancelledRqa } from 'src/app/model/reservation.model';
import { Table, TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import * as moment from 'moment';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateService } from 'src/app/services/date.service';
import { DATE_TIME_FORMAT } from 'src/app/shared/constants/date.constants';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UserConfigService } from 'src/app/services/user-config.service';
import { InputTextModule } from 'primeng/inputtext';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
@Component({
  selector: 'rqa-canceled-orders',
  templateUrl: './canceled-orders.component.html',
  styleUrls: ['./canceled-orders.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    TranslateModule,
    CalendarModule,
    OverlayPanelModule,
    InputTextModule
  ],
  providers: [RouterModule]
})
export class CanceledOrdersComponent implements OnInit, AfterViewInit {
  @ViewChild('dt') pTable!: Table;
  @ViewChild('calendar') pCalendar!: Calendar;
  private ordersService = inject(OrdersService);
  private dateService = inject(DateService);
  private userConfig = inject(UserConfigService);
  private layoutUtilsService = inject(LayoutUtilsService);
  orders: CancelledRqa[];
  dateRange: Date[];
  dateFormat: string = 'yy-mm-dd 06:00';
  tableHeight: string = '400px';
  ngOnInit(): void {
    this.getCancelledOrders();
    this.setFilterDateFormat();
  }

  ngAfterViewInit(): void {
    this.getTableHeight();
  }

  filterByDate(): void {
    if (this.dateRange.length && this.dateRange[1] !== null) {
      const startDate = moment(this.dateRange[0]).set({ hour: 6, minute: 0 }).format(DATE_TIME_FORMAT);
      let endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format(DATE_TIME_FORMAT);
      if (startDate === endDate) {
        this.dateRange[1] = new Date(moment(startDate).add(1, 'days').format(DATE_TIME_FORMAT));
        endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format(DATE_TIME_FORMAT);
      }
      this.pTable.filter(this.dateService.convertDateToUserTimezone(startDate), 'startDate', 'dateSameAfter');
      this.pTable.filter(this.dateService.convertDateToUserTimezone(endDate), 'endDate', 'dateSameBefore');
      this.pCalendar.overlayVisible = false;
    }
  }

  clearFilters(): void {
    this.dateRange = [];
    this.pTable.filters = {};
    this.pTable._filter();
  }

  onDateSelect(): void {
    if (
      this.dateRange &&
      this.dateRange[0] &&
      this.dateRange[1] &&
      this.dateRange[0].getTime() === this.dateRange[1].getTime()
    ) {
      this.dateRange = [this.dateRange[0]];
    }
  }

  showOrderHistory(order: CancelledRqa): void {
    this.getReservationHistory(order.uuid);
  }

  filterReservations($event: Event, matchType: string): void {
    this.pTable.filterGlobal(($event.target as HTMLInputElement).value, matchType);
  }

  private getReservationHistory(uuid: string): void {
    const orderIdx = this.orders.findIndex((order) => order.uuid === uuid);
    if (this.orders[orderIdx].reservationHistory?.length) return;
    this.ordersService.getReservationHistory(uuid).subscribe((data) => {
      this.orders[orderIdx].reservationHistory = data;
    });
  }

  private getCancelledOrders(): void {
    this.ordersService.getCancelledReservations().subscribe((data) => {
      this.orders = data.map((el) => {
        el.startDate = this.dateService.convertDateToUserTimezone(el.startDate);
        el.endDate = this.dateService.convertDateToUserTimezone(el.endDate);
        return el;
      });
    });
  }

  private setFilterDateFormat(): void {
    const timeZone = this.userConfig.getTimezone();
    const hours = '06:00';
    const localHours = this.dateService.adjustTimeToLocal(hours);
    if (timeZone !== 'UTC') {
      this.dateFormat = 'yy-mm-dd ' + localHours;
    } else {
      this.dateFormat = 'yy-mm-dd ' + hours;
    }
  }
  private getTableHeight(): void {
    setTimeout(() => {
      this.tableHeight = this.layoutUtilsService.getTableHeightForOrders();
    }, 1000);
  }
}
