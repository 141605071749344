import { Injectable, signal } from '@angular/core';
import { MapToolbarAction, MapToolbarPayload } from './map-toolbar-actions';

@Injectable({
  providedIn: 'root'
})
export class MapToolbarService {
  actionSignal = signal<{
    action: MapToolbarAction;
    payload?: MapToolbarPayload | undefined;
  } | null>(null);

  emitAction(action: MapToolbarAction, payload?: MapToolbarPayload) {
    this.actionSignal.set({ action, payload });
  }

  clearAction() {
    this.actionSignal.set(null);
  }
}
