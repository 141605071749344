import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { MapToolbarService } from './map-toolbar.service';
import { MapToolbarAction, MapToolbarPayload } from './map-toolbar-actions';
import { FilterLayer } from 'src/app/model/map.model';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'rqa-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, TooltipModule, ButtonModule, ToggleButtonComponent],
  animations: [
    trigger('slideInFromRight', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))])
    ])
  ]
})
export class MapToolbarComponent {
  @Input() filterLayerTypes: FilterLayer[];
  @Input() disabledAreas: string[];
  isLayerSubmenuVisible = false;
  private mapToolbarService = inject(MapToolbarService);

  onSearch(): void {
    this.emitAction('search');
  }

  onZoomOut(): void {
    this.emitAction('zoomOut');
  }

  onZoomIn(): void {
    this.emitAction('zoomIn');
  }

  onToggleLayerGroup(visible: boolean, groupId: string): void {
    this.emitAction('toggleLayerGroup', { visible, groupId });
  }

  private emitAction(action: MapToolbarAction, payload?: MapToolbarPayload): void {
    this.mapToolbarService.emitAction(action, payload);
  }
}
