import { NgFor, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { AirspaceElement, Info } from 'src/app/model/map.model';
import { RqaMapService } from '../rqa-map.service';

export const TYPES: string[] = ['CTR', 'TMA', 'MCTR', 'MTMA', 'SECTOR'];
@Component({
  selector: 'rqa-airspace-info',
  templateUrl: './airspace-info.component.html',
  styleUrls: ['./airspace-info.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, ButtonModule]
})
export class AirspaceInfoComponent {
  @Input() set airspaceInfo(value: AirspaceElement) {
    this.setHeaderInfo(value);
  }
  private rqaMapService = inject(RqaMapService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  designator: string;

  type: string;
  name: string | undefined;
  serviceAndFrequency: string[] = [];
  readonly TYPES = TYPES;

  copyDesignator(event: Event, designator: string): void {
    event.stopPropagation();
    this.rqaMapService.selectRowToCopy({ row: undefined, designator: designator });
    this.router.navigate(['/']);
    this.displayCopySuccess();
  }

  private setHeaderInfo(airspaceInfo: AirspaceElement): void {
    this.serviceAndFrequency = [];
    this.designator = airspaceInfo.designator;
    this.type = airspaceInfo.type;
    const servicesList: Info[] | undefined = airspaceInfo.info.filter((el) => el.header === 'Services');
    const frequenciesList: Info[] | undefined = airspaceInfo.info.filter((el) => el.header === 'Frequencies');
    this.name = airspaceInfo.info.find((el) => el.header === 'Name')?.content;

    servicesList.forEach((el, idx) => {
      this.serviceAndFrequency.push(`${el.content} - ${frequenciesList[idx].content}`);
    });
  }

  private displayCopySuccess(): void {
    this.messageService.add({
      severity: 'success',
      detail: this.translateService.instant('notifications.copyRowToTable'),
      life: 3000
    });
  }
}
