import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[rqaTimeControl]',
  standalone: true
})
export class TimeControlDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.isControlKey(event)) {
      return;
    }

    const input = event.target as HTMLInputElement;
    const value = input.value;
    const position = input.selectionStart || 0;
    const firstDigit = value.charAt(0);

    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
      return;
    }

    switch (position) {
      case 0:
        if (parseInt(event.key) > 2) {
          event.preventDefault();
        }
        break;

      case 1:
        if (firstDigit === '2' && parseInt(event.key) > 3) {
          event.preventDefault();
        }
        break;

      case 3:
        if (parseInt(event.key) > 5) {
          event.preventDefault();
        }
        break;

      case 4:
        break;

      default:
        event.preventDefault();
        break;
    }
  }

  private isControlKey(event: KeyboardEvent): boolean {
    return (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Tab' ||
      (event.ctrlKey && (event.key === 'c' || event.key === 'v' || event.key === 'x'))
    );
  }
}
