export function convertToDateTime(time: string): Date {
  const baseDate = '1970-01-01T06:00:00'; // Bazowy dzień startujący o 06:00
  const [hours, minutes] = time.split(':').map(Number);
  const date = new Date(baseDate);
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function isTimeInRange(recordStart: number, recordEnd: number, filterStart: number, filterEnd: number): boolean {
  if (!filterEnd && !filterStart) {
    return true;
  }
  if (filterStart <= filterEnd) {
    // Filtr nie przechodzi przez północ
    return recordStart >= filterStart && recordEnd <= filterEnd;
  } else {
    // Filtr przechodzi przez północ
    return (
      (recordStart >= filterStart || recordStart < filterEnd) && (recordEnd >= filterStart || recordEnd <= filterEnd)
    );
  }
}
