<div class="status-info" [ngClass]="statusName.toLowerCase()">
  @if (isMobileDevice()) {
    <span class="circle" (click)="showStatusText()"></span>
    @if (isStatusTextVisible()) {
      <span class="mobile-status-text">{{ 'observer.status.' + statusName.toLowerCase() | translate }}</span>
    }
  } @else {
    <span class="circle"></span>
  }
  <span class="name" *ngIf="!isMobileDevice()">{{ 'observer.status.' + statusName.toLowerCase() | translate }}</span>
</div>
