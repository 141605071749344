import { AfterViewInit, Component, effect, inject, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MapComponent } from 'src/app/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AupMapLayer, SelectedMapLayer, AupGeoJSONOptions, MapLayerTypeEnum } from 'src/app/model/map.model';
import { AccordionModule } from 'primeng/accordion';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';
import { AupMapService } from './aup-map.service';
import { MapToolbarComponent } from 'src/app/map/map-toolbar/map-toolbar.component';
import { AupReservation, AupReservationFilters, SelectedTableRow } from 'src/app/model/observer.model';
import { MapTimeComponent } from 'src/app/map/map-time/map-time.component';
import { MapHeightComponent } from 'src/app/map/map-height/map-height.component';
import { ObserverService } from 'src/app/services/observer.service';
import * as Turf from '@turf/turf';
import * as L from 'leaflet';
import { layersStyle } from 'src/app/map/layersStyle';
import { MapToolbarService } from 'src/app/map/map-toolbar/map-toolbar.service';
import { MapToolbarAction, MapToolbarPayload } from 'src/app/map/map-toolbar/map-toolbar-actions';
import { SelectedMapLayersComponent } from './selected-map-layers/selected-map-layers.component';
import { StyleType } from 'src/app/model/layers.model';
import { getAltitudeIndex } from 'src/app/model/altitudes.model';
import { MapHighlightService } from 'src/app/services/map-highlight.service';

@Component({
  selector: 'rqa-aup-map',
  templateUrl: './aup-map.component.html',
  styleUrls: ['./aup-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LeafletModule,
    AccordionModule,
    IconComponent,
    TooltipModule,
    ButtonModule,
    ToggleButtonComponent,
    MapToolbarComponent,
    AccordionModule,
    MapTimeComponent,
    MapHeightComponent,
    SelectedMapLayersComponent
  ]
})
export class AupMapComponent extends MapComponent implements AfterViewInit {
  private aupMapService = inject(AupMapService);
  private observerService = inject(ObserverService);
  private toolbarService = inject(MapToolbarService);
  private mapHighlightService = inject(MapHighlightService);
  readonly aupMapLayers: Signal<AupMapLayer[]> = this.aupMapService.mapLayers;
  readonly filters: Signal<AupReservationFilters> = this.observerService.filtersSignal;
  readonly toolbarAction: Signal<{ action: MapToolbarAction; payload?: MapToolbarPayload } | null> =
    this.toolbarService.actionSignal;
  readonly selectedRow: Signal<SelectedTableRow | null> = this.observerService.selectedRow;
  activeAccordionIndex: number = 0;
  timeRange: string[] = [];
  heightRange: string[] = [];
  layerStyles = layersStyle;
  lastClickedLayer: L.GeoJSON | null = null;
  layersContainingPoint: SelectedMapLayer[] = [];
  fetchLayersCount = 0;
  disabledFilters = false;

  constructor() {
    super();

    effect(() => {
      if (this.aupMapLayers().length) {
        this.createAupLayers(this.aupMapLayers());
        this.fetchLayersCount++;
      } else {
        this.clearAllLayers();
      }

      if (this.filters()) {
        this.timeRange = [this.filters().from, this.filters().until];
        this.heightRange = [this.filters().flMin, this.filters().flMax];
      }
      if (this.selectedRow()) {
        const selectedRow = this.selectedRow();
        if (selectedRow) {
          this.layersContainingPoint = [];
          this.higlightSelectedLayer(selectedRow.rowData.designator);
          if (selectedRow.isVisualizationChecked) {
            this.timeRange = [selectedRow.rowData.from, selectedRow.rowData.until];
            this.heightRange = [selectedRow.rowData.flMin, selectedRow.rowData.flMax];
            this.showFilters = true;
            this.disabledFilters = true;
          } else {
            this.timeRange = [this.filters().from, this.filters().until];
            this.heightRange = [this.filters().flMin, this.filters().flMax];
            this.disabledFilters = false;
          }
        }
      } else {
        this.disabledFilters = false;
      }
    });

    effect(() => {
      if (this.toolbarAction()) {
        this.handleToolbarAction(this.toolbarAction());
      }
    });

    effect(() => {
      const designator = this.mapHighlightService.highlightedDesignator();
      this.bringToFrontLayerAndHighlight(designator);
    });
  }

  ngAfterViewInit(): void {
    this.toolbarService.clearAction();
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.findIntersectingLayers(e.latlng);
  }

  onTimeRangeChange(timeRange: string[]): void {
    const filters = {
      ...this.filters(),
      from: timeRange[0],
      until: timeRange[1]
    };
    this.observerService.updateFilters(filters);
  }

  onHeightRangeChange(heightRange: string[]): void {
    const filters = {
      ...this.filters(),
      flMin: heightRange[0],
      flMax: heightRange[1]
    };
    this.observerService.updateFilters(filters);
  }

  createAupLayers(layers: AupMapLayer[]): void {
    this.layersContainingPoint = [];
    this.groupedLayers = {};
    this.clearAllLayers();
    layers.forEach((layerData) => {
      const {
        designator,
        type,
        geometry,
        reservations,
        flMin,
        flMax,
        displayFlMax,
        displayFlMin,
        styles,
        multipleAltitude
      } = layerData;
      if (!this.groupedLayers[type]) {
        this.groupedLayers[type] = L.layerGroup().addTo(this.map);
      }
      const resultGeometry = this.convertGeometry(geometry);
      const layer = new L.GeoJSON(
        resultGeometry as Turf.GeometryObject,
        {
          designator,
          reservations,
          type,
          flMin,
          flMax,
          displayFlMax,
          displayFlMin,
          styles,
          multipleAltitude,
          className: designator
        } as AupGeoJSONOptions
      );

      if (layer) {
        this.groupedLayers[type].addLayer(layer);
        this.setLayerStyles(layer, styles, StyleType.ACTIVATED);
        layer.on('click', () => this.onLayerClick(layer));
      }
    });

    if (this.fetchLayersCount === 0) {
      this.filterLayerTypes = Object.keys(this.groupedLayers).map((type) => {
        return {
          type: type,
          visible: type === MapLayerTypeEnum.AUP || type === MapLayerTypeEnum.UUP,
          checked: type === MapLayerTypeEnum.AUP || type === MapLayerTypeEnum.UUP
        };
      });
    }

    this.filterLayerTypes.map((el) => {
      this.toggleLayerGroupVisibility(el.checked, el.type);
    });
  }

  onLayerClick(layer: L.GeoJSON): void {
    this.map.fitBounds(layer.getBounds(), { maxZoom: 8 });
    this.resetLayerStyles();

    const styles = (layer.options as AupGeoJSONOptions).styles;
    this.setLayerStyles(layer, styles, StyleType.HIGHLIGHT);
    layer.bringToFront();
  }

  private handleToolbarAction(event: { action: MapToolbarAction; payload?: MapToolbarPayload } | null): void {
    if (event) {
      this.layersContainingPoint = [];
      switch (event.action) {
        case 'zoomIn':
          this.zoomIn();
          break;
        case 'zoomOut':
          this.zoomOut();
          break;
        case 'search':
          this.onSearch();
          break;
        case 'toggleLayerGroup':
          if (event.payload) {
            this.toggleLayerGroupVisibility(event.payload.visible, event.payload.groupId);
            this.bringToFrontLayers([MapLayerTypeEnum.AUP, MapLayerTypeEnum.UUP]);
          }
          break;
        case 'filters':
          this.toogleFilters();
          this.moveCoordinatesDiv(this.showFilters);
      }
    }
  }

  private findIntersectingLayers(latlng: L.LatLng): void {
    this.layersContainingPoint = [];
    this.map.eachLayer((layer) => {
      if (this.map.hasLayer(layer)) {
        if (this.isPointInLayer(latlng, layer as L.GeoJSON)) {
          const selectedLayer = this.mapSelectedLayer(layer as L.GeoJSON);
          this.layersContainingPoint.push(selectedLayer);
        }
      }
    });
    const convertedDoubleDesignators = this.layersContainingPoint
      .sort((a, b) => {
        return a.designator.localeCompare(b.designator);
      })
      .reduce<{ [key: string]: SelectedMapLayer }>((acc, item) => {
        const { designator, children, showChildren, flMinMax, multipleAltitude, type } = item;
        if (!acc[designator]) {
          acc[designator] = {
            designator,
            type,
            children: [],
            showChildren,
            flMinMax,
            multipleAltitude
          };
        }
        acc[designator].children.push(...children);
        return acc;
      }, {});

    this.layersContainingPoint = Object.values(convertedDoubleDesignators);
    if (!this.layersContainingPoint.length) {
      this.addMarker(latlng);
    } else {
      if (this.currentMarker) {
        this.map.removeLayer(this.currentMarker);
      }
    }
  }

  private mapSelectedLayer(layer: L.GeoJSON): SelectedMapLayer {
    const layerOptions = layer.options as AupGeoJSONOptions;
    return {
      designator: `${layerOptions.designator}`,
      type: `${layerOptions.type}`,
      flMinMax: `${layerOptions.displayFlMin}-${layerOptions.displayFlMax}`,
      children: layerOptions?.reservations.length ? this.mapReservationsToChildren(layerOptions.reservations) : [],
      multipleAltitude: layerOptions.multipleAltitude,
      showChildren: false
    };
  }

  private mapReservationsToChildren(reservations: AupReservation[]): string[] {
    return reservations
      .sort((a, b) => {
        if (a.responsibleUnit !== b.responsibleUnit) {
          return a.responsibleUnit.localeCompare(b.responsibleUnit);
        }
        if (a.from !== b.from) {
          return a.from.localeCompare(b.from);
        }
        return getAltitudeIndex(a.flMin) - getAltitudeIndex(b.flMin);
      })
      .map((res) => `${res.flMin}-${res.flMax} ${res.from}- ${res.until} ${res.responsibleUnit}`);
  }

  private higlightSelectedLayer(designator: string | null): void {
    if (designator) {
      this.resetLayerStyles();
      Object.keys(this.groupedLayers).forEach((key) => {
        this.groupedLayers[key]?.eachLayer((layer) => {
          const options = layer.options as AupGeoJSONOptions;
          if (options.designator === designator) {
            (layer as L.GeoJSON).bringToFront();
          } else {
            const styles = (layer.options as AupGeoJSONOptions).styles;
            this.setLayerStyles(layer as L.GeoJSON, styles, StyleType.TRANSPARENT);
          }
        });
      });
    }
  }

  private bringToFrontLayerAndHighlight(designator: string | null): void {
    Object.keys(this.groupedLayers).forEach((key) => {
      this.groupedLayers[key]?.eachLayer((layer) => {
        const options = layer.options as AupGeoJSONOptions;
        if (options.designator === designator) {
          (layer as L.GeoJSON).bringToFront();
          const styles = (layer.options as AupGeoJSONOptions).styles;
          this.setLayerStyles(layer as L.GeoJSON, styles, StyleType.HIGHLIGHT);
        } else {
          const styles = (layer.options as AupGeoJSONOptions).styles;
          this.setLayerStyles(layer as L.GeoJSON, styles, StyleType.ACTIVATED);
        }
      });
    });
  }
}
