import { Injectable, computed, signal, WritableSignal, Signal, inject } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { AupDetails, AupReservation, AupReservationFilters } from '../model/observer.model';
import { Altitudes } from '../model/altitudes.model';
import { ObserverService } from './observer.service';
import { AupMapService } from '../pages/observer/aup-details/aup-map/aup-map.service';
import { convertToDateTime, isTimeInRange } from '../shared/utils/time.utils';

@Injectable({
  providedIn: 'root'
})
export class AupService {
  private observerService = inject(ObserverService);
  private aupMapService = inject(AupMapService);
  aupDetails: WritableSignal<AupDetails> = this.observerService.aupDetails;
  filtersSignal: WritableSignal<AupReservationFilters> = signal({
    designator: [],
    responsibleUnit: [],
    flMin: '',
    flMax: '',
    from: '',
    until: ''
  });

  sortSignal: WritableSignal<SortEvent | null> = signal(null);
  filteredReservation: Signal<AupReservation[]> = computed(() => {
    const filters = this.filtersSignal();
    const sort = this.sortSignal();
    return this.filterAndSortReservation(this.aupDetails().reservationShortDTOS, filters, sort);
  });

  filterAndSortReservation(
    reservations: AupReservation[],
    filters: AupReservationFilters,
    sort: SortEvent | null
  ): AupReservation[] {
    let filtered = reservations.filter((item) => {
      const matchesDesignator =
        filters.designator && filters.designator.length ? filters.designator.includes(item.designator) : true;
      const matchesUnit =
        filters.responsibleUnit && filters.responsibleUnit.length
          ? filters.responsibleUnit.includes(item.responsibleUnit)
          : true;

      const recordFrom = convertToDateTime(item.from)?.getTime();
      const recordUntil = convertToDateTime(item.until)?.getTime();
      const filterFrom = filters.from ? convertToDateTime(filters.from.replace(/_/g, '0'))?.getTime() : 0;
      const filterUntil = filters.until ? convertToDateTime(filters.until.replace(/_/g, '0'))?.getTime() : 0;
      const matchesFromUntil = isTimeInRange(recordFrom, recordUntil, filterFrom, filterUntil);

      const flMinFilter = filters.flMin ? Altitudes[filters.flMin as keyof typeof Altitudes] : null;
      const flMaxFilter = filters.flMax ? Altitudes[filters.flMax as keyof typeof Altitudes] : null;

      const itemFlMin = Altitudes[item.flMin as keyof typeof Altitudes];
      const itemFlMax = Altitudes[item.flMax as keyof typeof Altitudes];

      const matchesFLMin = flMinFilter !== null ? itemFlMin >= flMinFilter : true;
      const matchesFLMax = flMaxFilter !== null ? itemFlMax <= flMaxFilter : true;

      return matchesDesignator && matchesUnit && matchesFromUntil && matchesFLMin && matchesFLMax;
    });

    // Sortowanie
    if (sort && sort.field) {
      filtered = this.sortReservations(filtered, sort.field as keyof AupReservation, sort.order);
    }

    return filtered;
  }

  // Sortowanie
  sortReservations(
    reservations: AupReservation[],
    column: keyof AupReservation,
    direction: number | undefined
  ): AupReservation[] {
    return reservations.sort((a, b) => {
      let valueA: any; // eslint-disable-line
      let valueB: any; // eslint-disable-line

      switch (column) {
        case 'flMin':
          valueA = Altitudes[a.flMin as keyof typeof Altitudes];
          valueB = Altitudes[b.flMin as keyof typeof Altitudes];
          break;
        case 'flMax':
          valueA = Altitudes[a.flMax as keyof typeof Altitudes];
          valueB = Altitudes[b.flMax as keyof typeof Altitudes];
          break;

        default:
          valueA = (a as any)[column]; // eslint-disable-line
          valueB = (b as any)[column]; // eslint-disable-line
      }

      const comparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      return direction === 1 ? comparison : -comparison;
    });
  }

  updateFilters(filters: AupReservationFilters | null) {
    if (filters) {
      this.filtersSignal.set(filters);
    }

    this.updateAirspaceLayers();
  }

  updateSort(sort: SortEvent | null) {
    this.sortSignal.set(sort);
    this.updateAirspaceLayers();
  }

  private updateAirspaceLayers(): void {
    const reservations = this.filteredReservation();
    this.aupMapService.updateMapLayers(
      reservations.map((item) => item.designator),
      this.aupDetails().name
    );
  }
}
