import { ChangeDetectionStrategy, Component, OnInit, Signal, ViewChild, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObserverService } from 'src/app/services/observer.service';
import { Table, TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AupDetails, AupReservation, AupReservationFiltersFormGroup } from 'src/app/model/observer.model';
import { ActivatedRoute } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { altitudeArray } from 'src/app/model/altitudes.model';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { SortEvent } from 'primeng/api';
import { AupService } from 'src/app/services/aup.service';
import { StatusInfoComponent } from 'src/app/ui/status-info/status-info.component';
import { tap } from 'rxjs';
import { MapService } from 'src/app/map/map.service';

@Component({
  selector: 'rqa-aup-reservations',
  templateUrl: './aup-reservations.component.html',
  styleUrls: ['./aup-reservations.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FloatLabelModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputMaskModule,
    StatusInfoComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AupReservationsComponent implements OnInit {
  @ViewChild('dt') pTable!: Table;
  private route = inject(ActivatedRoute);
  private observerService = inject(ObserverService);
  private aupService = inject(AupService);
  private fb = inject(FormBuilder);
  private _location = inject(Location);
  private mapService = inject(MapService);

  filtersForm: AupReservationFiltersFormGroup = this.fb.group({
    designator: this.fb.control<string[]>([]),
    responsibleUnit: this.fb.control<string[]>([]),
    from: this.fb.control<string>(''),
    until: this.fb.control<string>(''),
    flMin: this.fb.control<string | null>(null),
    flMax: this.fb.control<string | null>(null)
  }) as AupReservationFiltersFormGroup;

  aupDetails: Signal<AupDetails> = this.aupService.aupDetails;
  aupDate: string | null = this.route.snapshot.paramMap.get('date');
  designatorOptions: Signal<string[]> = this.observerService.designatorOptions;
  unitOptions: Signal<string[]> = this.observerService.unitOptions;
  altitudes: string[] = altitudeArray;
  filteredAltitudes: string[] = [];
  designatorPlaceholder: string = 'Designator';
  isSorted: boolean | null = null;
  reservations: Signal<AupReservation[]> = this.aupService.filteredReservation;

  ngOnInit(): void {
    this.fetchAupDetails();
    this.onFiltersFormChange();
  }

  goBack(): void {
    this._location.back();
  }

  searchAltitude(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
  }

  clearFilters(): void {
    this.filtersForm.reset();
  }

  clearSort(): void {
    this.aupService.updateSort(null);
    this.pTable.reset();
  }

  customSort(event: SortEvent) {
    const currentSort = this.aupService.sortSignal();
    if (!currentSort || currentSort.field !== event.field || currentSort.order !== event.order) {
      setTimeout(() => {
        this.aupService.updateSort(event);
      }, 100);
    }
  }

  private fetchAupDetails(): void {
    this.observerService
      .fetchAupDetails([
        {
          localDate: this.aupDate || ''
        }
      ])
      .pipe(tap(() => this.aupService.updateFilters(null)))
      .subscribe();
  }

  private onFiltersFormChange(): void {
    this.filtersForm.valueChanges.subscribe(() => {
      this.aupService.updateFilters(this.filtersForm.getRawValue());
    });
  }
}
