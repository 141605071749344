<div *ngIf="isMobile; else desktopView" class="mobile-view w-full">
  <div class="view-switch flex justify-content-center mb-0 w-full" [ngClass]="{ top: !visibleMap, bottom: visibleMap }">
    <button
      pButton
      [icon]="visibleMap ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
      (click)="onVisibleMap()"
      class="w-full justify-content-center"
    >
      @if (visibleMap) {
        {{ 'map.hideMap' | translate }}
      } @else {
        {{ 'map.showMap' | translate }}
      }
    </button>
  </div>
  <div [ngStyle]="{ display: !visibleMap ? 'block' : 'none' }">
    <ng-container *ngComponentOutlet="leftComponent"></ng-container>
  </div>
  <div [ngStyle]="{ display: visibleMap ? 'block' : 'none' }" class="w-full">
    <ng-container *ngComponentOutlet="rightComponent"></ng-container>
  </div>
</div>
<ng-template #desktopView>
  <p-splitter [panelSizes]="[55, 45]" (onResizeEnd)="onResizeSplitter($event)">
    <ng-template pTemplate>
      <ng-container *ngComponentOutlet="leftComponent"></ng-container>
    </ng-template>
    <ng-template pTemplate>
      <ng-container *ngComponentOutlet="rightComponent"></ng-container>
    </ng-template>
  </p-splitter>
</ng-template>
