import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReservationDTO, ReservationError } from 'src/app/model/reservation.model';
import * as moment from 'moment';
import { DATE_TIME_FORMAT } from '../shared/constants/date.constants';

@Injectable({
  providedIn: 'root'
})
export class RqaRowValidatorService {
  translateService = inject(TranslateService);

  dateValidation = (startDate: Date, endDate: Date, minDate: Date, maxDate: Date): ReservationError[] => {
    const formattedMinDate = moment(minDate).format(DATE_TIME_FORMAT);
    const formattedMaxDate = moment(maxDate).format(DATE_TIME_FORMAT);
    const messages = [];
    // startDate isBefore endDate
    if (moment(startDate).isAfter(moment(endDate))) {
      messages.push({
        field: 'startDate',
        message: this.translateService.instant('form.errors.dateStartEndError')
      });
    }

    // startDate isBefore minDate
    if (moment(startDate).isBefore(moment(minDate))) {
      messages.push({
        field: 'startDate',
        message: this.translateService.instant('form.errors.startDateMinError', { date: formattedMinDate })
      });
    }

    // startDate isAfter maxDate
    if (moment(startDate).isAfter(moment(maxDate))) {
      messages.push({
        field: 'startDate',
        message: this.translateService.instant('form.errors.startDateMinError', { date: formattedMaxDate })
      });
    }

    // endDate isBefore minDate
    if (moment(endDate).isBefore(moment(minDate))) {
      messages.push({
        field: 'endDate',
        message: this.translateService.instant('form.errors.endDateMinError', { date: formattedMinDate })
      });
    }

    // endDate isAfter maxDate
    if (moment(endDate).isAfter(moment(maxDate))) {
      messages.push({
        field: 'endDate',
        message: this.translateService.instant('form.errors.endDateMaxError', { date: formattedMaxDate })
      });
    }

    return messages;
  };

  checkRequiredFields = (
    reservation: ReservationDTO,
    isRequiredActivityType?: boolean,
    isRequiredAltitudes?: boolean
  ): ReservationError[] => {
    const result = [];
    const requiredFields = ['designator', 'startDate', 'endDate'];
    if (isRequiredActivityType) {
      requiredFields.push('activityType');
    }
    if (isRequiredAltitudes) {
      requiredFields.push('lowerAltitude');
      requiredFields.push('upperAltitude');
    }
    for (const [key, value] of Object.entries(reservation)) {
      if (requiredFields.includes(key) && (value === null || value === undefined || value === '')) {
        result.push({
          field: key,
          message: this.translateService.instant('form.errors.required')
        });
      }
    }
    return result;
  };
}
