import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AirspaceElement, MapLayersType } from '../model/map.model';
import { MapService } from '../services/map.service';
import { AupService } from '../services/aup.service';

@Injectable({
  providedIn: 'root'
})
export class MapResolver implements Resolve<AirspaceElement[]> {
  constructor(
    private mapService: MapService,
    private aupService: AupService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AirspaceElement[]> {
    const layersType: MapLayersType = state.url.includes('observer') ? 'AUP' : 'RQA';
    this.aupService.updateFilters(null);
    this.aupService.updateSort(null);
    return this.mapService.fetchMapLayers(layersType);
  }
}
