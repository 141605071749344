<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes"></rqa-map-toolbar>
  <div class="airspace-info">
    <p-accordion
      (activeIndexChange)="onToggleAccordion($event)"
      [styleClass]="'box-accordion'"
      [activeIndex]="activeAccordionIndex"
    >
      <p-accordionTab
        [ngClass]="{ 'region-disabled': layer.type === 'SECTOR' }"
        *ngFor="let layer of selectedLayers; let i = index; trackBy: trackByFn"
        [id]="layer.id"
      >
        <ng-container *ngIf="layer.type !== 'SECTOR'; else sector">
          <ng-template pTemplate="header">
            <rqa-airspace-info [airspaceInfo]="layer"></rqa-airspace-info>
          </ng-template>
          <rqa-airspace-table
            [type]="layer.type"
            [availabilities]="layer.availabilities"
            [designator]="layer.designator"
          ></rqa-airspace-table>
        </ng-container>
        <ng-template #sector>
          <ng-template pTemplate="header">
            <rqa-airspace-info [airspaceInfo]="layer"></rqa-airspace-info>
          </ng-template>
        </ng-template>
      </p-accordionTab>
    </p-accordion>
  </div>
  <div
    id="map"
    [style.height.px]="contentHeight()"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
  @if (showFilters) {
    <div class="height-container">
      <rqa-map-height
        [filterHeightRange]="heightRange"
        (heightRangeChange)="onHeightRangeChange($event)"
      ></rqa-map-height>
    </div>
  }
</div>
