<div class="list-container">
  <ul class="parent-list">
    <li
      *ngFor="let item of layers"
      class="parent-item"
      (mouseenter)="onMouseEnter(item)"
      (mouseleave)="onMouseLeave(item)"
    >
      <div class="parent-item-content">
        <div class="flex w-full align-items-center">
          <div class="mr-1" *ngIf="multipleAltitude">
            <i class="pi pi-bars"></i>
          </div>
          <div>{{ item.designator }}</div>

          <div class="ml-1 flMinMax" *ngIf="item.type !== 'SECTOR'">({{ item.flMinMax }})</div>
        </div>
        <i class="pi pi-angle-right" *ngIf="item.children.length"></i>
      </div>
      <ul class="child-list" *ngIf="item.showChildren">
        <li *ngFor="let child of item.children">
          {{ child }}
        </li>
      </ul>
    </li>
  </ul>
</div>
