import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'primeng/chart';
import { AupReservation, ChartBoxOverlap, ChartLegendItem } from 'src/app/model/observer.model';
import { Chart, registerables } from 'chart.js';
import ChartAnnotation, { EventContext } from 'chartjs-plugin-annotation';
import {
  formatTimestampToStringTime,
  normalizeFromAupTime,
  normalizeUntilAupTime
} from 'src/app/shared/utils/time.utils';
import { ButtonModule } from 'primeng/button';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { BadgeModule } from 'primeng/badge';
import * as moment from 'moment';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { altitudeArrayObserver, getAltitudeIndex } from 'src/app/model/altitudes.model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-designator-graph',
  templateUrl: './designator-graph.component.html',
  styleUrls: ['./designator-graph.component.scss'],
  standalone: true,
  imports: [CommonModule, ChartModule, ButtonModule, BadgeModule, OverlayPanelModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignatorGraphComponent implements OnChanges {
  @Input() data: AupReservation[];
  chart: Chart | null = null;
  legendData: { [key: number]: ChartLegendItem } = {};
  currentAnnotation: string | null = null;
  selectedAnnotations: Record<string, boolean> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  boxAnnotations: Record<string, any> = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.updateChart();
    }
  }

  drawChart() {
    Chart.register(...registerables, ChartAnnotation);
    const canvas = document.getElementById('chartCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              min: this.getMinFromValue(),
              max: this.getMaxUntilValue(),
              afterBuildTicks: (scale) => {
                const tickValues: number[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(this.boxAnnotations).forEach((box: any) => {
                  tickValues.push(box.xMin, box.xMax);
                });
                const uniqueTicks = Array.from(new Set(tickValues)).sort((a, b) => a - b);
                scale.ticks = uniqueTicks.map((value) => ({ value }));
              },
              ticks: {
                callback: (value) => {
                  const date = new Date(value);
                  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
                },
                font: {
                  size: 11
                }
              }
            },
            y: {
              min: 0,
              max: this.getMaxFlMaxValue(),
              afterBuildTicks: (scale) => {
                const tickValues: number[] = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                Object.values(this.boxAnnotations).forEach((box: any) => {
                  tickValues.push(box.yMin, box.yMax);
                });
                const uniqueTicks = Array.from(new Set(tickValues)).sort((a, b) => a - b);
                scale.ticks = uniqueTicks.map((value) => ({ value }));
              },
              ticks: {
                callback: (value) => {
                  return value;
                },
                font: {
                  size: 11
                }
              }
            }
          },
          plugins: {
            annotation: {
              enter: (context: EventContext, e: ChartEvent) => {
                this.showTooltip(context, e);
              },
              leave: () => {
                this.hideTooltip();
              },
              annotations: this.generateBoxAnnotations()
            }
          }
        }
      });
    }
  }

  updateChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.drawChart();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showTooltip(context: any, event: any) {
    const idx = context.element.options.id;
    const reservation = this.data[idx];
    const tooltipElement = document.getElementById('tooltip');
    if (tooltipElement) {
      tooltipElement.style.display = 'block';
      tooltipElement.style.left = `${event.x - 30}px`;
      tooltipElement.style.top = `${event.y + 10}px`;
      tooltipElement.innerHTML = `${reservation.flMin} - ${reservation.flMax} <br>${reservation.from} - ${reservation.until}`;
    }
  }

  hideTooltip() {
    const tooltipElement = document.getElementById('tooltip');
    if (tooltipElement) {
      tooltipElement.style.display = 'none';
    }
  }

  getLegendKeys(): string[] {
    return Object.keys(this.legendData);
  }

  onCheckboxChange(key: number, event: Event) {
    if (!this.chart) return;
    const checked = (event.target as HTMLInputElement).checked;
    this.selectedAnnotations[key] = checked;

    const annotations = this.chart.options.plugins?.annotation?.annotations as Record<string, any>;
    if (annotations && annotations[key]) {
      annotations[key].display = checked;
      if (checked) {
        let maxZ = 0;
        Object.keys(annotations).forEach((k) => {
          if (annotations[k].z !== undefined && annotations[k].z > maxZ) {
            maxZ = annotations[k].z;
          }
        });
        annotations[key].z = maxZ + 1;
      }
    }
    this.chart.update();
  }

  isSelected(key: string): boolean {
    return this.currentAnnotation === key;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private generateBoxAnnotations(): any {
    this.legendData = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const annotations: { [key: string]: any } = {};

    this.data.forEach((reservation, idx) => {
      const key: number = idx;
      this.legendData[key] = {
        label: reservation.responsibleUnit,
        color: this.generateRandomRgbaColorByIndex(idx),
        borderColor: this.generateRandomRgbaColorByIndex(idx, true),
        overlap: this.getOverlappingDetails(idx)
      } as ChartLegendItem;

      this.selectedAnnotations[key] = true;
      const labelPosition = this.calculateLabelPosition(reservation, idx);

      annotations[key] = {
        type: 'box',
        xMin: normalizeFromAupTime(reservation.from),
        xMax: normalizeUntilAupTime(reservation.until),
        yMin: this.convertStringToNumber(reservation.flMin),
        yMax: this.convertStringToNumber(reservation.flMax),
        backgroundColor: this.generateRandomRgbaColorByIndex(idx),
        borderColor: this.generateRandomRgbaColorByIndex(idx, true),
        borderWidth: 3,
        id: key,
        label: {
          display: true,
          drawTime: 'afterDraw',
          content: `${reservation.responsibleUnit}`,
          enabled: true,
          color: this.generateRandomRgbaColorByIndex(idx, true),
          clip: false,
          font: { weight: 'bolder' },
          position: {
            x: labelPosition.x,
            y: labelPosition.y
          },
          xAdjust: labelPosition.xAdjust,
          yAdjust: labelPosition.yAdjust,
          padding: 4
        }
      };
    });
    this.boxAnnotations = annotations;
    return annotations;
  }

  private getMaxFlMaxValue(): number {
    if (!this.data.length) {
      return 500;
    }

    let highestFlMax = this.convertStringToNumber(this.data[0].flMax);
    for (const item of this.data) {
      if (this.convertStringToNumber(item.flMax) > highestFlMax) {
        highestFlMax = this.convertStringToNumber(item.flMax);
      }
    }
    return highestFlMax;
  }

  private getMaxUntilValue(): number {
    if (!this.data.length) {
      return normalizeUntilAupTime('06:00');
    }

    let highestUntil = normalizeUntilAupTime(this.data[0].until);
    for (const item of this.data) {
      const until = normalizeUntilAupTime(item.until);
      if (until > highestUntil) {
        highestUntil = until;
      }
    }

    return highestUntil;
  }

  private getMinFromValue(): number {
    if (!this.data.length) {
      return normalizeUntilAupTime('06:00');
    }

    let lowestFrom = normalizeFromAupTime(this.data[0].from);
    for (const item of this.data) {
      const from = normalizeFromAupTime(item.from);
      if (from < lowestFrom) {
        lowestFrom = from;
      }
    }

    if (moment(lowestFrom).hour() <= 7) {
      return normalizeFromAupTime('06:00');
    }
    const substractHour = moment(lowestFrom).subtract(1, 'hours').valueOf();
    return substractHour;
  }

  private convertStringToNumber(value: string): number {
    if (value === 'GND' || value === 'SFC') {
      return 0;
    }

    const numericPart = value.substring(1);
    return parseInt(numericPart, 10);
  }

  private getOverlappingDetails(currentIndex: number): ChartBoxOverlap[] {
    const details: { label: string; overlapTime: string; overlapHeight: string }[] = [];
    const currentReservation = this.data[currentIndex];

    const xMinCurrent = normalizeFromAupTime(currentReservation.from);
    const xMaxCurrent = normalizeUntilAupTime(currentReservation.until);
    const yMinCurrent = getAltitudeIndex(currentReservation.flMin);
    const yMaxCurrent = getAltitudeIndex(currentReservation.flMax);

    this.data.forEach((otherReservation, index) => {
      if (index === currentIndex) return;

      const xMinOther = normalizeFromAupTime(otherReservation.from);
      const xMaxOther = normalizeUntilAupTime(otherReservation.until);
      const yMinOther = getAltitudeIndex(otherReservation.flMin);
      const yMaxOther = getAltitudeIndex(otherReservation.flMax);

      const timeOverlap = xMinCurrent < xMaxOther && xMaxCurrent > xMinOther;
      const heightOverlap = yMinCurrent < yMaxOther && yMaxCurrent > yMinOther;

      if (timeOverlap && heightOverlap) {
        const overlapStartTime = Math.max(xMinCurrent, xMinOther);
        const overlapEndTime = Math.min(xMaxCurrent, xMaxOther);
        const formattedOverlapTime = `${formatTimestampToStringTime(overlapStartTime)}-${formatTimestampToStringTime(overlapEndTime)}`;

        const overlapStartHeight = Math.max(yMinCurrent, yMinOther);
        const overlapEndHeight = Math.min(yMaxCurrent, yMaxOther);
        const formattedOverlapHeight = `${altitudeArrayObserver[overlapStartHeight]}-${altitudeArrayObserver[overlapEndHeight]}`;

        details.push({
          label: otherReservation.responsibleUnit,
          overlapTime: formattedOverlapTime,
          overlapHeight: formattedOverlapHeight
        });
      }
    });

    return details;
  }

  private calculateLabelPosition(
    reservation: AupReservation,
    index: number
  ): { x: string; y: string; xAdjust?: number; yAdjust?: number } {
    const boxWidth = normalizeUntilAupTime(reservation.until) - normalizeFromAupTime(reservation.from);
    const boxHeight = this.convertStringToNumber(reservation.flMax) - this.convertStringToNumber(reservation.flMin);
    const maxTime = this.getMaxUntilValue();
    const endTime = normalizeUntilAupTime(reservation.until);
    const startTime = normalizeFromAupTime(reservation.from);
    const MARGIN = 10;
    const LABEL_WIDTH = reservation.responsibleUnit.length * 8; // Przybliżona szerokość etykiety

    const isNearEndOfX = endTime > maxTime - 1800000;
    const isNearStartOfX = startTime < this.getMinFromValue() + 1800000;
    const isNarrow = boxWidth < 1800000;

    if (isNarrow && isNearEndOfX) {
      return {
        x: 'start',
        y: 'center',
        xAdjust: -LABEL_WIDTH - MARGIN,
        yAdjust: 0
      };
    }

    if (isNarrow && isNearStartOfX) {
      return {
        x: 'start',
        y: 'center',
        xAdjust: MARGIN,
        yAdjust: 0
      };
    }

    if (boxWidth > 3600000 && boxHeight > 50) {
      return {
        x: 'center',
        y: 'center',
        xAdjust: 0,
        yAdjust: 0
      };
    }

    const usedPositions = new Set<string>();
    this.data.slice(0, index).forEach((prevReservation) => {
      const prevFrom = normalizeFromAupTime(prevReservation.from);
      const prevUntil = normalizeUntilAupTime(prevReservation.until);
      const prevFlMin = this.convertStringToNumber(prevReservation.flMin);
      const prevFlMax = this.convertStringToNumber(prevReservation.flMax);

      if (
        normalizeFromAupTime(reservation.from) < prevUntil &&
        normalizeUntilAupTime(reservation.until) > prevFrom &&
        this.convertStringToNumber(reservation.flMin) < prevFlMax &&
        this.convertStringToNumber(reservation.flMax) > prevFlMin
      ) {
        usedPositions.add(`${prevFrom}-${prevFlMin}`);
      }
    });

    const positions = [
      { x: 'center', y: 'center', xAdjust: 0, yAdjust: 0 },
      { x: 'start', y: 'center', xAdjust: -LABEL_WIDTH - MARGIN, yAdjust: 0 },
      { x: 'end', y: 'center', xAdjust: MARGIN, yAdjust: 0 },
      { x: 'center', y: 'start', xAdjust: 0, yAdjust: -MARGIN },
      { x: 'center', y: 'end', xAdjust: 0, yAdjust: MARGIN },
      { x: 'start', y: 'start', xAdjust: -LABEL_WIDTH - MARGIN, yAdjust: -MARGIN },
      { x: 'end', y: 'start', xAdjust: MARGIN, yAdjust: -MARGIN },
      { x: 'start', y: 'end', xAdjust: -LABEL_WIDTH - MARGIN, yAdjust: MARGIN },
      { x: 'end', y: 'end', xAdjust: MARGIN, yAdjust: MARGIN }
    ];

    for (const pos of positions) {
      const posKey = `${startTime}-${this.convertStringToNumber(reservation.flMin)}-${pos.x}-${pos.y}`;
      if (!usedPositions.has(posKey)) {
        return pos;
      }
    }

    if (isNearEndOfX) {
      return {
        x: 'start',
        y: 'center',
        xAdjust: -LABEL_WIDTH - MARGIN,
        yAdjust: 0
      };
    }

    return {
      x: 'center',
      y: 'center',
      xAdjust: 0,
      yAdjust: 0
    };
  }

  private generateRandomRgbaColorByIndex(index: number, withoutTransparent?: boolean): string {
    switch (index) {
      case 0:
        return withoutTransparent ? 'rgba(240, 80, 100, 1)' : 'rgba(240, 80, 100, 0.05)';
      case 1:
        return withoutTransparent ? 'rgb(0, 128, 226)' : 'rgba(80, 170, 240, 0.05)';
      case 2:
        return withoutTransparent ? 'rgb(0, 143, 0)' : 'rgba(80, 230, 80, 0.05)';
      case 3:
        return withoutTransparent ? 'rgb(80, 57, 38)' : 'rgba(120, 240, 60, 0.05)';
      case 4:
        return withoutTransparent ? 'rgba(100, 50, 190, 1)' : 'rgba(100, 50, 190, 0.05)';
      case 5:
        return withoutTransparent ? 'rgba(240, 160, 20, 1)' : 'rgba(240, 160, 20, 0.05)';
      case 6:
        return withoutTransparent ? 'rgb(27, 94, 94)' : 'rgba(70, 230, 230, 0.05)';
      case 7:
        return withoutTransparent ? 'rgb(245, 55, 245)' : 'rgba(240, 80, 240, 0.05)';
      case 8:
        return withoutTransparent ? 'rgb(102, 109, 4)' : 'rgba(240, 140, 90, 0.05)';
      default:
        return withoutTransparent ? 'rgba(240, 140, 90, 1)' : 'rgba(240, 140, 90, 0.05)';
    }
  }
}
