export interface Style {
  color: string;
  weight: number;
  opacity: number;
  fillColor?: string;
  fillOpacity: number;
  dashArray?: string;
}

export interface Styles {
  activated: Style;
  highlight: Style;
}

export interface Layer {
  styles: Styles;
}

export interface LayersStyle {
  [key: string]: Style;
}

export enum StyleType {
  ACTIVATED = 'activated',
  HIGHLIGHT = 'highlight',
  TRANSPARENT = 'transparent'
}

export enum LayerTypes {
  TSA = 'TSA',
  TRA = 'TRA',
  R = 'R',
  D = 'D',
  TFR = 'TFR',
  ADHOC = 'ADHOC',
  NPZ = 'NPZ',
  RAD_RS = 'RAD_RS',
  MRT = 'MRT',
  RQA = 'RQA',
  AUP = 'AUP'
}
