import { LayersStyle, Style } from '../model/layers.model';

const commonStyle: Style = {
  color: '#000',
  weight: 2,
  opacity: 1,
  fillOpacity: 0.5
};

export const layersStyle: LayersStyle = {
  TSA: {
    ...commonStyle,
    color: '#786655',
    fillColor: '#786655'
  },
  R: {
    ...commonStyle,
    color: '#FCEC52',
    fillColor: '#FCEC52'
  },
  TRA: {
    ...commonStyle,
    color: '#439966',
    fillColor: '#439966'
  },
  D: {
    ...commonStyle,
    color: '#ea4d36',
    fillColor: '#ea4d36'
  },
  ATZ: {
    ...commonStyle,
    color: '#556678',
    fillColor: '#556678'
  },
  MRT: {
    ...commonStyle,
    color: '#664399',
    fillColor: '#664399'
  },
  TFR: {
    ...commonStyle,
    color: '#66000a',
    fillColor: '#66000a'
  },
  ADHOC: {
    ...commonStyle,
    color: '#6fbaf3',
    fillColor: '#6fbaf3'
  },
  NPZ: {
    ...commonStyle,
    color: '#f7abf2',
    fillColor: '#f7abf2'
  },
  RAD_RS: {
    ...commonStyle,
    color: '#f7abf2',
    fillColor: '#f7abf2'
  },
  SECTOR: {
    ...commonStyle,
    color: '#79716B',
    fillColor: '#79716B',
    fillOpacity: 0.1
  },
  CTR: {
    ...commonStyle,
    color: '#7D89B0',
    fillColor: '#7D89B0'
  },
  MCTR: {
    ...commonStyle,
    color: '#7D89B0',
    fillColor: '#7D89B0'
  },
  MTMA: {
    ...commonStyle,
    color: '#70707B',
    fillColor: '#70707B'
  },
  TMA: {
    ...commonStyle,
    color: '#70707B',
    fillColor: '#70707B'
  }
};

export function setStyleAdditionalLayer(styles: Style): Style {
  return {
    ...styles,
    fillColor: darkenColor(styles.color, 10),
    color: '#fff',
    fillOpacity: 0.8,
    dashArray: '2'
  };
}

export function setHiglightStyle(styles: Style): Style {
  return {
    ...styles,
    color: '#4E6481',
    fillColor: styles.fillColor,
    weight: 6,
    opacity: 1,
    fillOpacity: 0.8
  };
}

export function setTransparentStyle(styles: Style): Style {
  return {
    ...styles,
    fillOpacity: 0.4,
    opacity: 0.4
  };
}

function darkenColor(hex: string, amount: number): string {
  let usePound = false;

  if (hex[0] === '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  const num = parseInt(hex, 16);

  let r = (num >> 16) - amount;
  if (r < 0) r = 0;
  else if (r > 255) r = 255;

  let g = ((num >> 8) & 0x00ff) - amount;
  if (g < 0) g = 0;
  else if (g > 255) g = 255;

  let b = (num & 0x0000ff) - amount;
  if (b < 0) b = 0;
  else if (b > 255) b = 255;

  return (usePound ? '#' : '') + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}
