import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { MapToolbarService } from './map-toolbar.service';
import { MapToolbarAction, MapToolbarPayload } from './map-toolbar-actions';
import { FilterLayer, MapLayerTypeEnum } from 'src/app/model/map.model';
import { ToggleButtonComponent } from 'src/app/ui/toggle-button/toggle-button.component';

@Component({
  selector: 'rqa-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, TooltipModule, ButtonModule, ToggleButtonComponent]
})
export class MapToolbarComponent {
  @Input()
  set filterLayerTypes(value: FilterLayer[]) {
    this.groupLayers1 = value.filter((el) => Object.keys(MapLayerTypeEnum).includes(el.type));
    this.groupLayers2 = value.filter(
      (el) => ![...Object.keys(MapLayerTypeEnum), 'CTR', 'MCTR', 'TMA', 'MTMA', 'SECTOR'].includes(el.type)
    );
    this.groupLayers3 = value.filter((el) => ['CTR', 'MCTR', 'TMA', 'MTMA'].includes(el.type));
    this.groupLayers4 = value.filter((el) => el.type === 'SECTOR');
  }
  isLayerSubmenuVisible = false;
  groupLayers1: FilterLayer[] = [];
  groupLayers2: FilterLayer[] = [];
  groupLayers3: FilterLayer[] = [];
  groupLayers4: FilterLayer[] = [];
  private mapToolbarService = inject(MapToolbarService);

  onSearch(): void {
    this.emitAction('search');
  }

  onZoomOut(): void {
    this.emitAction('zoomOut');
  }

  onZoomIn(): void {
    this.emitAction('zoomIn');
  }

  onToggleLayerGroup(visible: boolean, groupId: string): void {
    this.emitAction('toggleLayerGroup', { visible, groupId });
  }

  onFilters(): void {
    this.emitAction('filters');
  }

  private emitAction(action: MapToolbarAction, payload?: MapToolbarPayload): void {
    this.mapToolbarService.emitAction(action, payload);
  }
}
