<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between pb-5 align-items-center px-3 lg:px-0 orders-header">
    <div>
      <h1 class="text-2xl mx-0 my-0 title">{{ 'orders.title' | translate }}</h1>
      <p class="mx-0 my-0 subtitle">{{ 'orders.subtitle' | translate }}</p>
    </div>
  </section>
  <section class="w-full max-w-screen">
    <p-tabView (onChange)="onTabChange($event)">
      <p-tabPanel [header]="'orders.sent' | translate">
        <rqa-sent-orders *ngIf="activeTabIndex === 0"></rqa-sent-orders>
      </p-tabPanel>
      <p-tabPanel [header]="'orders.canceled' | translate">
        <rqa-canceled-orders *ngIf="activeTabIndex === 1"></rqa-canceled-orders>
      </p-tabPanel>
      <p-tabPanel [header]="'orders.archival' | translate">
        <rqa-archival-orders *ngIf="activeTabIndex === 2"></rqa-archival-orders>
      </p-tabPanel>
    </p-tabView>
  </section>
</div>
