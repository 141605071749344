import { inject, Injectable } from '@angular/core';
import {
  AdhocFormGroup,
  AltitudesGroup,
  CircleFormGroup,
  DateAndAltitudesFormGroup,
  DatesGroup,
  PointFormGroup,
  PolyFormGroup
} from '../model/adhoc.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CoordinatesFormat } from '../model/map.model';
import { altitudesValidator, startDateBeforeEndDateValidator } from '../shared/form/validators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdhocFormService {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  private fb = inject(FormBuilder);
  private adhocDataSubject = new BehaviorSubject<{ activeStep: number; activeTab: number; formData: any } | null>(null);

  setAdhocData(data: { activeStep: number; activeTab: number; formData: any } | null) {
    this.adhocDataSubject.next(data);
  }

  getAdhocData() {
    return this.adhocDataSubject.value;
  }

  observeAdhocData() {
    return this.adhocDataSubject.asObservable();
  }

  createAdhocForm(): AdhocFormGroup {
    return this.fb.group({
      format: new FormControl<string>(CoordinatesFormat.DMS, Validators.required),
      circle: this.fb.group({
        latitude: new FormControl<string>('', Validators.required),
        longitude: new FormControl<string>('', Validators.required),
        radius: new FormControl<number | null>(null, Validators.required),
        unit: new FormControl<{ value: string; label: string }>({ value: 'km', label: 'km' }, Validators.required)
      }) as CircleFormGroup,
      poly: this.fb.group({
        points: this.fb.array([
          this.createPointFormGroup(),
          this.createPointFormGroup(),
          this.createPointFormGroup()
        ] as PointFormGroup[])
      }) as PolyFormGroup,
      dateAndAltitudes: this.fb.group({
        datesGroup: this.fb.group(
          {
            startDate: new FormControl<string>('', Validators.required),
            endDate: new FormControl<string>('', Validators.required)
          },
          { validator: startDateBeforeEndDateValidator }
        ) as DatesGroup,
        altitudesGroup: this.fb.group(
          {
            lowerAltitude: new FormControl<string>('', Validators.required),
            upperAltitude: new FormControl<string>('', Validators.required)
          },
          { validator: altitudesValidator }
        ) as AltitudesGroup
      }) as DateAndAltitudesFormGroup
    }) as AdhocFormGroup;
  }

  private createPointFormGroup(): PointFormGroup {
    return this.fb.group({
      lat: new FormControl<string>('', Validators.required),
      lon: new FormControl<string>('', Validators.required)
    }) as PointFormGroup;
  }

  patchValuesToForm(form: AdhocFormGroup, data: any): AdhocFormGroup {
    form.patchValue({
      format: data.format,
      circle: data.circle,
      poly: {
        points: data.poly.points.map((point: any) => {
          return {
            lat: point.lat,
            lon: point.lon
          };
        })
      },
      dateAndAltitudes: {
        altitudesGroup: {
          lowerAltitude: data.dateAndAltitudes.altitudesGroup.lowerAltitude,
          upperAltitude: data.dateAndAltitudes.altitudesGroup.upperAltitude
        },
        datesGroup: {
          startDate: data.dateAndAltitudes.datesGroup.startDate,
          endDate: data.dateAndAltitudes.datesGroup.endDate
        }
      }
    });
    return form;
  }
}
