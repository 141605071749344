import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Table, TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MenuModule } from 'primeng/menu';
import { AupCalendarItem, ReservationDTO, TimeRange } from 'src/app/model/reservation.model';
import { AirspaceService } from 'src/app/services/airspace.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { getUniqueId } from 'src/app/shared/utils/uuid.util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RqaEditDatesComponent } from '../rqa-edit-dates/rqa-edit-dates.component';
import { RqaRowValidatorService } from 'src/app/services/row-validator.service';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { RestrictInputDirective } from 'src/app/shared/directives/restrict-input.directive';
import { UserConfigService } from 'src/app/services/user-config.service';
import { DateService } from 'src/app/services/date.service';
import { Subject } from 'rxjs';
import { AltitudeChange } from 'src/app/model/altitudes.model';
import { DService } from 'src/app/services/d.service';
import { DialogManagerService } from 'src/app/services/dialog-manager.service';
import { RqaMapService } from '../../rqa-map/rqa-map.service';
import { MapLayerTypeEnum } from 'src/app/model/map.model';
import { ReservationService } from 'src/app/services/reservation.service';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import * as moment from 'moment';
import { RqaCalendarComponent } from 'src/app/ui/calendar/calendar.component';
import { customTableSortDesignator } from 'src/app/shared/utils/custom-sort-designator.util';
import { AutoComplete } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
const RAD_RS = 'RAD_RS';
const D = 'D';
const NPZ = 'NPZ';

@Component({
  selector: 'rqa-table',
  templateUrl: './rqa-table.component.html',
  styleUrls: ['./rqa-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    TableModule,
    ButtonModule,
    CalendarModule,
    ConfirmPopupModule,
    AutoCompleteModule,
    TranslateModule,
    MenuModule,
    IconComponent,
    TooltipModule,
    RestrictInputDirective,
    RqaCalendarComponent,
    PanelModule
  ],
  providers: [DatePipe]
})
export class RqaTableComponent implements OnInit, OnDestroy {
  @ViewChild('dt') pTable!: Table;
  @ViewChild('startDateCalendar') startDateCalendar: Calendar;
  @ViewChild('endDateCalendar') endDateCalendar: Calendar;
  @ViewChild('lowerAltitudeAuto') lowerAltitudeAuto: AutoComplete;
  @ViewChild('upperAltitudeAuto') upperAltitudeAuto: AutoComplete;
  @Input() resetTable: boolean;
  @Output() countChange = new EventEmitter<number>();
  @Output() checkValidTable = new EventEmitter<boolean>();
  private rqaMapService = inject(RqaMapService);
  private confirmationService = inject(ConfirmationService);
  private messageService = inject(MessageService);
  private reservationService = inject(ReservationService);
  private airSpaceService = inject(AirspaceService);
  private dialogService = inject(DialogService);
  private rowValidatorService = inject(RqaRowValidatorService);
  private translateService = inject(TranslateService);
  private userConfigService = inject(UserConfigService);
  private dateService = inject(DateService);
  private cdr = inject(ChangeDetectorRef);
  private dService = inject(DService);
  private altitudeChangeSubject = new Subject<AltitudeChange>();
  private dialogManager = inject(DialogManagerService);
  private layoutUtilsService = inject(LayoutUtilsService);
  private destroyRef = inject(DestroyRef);
  private resizeHandler = () => this.layoutUtilsService.calculateTableHeightForRqa();
  ref: DynamicDialogRef | undefined;
  reservations: ReservationDTO[] = [];
  selectedReservations: ReservationDTO[] = [];
  clonedReservations: { [s: string]: ReservationDTO } = {};
  designators: string[] = [];
  filteredDesignators: string[] = [];
  altitudes: string[];
  filteredAltitudes: string[];
  isEditedRow: boolean;
  reservableInterval: TimeRange;
  minDate: Date;
  maxDate: Date;
  commonMinDate: Date;
  adhocMinDate: Date;
  isRequiredActivityType: boolean = false;
  isRequiredAltitudes: boolean = false;
  isDTypeAirspace: boolean;
  isRadRsTypeAirspace: boolean;
  rowMenuItems: MenuItem[];
  commonActivityType: string;
  calendarDateSelect: Date;
  userTimeZone: string | undefined;
  isMobileDevice: Signal<boolean | undefined> = this.layoutUtilsService.isMobileDevice;
  commonDate: Date | null;
  dateFormat: string = 'yy-mm-dd 06:00';
  aupCalendarDateFormat: string = 'yy-mm-dd 06:00';
  aupList: AupCalendarItem[] = [];
  aupCalendarMinDate: Date;
  aupCalendarMaxDate: Date;
  tableHeight: Signal<string> = this.layoutUtilsService.rqaTableHeight;
  customSortFunction = customTableSortDesignator;

  ngOnInit(): void {
    this.fetchReservations();
    this.fetchDesignators();
    this.getCommonReservableInterval();
    this.getReservableInterval();
    this.getAupList();
    this.getUserTimeZone();
    this.checkClearTable();
    setTimeout(() => {
      this.layoutUtilsService.calculateTableHeightForRqa();
    });
    window.addEventListener('resize', this.resizeHandler);
  }

  onRowEditInit(reservation: ReservationDTO): void {
    this.pTable.initRowEdit(reservation);
    this.isEditedRow = true;
    this.clonedReservations[reservation.tempId as string] = { ...reservation };
    if (reservation.designator) {
      this.getAlitutdesForDesignator(reservation.designator);
    }
    this.checkValidTable.emit(false);
  }

  onRowEditSave(reservation: ReservationDTO): void {
    const isValidRow = this.reservationRowValidation(reservation);
    if (isValidRow) {
      const payload = this.mapReservationPayload(reservation);
      if (reservation.uuid) {
        this.updateReservation(payload);
      } else {
        this.createReservation(payload);
      }
    } else {
      this.pTable.initRowEdit(reservation);
    }
  }

  onRowEditCancel(reservation: ReservationDTO, index: number): void {
    this.isEditedRow = false;
    if (reservation.uuid) {
      this.reservations[index] = this.clonedReservations[reservation.tempId as string];
      delete this.clonedReservations[reservation.tempId as string];
    } else {
      this.reservations = this.reservations.filter((el) => el.tempId !== reservation.tempId);
    }
    this.countChange.emit(this.reservations.length);
    this.checkValidTable.emit(this.isValidTable());
    this.selectLayerOnMap(undefined);
  }

  onConfirmCalendar(): void {
    this.startDateCalendar.overlayVisible = false;
    this.endDateCalendar.overlayVisible = false;
  }

  onShowCalendar(date: Date): void {
    this.calendarDateSelect = date;
  }

  onCancelCalendar(reservation: ReservationDTO, field: string, index: number): void {
    this.startDateCalendar.overlayVisible = false;
    this.endDateCalendar.overlayVisible = false;
    this.reservations[index] = {
      ...reservation,
      [field]: this.calendarDateSelect
    };
  }

  private getInitialStartDate(rowStartDate?: Date): Date | string {
    if (rowStartDate) {
      return new Date(rowStartDate);
    }

    if (this.commonDate) {
      return new Date(this.dateService.setReservationStartDate(this.commonDate));
    }

    return this.minDate;
  }

  private getInitialEndDate(rowEndDate?: Date): Date | string {
    if (rowEndDate) {
      return new Date(rowEndDate);
    }
    return new Date(this.dateService.setReservationEndDate(this.getInitialStartDate()));
  }

  onRowAdd(rowData?: ReservationDTO, designator?: string): void {
    this.pTable?.reset();
    const designatorValue = rowData?.designator || designator || '';

    if (!designatorValue) {
      this.minDate = this.commonMinDate;
    } else {
      const designatorType = this.airSpaceService.findAirspaceByDesignator(designatorValue)?.type;
      this.setMinDateForDesignatorType(designatorType as string);
    }

    const startDate = this.getInitialStartDate(rowData?.startDate as Date);
    const endDate = this.getInitialEndDate(rowData?.endDate as Date);

    const addedRow = {
      designator: designatorValue,
      startDate: startDate,
      endDate: endDate,
      lowerAltitude: rowData?.lowerAltitude || '',
      upperAltitude: rowData?.upperAltitude || '',
      activityType: this.commonActivityType,
      tempId: getUniqueId(2)
    };

    if (addedRow.designator) {
      this.getAlitutdesForDesignator(addedRow.designator);
    }

    this.reservations = [...this.reservations, addedRow];
    this.pTable.initRowEdit(addedRow);
    this.isEditedRow = true;
    this.clonedReservations[addedRow.tempId as string] = { ...addedRow };
    this.countChange.emit(this.reservations.length);
    this.checkValidTable.emit(this.isValidTable());
    this.cdr.detectChanges();
    this.pTable.scrollTo({ top: this.pTable?.wrapperViewChild?.nativeElement.scrollHeight });
  }

  onRowDuplicate(reservation?: ReservationDTO): void {
    let duplicatedRow;
    if (reservation) {
      duplicatedRow = { ...reservation, tempId: getUniqueId(2), uuid: undefined };
      this.getAlitutdesForDesignator(reservation?.designator);
    } else {
      duplicatedRow = { ...this.selectedReservations[0], tempId: getUniqueId(2), uuid: undefined };
      this.getAlitutdesForDesignator(this.selectedReservations[0]?.designator);
    }
    this.reservations = [...this.reservations, duplicatedRow];
    this.pTable.initRowEdit(duplicatedRow);
    this.isEditedRow = true;
    this.clonedReservations[duplicatedRow.tempId as string] = { ...duplicatedRow };
    this.countChange.emit(this.reservations.length);
    this.checkValidTable.emit(this.isValidTable());
    this.cdr.detectChanges();
    this.pTable.scrollTo({ top: this.pTable?.wrapperViewChild?.nativeElement.scrollHeight });
    this.selectLayerOnMap(reservation?.designator);
  }

  onRemoveSelected(): void {
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('dialogs.confirm'),
      acceptButtonStyleClass: 'p-button',
      rejectLabel: this.translateService.instant('dialogs.reject'),
      rejectButtonStyleClass: 'p-button-text p-button-plain',
      header: this.translateService.instant('dialogs.deleteReservation.header'),
      message: this.translateService.instant('dialogs.deleteReservation.msg'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const uuids: string[] = this.selectedReservations.map((el) => el.uuid) as string[];
        this.reservationService.deleteMutliple(uuids).subscribe(() => {
          if (this.hasOverllapError()) {
            this.fetchReservations();
          } else {
            this.reservations = this.reservations.filter((val) => !this.selectedReservations?.includes(val));
            if (!this.reservations.length) {
              this.rqaMapService.selectRowToCopy(undefined);
            }
            this.selectedReservations = [];
            this.countChange.emit(this.reservations.length);
            this.checkValidTable.emit(this.isValidTable());
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('notifications.deletedReservations'),
              life: 3000
            });
          }
          this.updateMapLayers();
        });
      }
    });
  }

  onEditMultiple(): void {
    this.ref = this.dialogService.open(RqaEditDatesComponent, {
      header: this.translateService.instant('dialogs.editReservation.header'),
      data: {
        selectedReservations: this.selectedReservations,
        minDate: this.minDate,
        maxDate: this.maxDate
      },
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      focusOnShow: false
    });

    this.dialogManager.addDialog(this.ref);

    this.ref.onClose.subscribe((editedReservations: ReservationDTO[]) => {
      if (editedReservations) {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.editedMultipleReservation'),
          life: 3000
        });
        editedReservations.forEach((editedRes: ReservationDTO) => {
          const resIdx = this.reservations.findIndex((el) => el.uuid === editedRes.uuid);
          const selectedResIdx = this.selectedReservations.findIndex((el) => el.uuid === editedRes.uuid);
          this.selectedReservations[selectedResIdx] = {
            ...this.selectedReservations[selectedResIdx],
            ...editedRes,
            startDate: this.dateService.parseIsoDateToDateObj(editedRes.startDate as string),
            endDate: this.dateService.parseIsoDateToDateObj(editedRes.endDate as string)
          };
          this.reservations[resIdx] = {
            ...this.reservations[resIdx],
            ...editedRes,
            startDate: this.dateService.parseIsoDateToDateObj(editedRes.startDate as string),
            endDate: this.dateService.parseIsoDateToDateObj(editedRes.endDate as string)
          };
        });
        this.selectedReservations = [];
        this.cdr.detectChanges();
      }
      this.dialogManager.removeDialog(this.ref as DynamicDialogRef);
    });
  }

  onDeaggregate(): void {
    const uuids: string[] = this.selectedReservations.map((el) => el.uuid as string);
    this.reservationService.deaggregate(uuids).subscribe({
      next: (result) => {
        if (result.length) {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.deaggregateSuccess'),
            life: 3000
          });
          this.rqaMapService.selectRowToCopy(undefined);
          this.fetchReservations();
        }
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  onAggregate(): void {
    const uuids: string[] = this.selectedReservations.map((el) => el.uuid as string);
    this.reservationService.aggregate(uuids).subscribe({
      next: (result) => {
        if (result.length) {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('notifications.aggregateSuccess'),
            life: 3000
          });
          this.rqaMapService.selectRowToCopy(undefined);
          this.fetchReservations();
        }
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          detail: err.error.message,
          life: 3000
        });
      }
    });
  }

  searchDesignator(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();
    this.filteredDesignators = this.designators.filter((el) => el.toLowerCase().includes(query));
  }

  onKeyUpDesignator(event: KeyboardEvent): void {
    if (event?.target as HTMLInputElement) {
      const val = (event?.target as HTMLInputElement).value;
      if (val.length > 4) {
        const designator = this.designators.find((el) => val.toUpperCase().includes(el));
        if (designator) {
          this.getAlitutdesForDesignator(designator);
        }
      }
    }
  }

  searchAltitude(
    event: AutoCompleteCompleteEvent,
    reservation: ReservationDTO,
    field: 'lowerAltitude' | 'upperAltitude'
  ): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes?.length
      ? this.altitudes.filter((el) => el.toLowerCase().includes(query))
      : [];
    if (this.isDTypeAirspace) {
      const parsedVal = parseInt(query, 10);
      if (!isNaN(parsedVal) && parsedVal >= 0) {
        if (field === 'lowerAltitude') {
          reservation.lowerAltitude = this.dService.makeValue(reservation.designator, parsedVal, field);
          this.lowerAltitudeAuto?.hide();
        } else {
          reservation.upperAltitude = this.dService.makeValue(reservation.designator, parsedVal, field);
          this.upperAltitudeAuto?.hide();
        }
      }
    } else {
      if (this.filteredAltitudes.length === 1) {
        const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
        this.reservations[reservationIdx][field] = this.filteredAltitudes[0];
        if (field === 'lowerAltitude') {
          this.lowerAltitudeAuto?.hide();
        } else {
          this.upperAltitudeAuto?.hide();
        }
      }
    }
  }

  onAltitudeChange(value: string, type: string, reservation: ReservationDTO) {
    this.altitudeChangeSubject.next({
      value,
      type,
      reservation
    });
  }

  onDesignatorSelect(event: AutoCompleteSelectEvent): void {
    this.getAlitutdesForDesignator(event.value);
  }

  filterReservations($event: Event, matchType: string): void {
    this.pTable.filterGlobal(($event.target as HTMLInputElement).value, matchType);
  }

  onTableFilter(): void {
    const designators = [...new Set(this.pTable.processedData.map((el) => el.designator))];
    this.rqaMapService.updateMapLayers(designators, MapLayerTypeEnum.RQA);
  }

  onShowRowMenu(reservation: ReservationDTO): void {
    this.generateRowMenuItems(reservation);
  }

  onCommonDateSelect(event: Date): void {
    const seletedDate = moment(event).format('YYYY-MM-DD').toString();
    const dateRange = this.aupList.find((el) => el.date === seletedDate)?.range as string;
    const startDate = dateRange.split(' - ')[0];
    const endDate = dateRange.split(' - ')[1];
    this.aupCalendarDateFormat = `${this.dateService.convertDateToUserTimezone(startDate)} - ${this.dateService.convertDateToUserTimezone(endDate)}`;
    this.rqaMapService.fetchRqaMapLayersByDate(startDate.replace(' ', 'T'), endDate.replace(' ', 'T')).subscribe();
    this.cdr.detectChanges();
  }

  onCommonDateClear(): void {
    this.rqaMapService.fetchRqaMapLayers().subscribe();
    this.commonDate = null;
  }

  handleUppercaseInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    input.value = input.value.toUpperCase();

    input.setSelectionRange(start, end);
  }

  private fetchReservations(): void {
    this.selectedReservations = [];
    this.reservationService.getReservations().subscribe((result) => {
      this.reservations = result.map((reservation) => ({
        ...reservation,
        startDate: this.dateService.parseIsoDateToDateObj(reservation.startDate as string),
        endDate: this.dateService.parseIsoDateToDateObj(reservation.endDate as string),
        tempId: getUniqueId(2)
      }));
      this.listenSelectOnMap();
      this.updateMapLayers();
    });
    this.countChange.emit(this.reservations.length);
    this.checkValidTable.emit(this.isValidTable());
  }

  private listenSelectOnMap(): void {
    this.rqaMapService.getSelectedRowToCopy().subscribe((result) => {
      if (result?.row) {
        const row = result.row;
        if (!this.isEditedRow) {
          this.onRowAdd(row);
        } else {
          const reservationsIdx = this.reservations.length - 1;
          this.reservations[reservationsIdx] = {
            ...this.reservations[reservationsIdx],
            designator: row.designator,
            startDate: row?.startDate ? new Date(row?.startDate) : this.minDate,
            endDate: row?.endDate ? new Date(row?.endDate) : this.minDate,
            lowerAltitude: row.lowerAltitude,
            upperAltitude: row.upperAltitude
          };
          this.getAlitutdesForDesignator(row.designator);
        }
      }
      if (result?.designator) {
        if (!this.isEditedRow) {
          this.onRowAdd(undefined, result?.designator);
        } else {
          const reservationsIdx = this.reservations.length - 1;
          this.reservations[reservationsIdx] = {
            ...this.reservations[reservationsIdx],
            designator: result?.designator
          };
          this.getAlitutdesForDesignator(result?.designator);
        }
      }
      this.countChange.emit(this.reservations.length);
      this.checkValidTable.emit(this.isValidTable());
    });
  }

  private getAlitutdesForDesignator(designator: string): void {
    const airSpace = this.airSpaceService.findAirspaceByDesignator(designator);
    const designatorId = airSpace?.id as number;
    const designatorType = airSpace?.type as string;

    this.setMinDateForDesignatorType(designatorType);
    this.checkAirspaceType(designator, designatorType);
    this.selectLayerOnMap(designator);
    if (designatorId) {
      this.fetchAltitudes(designatorId);
    }
  }

  private fetchDesignators(): void {
    this.airSpaceService.fetchDesigators().subscribe((res: string[]) => {
      this.designators = res;
    });
  }

  private fetchAltitudes(designatorId: number): void {
    this.reservationService.fetchAltitudes(designatorId).subscribe((res: string[]) => {
      this.altitudes = res;
    });
  }

  private getCommonReservableInterval(): void {
    const commonInterval = this.reservationService.commonDateInterval();
    if (commonInterval) {
      this.reservableInterval = commonInterval;
      this.commonMinDate = this.dateService.parseIsoDateToDateObj(commonInterval.startDate);
      this.minDate = this.dateService.parseIsoDateToDateObj(commonInterval.startDate);
      this.maxDate = this.dateService.parseIsoDateToDateObj(commonInterval.endDate);
    }
  }

  private getReservableInterval() {
    this.reservationService.getReservableInterval().subscribe((value: TimeRange) => {
      this.adhocMinDate = this.dateService.parseIsoDateToDateObj(value.startDate);
    });
  }

  private getAupList(): void {
    this.reservationService.getAupList().subscribe((res: AupCalendarItem[]) => {
      this.aupList = res;
      this.aupCalendarMinDate = this.dateService.parseIsoDateToDateObj(this.aupList[0].date);
      this.aupCalendarMaxDate = this.dateService.parseIsoDateToDateObj(this.aupList[this.aupList.length - 1].date);
    });
  }

  private getUserTimeZone(): void {
    this.userConfigService.userConfig$.subscribe((userConfig) => {
      this.userTimeZone = userConfig?.timeZone;
    });
  }

  private createReservation(reservation: ReservationDTO): void {
    this.reservationService.addReservation(reservation).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.addedReservation', { designator: res.designator }),
          life: 3000
        });
        this.isEditedRow = false;
        const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
        this.reservations[reservationIdx] = {
          ...res,
          startDate: this.dateService.parseIsoDateToDateObj(res.startDate as string),
          endDate: this.dateService.parseIsoDateToDateObj(res.endDate as string),
          tempId: getUniqueId(2)
        };
        this.updateMapLayers();
        this.checkValidTable.emit(this.isValidTable());
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.pTable.initRowEdit(reservation);
        if (err.error['validation-errors']) {
          const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
          this.reservations[reservationIdx].errors = err.error['validation-errors'];
          this.checkValidTable.emit(false);
        }
      }
    });
  }

  private updateReservation(reservation: ReservationDTO): void {
    this.reservationService.update(reservation).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.editedReservation', { designator: res.designator }),
          life: 3000
        });
        this.isEditedRow = false;
        const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
        this.reservations[reservationIdx] = {
          ...res,
          startDate: this.dateService.parseIsoDateToDateObj(res.startDate as string),
          endDate: this.dateService.parseIsoDateToDateObj(res.endDate as string),
          tempId: getUniqueId(2)
        };
        this.updateMapLayers();
        this.checkValidTable.emit(this.isValidTable());
      },
      error: (err) => {
        this.pTable.initRowEdit(reservation);
        if (err.error['validation-errors']) {
          const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
          this.reservations[reservationIdx].errors = err.error['validation-errors'];
          this.checkValidTable.emit(false);
        }
      }
    });
  }

  private deleteReservation(reservation: ReservationDTO): void {
    this.reservationService.delete(reservation.uuid as string).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.deletedReservation', {
            designator: reservation.designator
          }),
          life: 3000
        });
        if (this.hasOverllapError()) {
          this.fetchReservations();
        } else {
          this.isEditedRow = false;
          this.reservations = this.reservations.filter((el) => el.uuid !== reservation.uuid);
          this.selectedReservations = this.selectedReservations.filter((el) => el.uuid !== reservation.uuid);
          this.countChange.emit(this.reservations.length);
          this.checkValidTable.emit(this.isValidTable());
          this.updateMapLayers();
        }
      },
      error: (err) => {
        throw err;
      }
    });
  }

  private reservationRowValidation(reservation: ReservationDTO): boolean {
    const validationErrors = [
      ...this.rowValidatorService.dateValidation(
        reservation.startDate as Date,
        reservation.endDate as Date,
        this.minDate,
        this.maxDate
      ),
      ...this.rowValidatorService.checkRequiredFields(
        reservation,
        this.isRequiredActivityType,
        this.isRequiredAltitudes
      )
    ];
    if (!validationErrors.length) {
      return true;
    }
    const reservationIdx = this.reservations.findIndex((el) => el.tempId === reservation.tempId);
    this.reservations[reservationIdx].errors = validationErrors;
    this.clonedReservations[reservation.tempId as string].errors = validationErrors;
    return false;
  }

  private mapReservationPayload(reservation: ReservationDTO): ReservationDTO {
    return {
      ...reservation,
      designator: reservation.designator.toUpperCase(),
      startDate: this.dateService.parseDateToReservationRow(reservation.startDate as string),
      endDate: this.dateService.parseDateToReservationRow(reservation.endDate as string)
    };
  }

  private generateRowMenuItems(reservation: ReservationDTO): void {
    this.rowMenuItems = [
      {
        label: this.translateService.instant('buttons.edit'),
        icon: 'pi pi-pencil',
        command: () => {
          this.onRowEditInit(reservation);
        }
      },
      {
        label: this.translateService.instant('buttons.duplicate'),
        icon: 'pi pi-clone',
        command: () => {
          this.onRowDuplicate(reservation);
        }
      },
      {
        label: this.translateService.instant('buttons.delete'),
        icon: 'pi pi-times',
        command: () => {
          this.deleteReservation(reservation);
        }
      }
    ];
  }

  private selectLayerOnMap(designator: string | undefined): void {
    this.rqaMapService.setLayerSelect(designator);
  }

  private setMinDateForDesignatorType(designatorType: string): void {
    if (designatorType === 'ADHOC') {
      this.minDate = this.adhocMinDate;
    } else {
      this.minDate = this.commonMinDate;
    }
  }

  private checkAirspaceType(designator: string, designatorType: string): void {
    if (!designatorType) {
      const epdPattern = /^EPD[2-9]\d*[A-Z]*$/;
      if (epdPattern.test(designator)) {
        this.isDTypeAirspace = true;
        this.isRadRsTypeAirspace = false;
        this.isRequiredActivityType = false;
        this.isRequiredAltitudes = true;
        return;
      }
    }
    this.isDTypeAirspace = designatorType === D;
    this.isRadRsTypeAirspace = designatorType === RAD_RS;
    this.isRequiredActivityType = designatorType !== D && designatorType !== RAD_RS && designatorType !== NPZ;
    this.isRequiredAltitudes = designatorType !== RAD_RS;
  }

  private isValidTable(): boolean {
    if (this.reservations.length && !this.isEditedRow) {
      return this.reservations.findIndex((el) => el.errors?.length) === -1;
    }
    return false;
  }

  private hasOverllapError(): boolean {
    if (this.reservations.length) {
      return this.reservations.findIndex((el) => el.errors?.find((err) => err.field === 'overlapped')) !== -1;
    }
    return false;
  }

  private updateMapLayers(): void {
    const designators = [
      ...new Set(
        this.reservations
          .map((el) => (el.designators && el.designators?.length > 0 ? el.designators : [el.designator]))
          .flat()
      )
    ];
    this.rqaMapService.updateMapLayers(designators, MapLayerTypeEnum.RQA);
  }

  private checkClearTable(): void {
    this.reservationService.clearTable$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((clearTable) => {
      if (clearTable) {
        this.pTable.reset();
        this.reservations = [];
        this.selectedReservations = [];
        this.countChange.emit(0);
        this.checkValidTable.emit(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.rqaMapService.selectRowToCopy(undefined);
    window.removeEventListener('resize', this.resizeHandler);
  }
}
