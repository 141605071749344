import { map, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { AupDetails, AupItem } from '../model/observer.model';
import { MapService } from './map.service';

const url = `/api/uup`;

@Injectable({
  providedIn: 'root'
})
export class ObserverService {
  private http = inject(HttpClient);
  private mapService = inject(MapService);
  designatorOptions: WritableSignal<string[]> = signal([]);
  unitOptions: WritableSignal<string[]> = signal([]);
  aupDetails: WritableSignal<AupDetails> = signal({
    dateFrom: '',
    dateTo: '',
    name: '',
    status: '',
    reservationShortDTOS: []
  });

  fetchAupList(): Observable<AupItem[]> {
    return this.http.get<AupItem[]>(`${url}/getAupLastStatus`);
  }

  fetchAupDetails(dates: { localDate: string }[]): Observable<AupDetails> {
    return this.http.post<AupDetails[]>(`${url}/findUpByDates`, dates).pipe(
      tap((details) => {
        const uniqueDesignators = [...new Set(details[0].reservationShortDTOS.map((item) => item.designator))].sort(
          (a, b) => a.localeCompare(b)
        );
        const uniqueUnits = [...new Set(details[0].reservationShortDTOS.map((item) => item.responsibleUnit))];

        this.designatorOptions.set(uniqueDesignators);
        this.unitOptions.set(uniqueUnits.filter((el) => el !== null));
        this.aupDetails.set(details[0]);
        this.mapService.updateMapLayers(uniqueDesignators, 'AUP');
      }),
      map((res: AupDetails[]) => res[0])
    );
  }
}
