<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
    <div>
      <h1 class="text-2xl mx-0 my-0 title">{{ 'help.title' | translate }}</h1>
    </div>
  </section>
  <section>
    <p-card>
      <div [innerHTML]="helpContent"></div>
      <div id="contact">
        {{ 'help.contact' | translate }}
        <div>
          <span class="asm"><strong>ASM2</strong></span> 22 574 57 40 <br />
          <span class="asm"><strong>AMC</strong></span>
          22 574 57 31 <br />
          amc.poland&#64;pansa.pl
        </div>
      </div>
    </p-card>
  </section>
</div>
