import { CommonModule } from '@angular/common';
import { Component, inject, Input, Signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutUtilsService } from 'src/app/services/layout-utils.service';

@Component({
  selector: 'rqa-status-info',
  templateUrl: './status-info.component.html',
  styleUrl: './status-info.component.scss',
  imports: [CommonModule, TooltipModule, TranslateModule],
  standalone: true
})
export class StatusInfoComponent {
  @Input() statusName: string;
  private layoutUtilsService = inject(LayoutUtilsService);
  isMobileDevice: Signal<boolean | undefined> = this.layoutUtilsService.isMobileDevice;
}
