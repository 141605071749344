import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CanceledOrdersComponent } from '../canceled-orders/canceled-orders.component';

@Component({
  selector: 'rqa-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    CanceledOrdersComponent,
    RouterModule,
    TranslateModule
  ],
  providers: [RouterModule]
})
export class OrdersListComponent {}
