import { SortEvent } from 'primeng/api';

interface SortableItem {
  [key: string]: string | number | Date | boolean | null | undefined;
}

export const customTableSortDesignator = (event: SortEvent) => {
  const { field, data, order } = event;

  if (!data) return [];

  return data.sort((a: SortableItem, b: SortableItem) => {
    const aValue = a[field as keyof typeof a];
    const bValue = b[field as keyof typeof b];

    if (!aValue && !bValue) return 0;
    if (!aValue) return 1;
    if (!bValue) return -1;

    let result = 0;

    if (field === 'designator') {
      result = compareDesignators(aValue as string, bValue as string);
    } else {
      result = String(aValue).localeCompare(String(bValue));
    }

    return (order ?? 1) * result;
  });
};

const LETTERS_AND_DIGITS_DESIGNATOR = /[A-Z]+(Z[A-Y]?)?$/;
const DIGIT_PATTERN = /\d/;
const FBZ_INDICATOR = 'Z';

function compareDesignators(designator1: string, designator2: string): number {
  const o1BaseStr = getBaseLetters(designator1);
  const o2BaseStr = getBaseLetters(designator2);

  const o1BaseLong = getBaseNumber(designator1);
  const o2BaseLong = getBaseNumber(designator2);

  const o1SufStr = getSuffix(designator1);
  const o2SufStr = getSuffix(designator2);

  if (o1BaseStr.localeCompare(o2BaseStr) !== 0) {
    return o1BaseStr.localeCompare(o2BaseStr);
  } else if (o1BaseLong !== o2BaseLong) {
    return o1BaseLong < o2BaseLong ? -1 : 1;
  } else if (o1SufStr.localeCompare(o2SufStr) !== 0) {
    return o1SufStr.localeCompare(o2SufStr);
  } else {
    return 0;
  }

  function getBase(designator: string): string {
    if (!designator) return '';
    designator = designator.trim();
    if (designatorHasSpaces(designator)) {
      return extractCompoundDesignator(designator);
    }
    const parts = designator.split(LETTERS_AND_DIGITS_DESIGNATOR);
    return parts[0] || designator;
  }

  function extractSegments(designator: string): string {
    const beginning = getBase(designator);
    if (!beginning) return beginning;
    const ending = designator.substring(beginning.length);
    if (endWithFbz(ending)) {
      return ending.substring(0, ending.lastIndexOf(FBZ_INDICATOR));
    }
    return ending;
  }

  function getSuffix(designator: string): string {
    return sortedDistinct(extractSegments(designator));
  }

  function sortedDistinct(str: string): string {
    const chars = Array.from(str);
    return [...new Set(chars)].sort().join('');
  }

  function getBaseLetters(designator: string): string {
    const base = getBase(designator);
    if (hasDigits(base)) {
      const match = base.match(DIGIT_PATTERN);
      if (match && match.index !== undefined) {
        return base.substring(0, match.index);
      }
    }
    return base;
  }

  function getBaseNumber(designator: string): number {
    const base = getBase(designator);
    if (hasDigits(base)) {
      const match = designator.match(DIGIT_PATTERN);
      if (match && match.index !== undefined) {
        const resultStr = base.substring(match.index);
        const result = parseInt(resultStr, 10);
        return isNaN(result) ? 0 : result;
      }
    }
    return 0;
  }

  function hasDigits(text: string): boolean {
    if (!text) return false;
    return DIGIT_PATTERN.test(text);
  }

  function endWithFbz(ending: string): boolean {
    for (let i = 65; i < 90; ++i) {
      if (ending.endsWith(FBZ_INDICATOR + String.fromCharCode(i))) return true;
    }
    return ending.endsWith(FBZ_INDICATOR);
  }

  function designatorHasSpaces(designator: string): boolean {
    return /\s/.test(designator);
  }

  function extractCompoundDesignator(designator: string): string {
    const spaceIndex = designator.lastIndexOf(' ');
    return (
      designator.substring(0, spaceIndex) +
      designator.substring(spaceIndex, Math.min(spaceIndex + 5, designator.length))
    );
  }
}
