import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { SentOrdersComponent } from '../sent-orders/sent-orders.component';
import { CanceledOrdersComponent } from '../canceled-orders/canceled-orders.component';
import { ArchivalOrdersComponent } from '../archival-orders/archival-orders.component';

@Component({
  selector: 'rqa-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    RouterModule,
    TranslateModule,
    TabViewModule,
    SentOrdersComponent,
    CanceledOrdersComponent,
    ArchivalOrdersComponent
  ],
  providers: [RouterModule]
})
export class OrdersListComponent {
  activeTabIndex: number = 0;

  onTabChange(event: any): void {
    this.activeTabIndex = event.index;
  }
}
