<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6">
  <section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
    <div>
      <h1 class="text-2xl mx-0 my-0 title">{{ 'orders.title' | translate }}</h1>
      <p class="mx-0 my-0 subtitle">{{ 'orders.subtitle' | translate }}</p>
    </div>
  </section>
  <section class="w-full">
    <router-outlet></router-outlet>
  </section>
</div>
