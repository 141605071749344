<nav class="toolbar">
  <ul>
    <li class="has-submenu">
      <p-button
        [text]="true"
        [plain]="true"
        (click)="isLayerSubmenuVisible = !isLayerSubmenuVisible"
        tooltipPosition="bottom"
        [pTooltip]="'tooltips.layers' | translate"
      >
        <rqa-icon iconName="layers"></rqa-icon>
      </p-button>
      <ul class="submenu max-h-screen" *ngIf="isLayerSubmenuVisible">
        @for (layer of groupLayers1; track layer) {
          <li class="pt-2">
            <rqa-toggle-button
              [label]="'map.layers.' + layer.type.toLowerCase() | translate"
              [className]="layer.type.toLowerCase()"
              [checked]="layer.checked"
              (changed)="onToggleLayerGroup($event, layer.type)"
            ></rqa-toggle-button>
          </li>
        }
        <hr class="mx-0 mt-2 mb-0" />
        @for (layer of groupLayers2; track layer) {
          <li class="pt-2">
            <rqa-toggle-button
              [label]="'map.layers.' + layer.type.toLowerCase() | translate"
              [className]="layer.type.toLowerCase()"
              [checked]="layer.checked"
              (changed)="onToggleLayerGroup($event, layer.type)"
            ></rqa-toggle-button>
          </li>
        }
        <hr class="mx-0 mt-2 mb-0" />
        @for (layer of groupLayers3; track layer) {
          <li class="pt-2">
            <rqa-toggle-button
              [label]="'map.layers.' + layer.type.toLowerCase() | translate"
              [className]="layer.type.toLowerCase()"
              [checked]="layer.checked"
              (changed)="onToggleLayerGroup($event, layer.type)"
            ></rqa-toggle-button>
          </li>
        }
        <hr class="mx-0 mt-2 mb-0" />
        @for (layer of groupLayers4; track layer) {
          <li class="pt-1 pb-1">
            <rqa-toggle-button
              [label]="'map.layers.' + layer.type.toLowerCase() | translate"
              [className]="layer.type.toLowerCase()"
              [checked]="layer.checked"
              (changed)="onToggleLayerGroup($event, layer.type)"
            ></rqa-toggle-button>
          </li>
        }
      </ul>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onZoomIn()"
        tooltipPosition="bottom"
        [pTooltip]="'tooltips.zoomIn' | translate"
      >
        <rqa-icon iconName="plus-square"></rqa-icon>
      </p-button>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onZoomOut()"
        tooltipPosition="bottom"
        [pTooltip]="'tooltips.zoomOut' | translate"
      >
        <rqa-icon iconName="minus-square"></rqa-icon>
      </p-button>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onSearch()"
        tooltipPosition="bottom"
        [pTooltip]="'tooltips.search' | translate"
      >
        <rqa-icon iconName="search"></rqa-icon>
      </p-button>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onFilters()"
        tooltipPosition="bottom"
        [pTooltip]="'tooltips.filters' | translate"
      >
        <rqa-icon iconName="filters"></rqa-icon>
      </p-button>
    </li>
  </ul>
</nav>
