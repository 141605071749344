@if (selectedTemplateDetails()?.length > 0) {
  <h5 class="mb-1 mt-0">{{ 'map.box.templateDetails' | translate }}</h5>
  <table class="w-full mb-2">
    <tr class="text-xs text-left">
      <th>{{ 'map.box.time' | translate }}</th>
      <th>{{ 'map.box.height' | translate }}</th>
      <th>{{ 'map.box.activityType' | translate }}</th>
    </tr>
    <tbody>
      @for (templateDetail of selectedTemplateDetails(); track templateDetail.uuid) {
        @if (templateDetail.designator === designator) {
          <tr class="text-xs">
            <td>{{ templateDetail.startTime }} - {{ templateDetail.endTime }}</td>
            <td>{{ templateDetail.lowerAltitude }} - {{ templateDetail.upperAltitude }}</td>
            <td>{{ templateDetail.remarks }}</td>
          </tr>
        }
      }
    </tbody>
  </table>
  <p-panel [toggleable]="true" header="{{ 'map.box.availabilities' | translate }}" [collapsed]="true">
    <p-table [value]="_availabilities" [scrollable]="true" scrollHeight="300px">
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th pSortableColumn="startDate">{{ 'map.box.time' | translate }}</th>
          <th pSortableColumn="lowerAltitude">{{ 'map.box.height' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-availability>
        <tr class="text-xs">
          <td>
            {{ availability.startDate | date: 'dd/MM/yy HH:mm' }} -
            {{ availability.endDate | date: 'dd/MM/yy HH:mm' }}
          </td>
          <td>
            {{ availability.lowerAltitude }} - {{ availability.upperAltitude }}
            <p-button
              *ngIf="TYPES.indexOf(type) === -1"
              icon="pi pi-clone"
              [rounded]="true"
              [text]="true"
              (click)="copyRow(availability)"
              [styleClass]="'copy-box-button'"
            ></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-panel>
} @else {
  <p-table [value]="_availabilities" [scrollable]="true" scrollHeight="300px">
    <ng-template pTemplate="header">
      <tr class="text-xs">
        <th pSortableColumn="startDate">{{ 'map.box.time' | translate }}</th>
        <th pSortableColumn="lowerAltitude">{{ 'map.box.height' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-availability>
      <tr class="text-xs">
        <td>
          {{ availability.startDate | date: 'dd/MM/yy HH:mm' }} -
          {{ availability.endDate | date: 'dd/MM/yy HH:mm' }}
        </td>
        <td>
          {{ availability.lowerAltitude }} - {{ availability.upperAltitude }}
          <p-button
            *ngIf="TYPES.indexOf(type) === -1"
            icon="pi pi-clone"
            [rounded]="true"
            [text]="true"
            (click)="copyRow(availability)"
            [styleClass]="'copy-box-button'"
          ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
