import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AupMapComponent } from './aup-map/aup-map.component';
import { AupReservationsComponent } from './aup-reservations/aup-reservations.component';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';

@Component({
  selector: 'rqa-aup-details',
  templateUrl: './aup-details.component.html',
  styleUrls: ['./aup-details.component.scss'],
  standalone: true,
  imports: [CommonModule, AupMapComponent, AupReservationsComponent, SplitterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AupDetailsComponent {
  leftSideComponent = AupReservationsComponent;
  rightSideComponent = AupMapComponent;
}
