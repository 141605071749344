import { LayersStyle, Style } from '../model/layers.model';

export const layersStyle: LayersStyle = {
  tsa: {
    styles: {
      activated: {
        fillColor: '#786655',
        color: '#786655',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#786655',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  r: {
    styles: {
      activated: {
        color: '#FCEC52',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#FCEC52',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  tra: {
    styles: {
      activated: {
        fillColor: '#439966',
        color: '#439966',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#439966',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  d: {
    styles: {
      activated: {
        color: '#ea4d36',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#ea4d36',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  atz: {
    styles: {
      activated: {
        color: '#556678',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#556678',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  mrt: {
    styles: {
      activated: {
        color: '#664399',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#664399',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  tfr: {
    styles: {
      activated: {
        color: '#66000a',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#66000a',
        color: '#4E6481',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  adhoc: {
    styles: {
      activated: {
        color: '#6fbaf3',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#6fbaf3',
        color: '#499AD8',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  npz: {
    styles: {
      activated: {
        color: '#f7abf2',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#fcbdf8',
        color: '#fcbdf8',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  rad_rs: {
    styles: {
      activated: {
        color: '#f7abf2',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.5,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#fcbdf8',
        color: '#fcbdf8',
        weight: 6,
        opacity: 1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  },
  sector: {
    styles: {
      activated: {
        color: '#8a8684',
        weight: 1,
        opacity: 1,
        fillOpacity: 0.1,
        dashArray: '0'
      },
      highlight: {
        fillColor: '#8a8684',
        color: '#8a8684',
        weight: 6,
        opacity: 0.1,
        fillOpacity: 0.8,
        dashArray: '0'
      }
    }
  }
};

export function setStyleAdditionalLayer(styles: Style): Style {
  return {
    ...styles,
    color: '#000',
    fillColor: darkenColor(styles.color, 10),
    fillOpacity: 0.8,
    dashArray: '3'
  };
}

function darkenColor(hex: string, amount: number): string {
  let usePound = false;

  if (hex[0] === '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  const num = parseInt(hex, 16);

  let r = (num >> 16) - amount;
  if (r < 0) r = 0;
  else if (r > 255) r = 255;

  let g = ((num >> 8) & 0x00ff) - amount;
  if (g < 0) g = 0;
  else if (g > 255) g = 255;

  let b = (num & 0x0000ff) - amount;
  if (b < 0) b = 0;
  else if (b > 255) b = 255;

  return (usePound ? '#' : '') + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
}
