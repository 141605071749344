<form [formGroup]="editDatesForm">
  <div class="flex">
    <label htmlFor="designator">{{ 'table.header.designator' | translate }}</label>
  </div>
  <div class="flex gap-2 flex-wrap">
    @for (reservation of selectedReservations; track reservation.uuid) {
      <p-chip
        [label]="reservation.designator"
        [removable]="selectedReservations.length > 1"
        (onRemove)="onDesignatorRemove(reservation)"
      ></p-chip>
    }
  </div>
  <div class="flex mt-3 lg:flex-row flex-column gap-2">
    <div class="flex flex-column gap-2">
      <label htmlFor="startDate">{{ 'table.header.startDate' | translate }}</label>
      <span class="p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <rqa-calendar formControlName="startDate" [minDate]="minDate" [maxDate]="maxDate" [showTime]="true" />
        <rqa-validator [control]="editDatesForm.get('startDate')"></rqa-validator>
      </span>
    </div>
    <div class="flex flex-column gap-2">
      <label htmlFor="endDate">{{ 'table.header.endDate' | translate }}</label>
      <span class="p-input-icon-left">
        <i class="pi pi-calendar"></i>
        <rqa-calendar formControlName="endDate" [minDate]="minDate" [maxDate]="maxDate" [showTime]="true" />
        <rqa-validator [control]="editDatesForm.get('endDate')"></rqa-validator>
      </span>
    </div>
  </div>
  <rqa-validator [formGroup]="editDatesForm"></rqa-validator>
  <div class="update-errors">
    <ul class="m-0">
      @for (err of errorMessages; track err.field) {
        <li class="text-xs p-error">
          <strong>{{ getDesignatorByUuid(err?.uuid) }} {{ 'table.header.' + err.field | translate }}: </strong
          >{{ err.message }}
        </li>
      }
    </ul>
  </div>
  <div class="flex mt-5 mb-4 justify-content-between">
    <p-button
      [label]="'buttons.cancel' | translate"
      [text]="true"
      [plain]="true"
      icon="pi pi-minus-circle"
      (click)="close()"
    ></p-button>
    <p-button [label]="'buttons.save' | translate" icon="pi pi-check" (click)="save()"></p-button>
  </div>
</form>
