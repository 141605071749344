<div class="status-info" [ngClass]="statusName.toLowerCase()">
  @if (isMobileDevice()) {
    <span
      class="circle"
      [pTooltip]="'observer.status.' + statusName.toLowerCase() | translate"
      tooltipPosition="top"
    ></span>
  } @else {
    <span class="circle"></span>
  }
  <span class="name" *ngIf="!isMobileDevice()">{{ 'observer.status.' + statusName.toLowerCase() | translate }}</span>
</div>
