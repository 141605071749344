import { inject, Injectable, signal, WritableSignal } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { AirspaceElement, CopiedMapData, FilterLayer, MapLayerTypeEnum } from 'src/app/model/map.model';
import { layersStyle, setStyleAdditionalLayer } from 'src/app/map/layersStyle';

@Injectable({
  providedIn: 'root'
})
export class RqaMapService {
  private http = inject(HttpClient);
  private layerSelectBehaviorSubject = new BehaviorSubject<string | undefined>(undefined);
  private $formLayerSelect = this.layerSelectBehaviorSubject.asObservable();

  private selectedRow = new BehaviorSubject<CopiedMapData | undefined>(undefined);
  private $selectedRow = this.selectedRow.asObservable();
  readonly API_URL: string = '/api/airspace';
  readonly API_URL_OBSERVER: string = '/api/airspace/aup';
  readonly layerStyles = layersStyle;

  mapLayers: WritableSignal<AirspaceElement[]> = signal([]);
  filterLayerTypes: WritableSignal<FilterLayer[]> = signal([]);

  fetchRqaMapLayers(): Observable<AirspaceElement[]> {
    return this.http.get<AirspaceElement[]>(this.API_URL).pipe(
      map((layers) => layers.filter((layer) => layer.type !== 'RAD_RS')),
      tap((layers) => {
        this.mapLayers.set(
          layers.map((layer) => ({
            ...layer,
            styles: layersStyle[layer.type]
          }))
        );
      })
    );
  }

  updateMapLayers(designators: string[], type: MapLayerTypeEnum) {
    const newLayers = this.mapLayers()
      .filter((value) => designators.includes(value.designator) && value.type !== type)
      .map((airspace) => ({
        ...airspace,
        type: type,
        styles: setStyleAdditionalLayer(layersStyle[airspace.type])
      }));
    this.mapLayers.update((current) => [...current.filter((el) => el.type !== type), ...newLayers]);
  }

  setLayerSelect(data: string | undefined) {
    this.layerSelectBehaviorSubject.next(data);
  }

  getLayerSelect(): Observable<string | undefined> {
    return this.$formLayerSelect;
  }

  selectRowToCopy(data: CopiedMapData | undefined) {
    this.selectedRow.next(data);
  }

  getSelectedRowToCopy(): Observable<CopiedMapData | undefined> {
    return this.$selectedRow;
  }

  setFilterLayerTypes(filterLayerTypes: FilterLayer[]) {
    this.filterLayerTypes.set(filterLayerTypes);
  }
}
