import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  Signal,
  SimpleChanges,
  effect,
  inject
} from '@angular/core';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import { layersStyle, setStyleForRqaLayer } from './layersStyle';
import { AirspaceElement, Coordinates, FilterLayer, LayersRef, MapLayers, MapLayersType } from '../model/map.model';
import * as Turf from '@turf/turf';
import { CookieService } from 'ngx-cookie-service';
import { SearchMapDialogComponent } from './search-map-dialog/search-map-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MapService } from '../services/map.service';
import { circleToPolygon, convertCoordinate } from '../shared/utils/coordinates.utils';
import { IPoint } from '../model/adhoc.model';
import { AdhocService } from '../services/adhoc.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DialogManagerService } from '../services/dialog-manager.service';
import { setStyleForAupLayer } from './layersStyle';

@Component({
  selector: 'rqa-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [
    trigger('slideInFromRight', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))])
    ])
  ]
})
export class MapComponent implements OnInit, OnChanges {
  @Input() resizeMap: boolean;
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private cookieService = inject(CookieService);
  private mapService = inject(MapService);
  private adhocService = inject(AdhocService);
  private dialogManager = inject(DialogManagerService);
  private cdr = inject(ChangeDetectorRef);
  destroyRef = inject(DestroyRef);
  ref: DynamicDialogRef;

  readonly mapLayers: Signal<MapLayers> = this.mapService.mapLayers;
  isLayerSubmenuVisible: boolean = false;
  iconRetinaUrl = 'assets/marker-icon-2x.png';
  iconUrl = 'assets/marker-icon.png';
  shadowUrl = 'assets/marker-shadow.png';
  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],

      iconUrl: 'assets/img/marker-icon.png',
      shadowUrl: 'assets/img/marker-shadow.png'
    })
  };
  options: L.MapOptions = {
    layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: 'Open Street Map' })],
    zoom: 6,
    minZoom: 5,
    maxZoom: 17,
    center: new L.LatLng(environment.center[0], environment.center[1]),
    zoomControl: false
  };
  filterLayerTypes: Signal<FilterLayer[]> = this.mapService.filterLayerTypes;
  visibleLayerGroups: { [key: string]: L.FeatureGroup } = {};
  disabledAreas: string[] = [];
  map: L.Map;
  selectedLayers: AirspaceElement[];
  layersRef: LayersRef[] = [];
  currentMarker: L.Marker;
  lastActiveLayer: AirspaceElement;
  drawnItems = new L.FeatureGroup();
  activeTabIndex: number | null = null;
  mapView: MapLayersType = 'RQA';

  constructor() {
    effect(
      () => {
        this.mapView = this.mapLayers().type;
        this.getDisabledAreas();
        if (this.mapLayers().data.length) {
          this.createLayers();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.listenSelectLayerOnTable();
    this.listenDrawCircle();
    this.listenDrawPoly();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resizeMap'].currentValue) {
      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 300);
    }
  }

  onMapReady(map: L.Map): void {
    this.map = map;
    this.displayCoordinates(map);
    setTimeout(() => {
      this.map.invalidateSize(true);
    }, 300);
  }

  zoomIn(): void {
    this.map.zoomIn();
  }

  zoomOut(): void {
    this.map.zoomOut();
  }

  onSearch(): void {
    this.ref = this.dialogService.open(SearchMapDialogComponent, {
      header: this.translateService.instant('dialogs.searchMap.header'),
      contentStyle: { overflow: 'auto' },
      width: '40rem',
      baseZIndex: 10000
    });
    this.dialogManager.addDialog(this.ref);
    this.ref.onClose.subscribe((data: Coordinates) => {
      if (data) {
        this.addSearchMarker(data);
      }
      this.dialogManager.removeDialog(this.ref);
    });
  }

  onToggle(index: number): void {
    if (index !== null) {
      const toggledLayer = this.selectedLayers[index];
      const layer = this.layersRef.find((el) => el.name === toggledLayer.designator)?.layer;
      if (layer) {
        if (this.lastActiveLayer) {
          const lastToggledLayer = this.layersRef.find((el) => el.name === this.lastActiveLayer.designator);
          lastToggledLayer?.layer?.setStyle(layersStyle[toggledLayer.type.toLowerCase()].styles.activated);
        }

        const watchStyle = layersStyle[toggledLayer.type.toLowerCase()].styles;
        layer.setStyle(watchStyle.highlight);
        layer.bringToFront();
        this.lastActiveLayer = toggledLayer;
      }
    } else {
      this.resetLayerStyles();
    }
  }

  toggleLayerGroupVisibility(visible: boolean, groupId: string): void {
    if (!visible) {
      this.disabledAreas.push(groupId);
    } else {
      this.disabledAreas = this.disabledAreas.filter((el) => el !== groupId);
    }
    this.setDisabledAreas();
    const group = this.visibleLayerGroups[groupId];
    if (this.map.hasLayer(group)) {
      group.remove();
    } else {
      group.addTo(this.map);
    }
    this.bringToFrontLayers();
  }

  trackByFn(index: number, layer: AirspaceElement): number {
    return layer.id;
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.selectedLayers = [];
    this.addMarker(e.latlng);
    this.resetLayerStyles();
    const findLayers: L.Polygon[] = [];
    this.layersRef.forEach((layerRef) => {
      const isEnableType = this.disabledAreas.indexOf(layerRef.type) === -1;
      const layer = layerRef.layer.getLayers()[0];
      if (isEnableType && layer instanceof L.Polygon && layer.feature?.geometry) {
        if (layer.feature && layer.feature.geometry && layer.feature.geometry.type === 'Polygon') {
          const clickPoint = Turf.point([e.latlng.lng, e.latlng.lat]);
          const coordinates = layer.feature.geometry.coordinates;
          try {
            const polygon = Turf.polygon(coordinates);
            // check if clicked point is concluded in layer
            if (Turf.booleanPointInPolygon(clickPoint, polygon)) {
              // find airspace layer by compare designator and className
              const layerFind: AirspaceElement = this.mapLayers().data.find(
                (el: AirspaceElement) => el.designator === layer.options.className
              ) as AirspaceElement;
              findLayers.push(layer);
              this.selectedLayers.push(layerFind);
            }
          } catch (error) {
            console.error('Error creating polygon in Turf.js', error);
          }
        }
      }
    });

    if (findLayers.length === 1) {
      if (this.lastActiveLayer) {
        const lastToggledLayer = this.layersRef.find((el) => el.name === this.lastActiveLayer.designator);
        lastToggledLayer?.layer?.setStyle(layersStyle[this.selectedLayers[0].type.toLowerCase()].styles.activated);
      }
      const watchStyle = layersStyle[this.selectedLayers[0].type.toLowerCase()].styles;
      findLayers[0].setStyle(watchStyle.highlight);
      findLayers[0].bringToFront();
      this.lastActiveLayer = this.selectedLayers[0];
    }
    this.activeTabIndex = this.selectedLayers.length === 1 ? 0 : null;
  }

  onAccordionHeaderClick(layerId: number): void {
    const idx = this.selectedLayers.findIndex((el) => el.id === layerId);
    this.activeTabIndex = idx;
  }

  private createLayers(): void {
    // remove all layers
    this.map.eachLayer((layer) => {
      if (layer instanceof L.FeatureGroup) {
        this.map.removeLayer(layer);
      }
    });
    this.mapService.setFilterLayerTypes([]);
    this.layersRef = [];
    const uniqueLayerTypes = Array.from(
      new Set(
        this.mapLayers()
          .data.filter((layer) => Boolean(layer.geometry))
          .map((el: AirspaceElement) => el.type.toLowerCase())
      )
    );
    this.createLayersGroup(uniqueLayerTypes);
  }

  private createLayersGroup(layersGroups: string[]): void {
    const filterLayerTypes: FilterLayer[] = layersGroups.map((layerGroup) => {
      const group = new L.FeatureGroup();
      this.addLayersByType(layerGroup, group);
      if (group.getLayers().length > 0) {
        group.addTo(this.map);
        this.visibleLayerGroups[layerGroup] = group;
        if (this.disabledAreas.includes(layerGroup)) {
          group.remove();
        }
      }
      return { type: layerGroup, visible: true };
    });
    this.mapService.setFilterLayerTypes(filterLayerTypes);
  }

  private addLayersByType(type: string, group: L.FeatureGroup): void {
    this.mapLayers()
      .data.filter((o: AirspaceElement) => o.type.toLowerCase() === type)
      .forEach((o: AirspaceElement) => {
        const layer = this.createLayerForAirspaceElement(o);
        layer.addTo(group);
        this.layersRef.push({
          type: type,
          layer: layer,
          name: o.designator,
          styles: o.styles
        });
      });
  }

  private createLayerForAirspaceElement(airpaceElement: AirspaceElement): L.GeoJSON {
    const layer = new L.GeoJSON(airpaceElement.geometry as Turf.GeometryObject);
    if (airpaceElement.type === 'AUP') {
      layer.setStyle({ ...setStyleForAupLayer(airpaceElement.styles.activated), className: airpaceElement.designator });
    } else if (airpaceElement.type === 'RQA') {
      layer.setStyle({ ...setStyleForRqaLayer(airpaceElement.styles.activated), className: airpaceElement.designator });
    } else {
      layer.setStyle({ ...airpaceElement.styles.activated, className: airpaceElement.designator });
    }
    layer.setZIndex(airpaceElement.id);
    layer.on('click', () => {
      this.map.fitBounds(layer.getBounds(), { maxZoom: 8 });
    });
    return layer;
  }

  private addMarker(latlng: L.LatLng): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    this.currentMarker = L.marker(latlng, this.markerIcon)
      .bindTooltip(`${latlng.lat.toFixed(4)} ${latlng.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
  }

  private addSearchMarker(latLng: Coordinates): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    const latLngConverted = new L.LatLng(latLng.latitude, latLng.longitude);
    this.currentMarker = L.marker(latLngConverted, this.markerIcon)
      .bindTooltip(`${latLngConverted.lat.toFixed(4)} ${latLngConverted.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
    this.map.setView(latLngConverted, 10);
  }

  private displayCoordinates(map: L.Map): void {
    const Coordinates = L.Control.extend({
      onAdd: (mapCoordinates: L.Map) => {
        const container = L.DomUtil.create('div');
        mapCoordinates.addEventListener('mousemove', (e: L.LeafletMouseEvent) => {
          container.innerHTML = `
            <div class='latlngInfo'>
            ${this.translateService.instant('map.coordinates')}<br>
            ${this.translateService.instant('map.latitude')}: <strong>${e.latlng.lat.toFixed(4)}</strong>
            ${this.translateService.instant('map.longitude')}: <strong>${e.latlng.lng.toFixed(4)}</strong>  
            </div>
            `;
        });
        return container;
      }
    });
    map.addControl(new Coordinates({ position: 'bottomright' }));
  }

  private setDisabledAreas(): void {
    if (this.mapView === 'RQA') {
      this.cookieService.set(
        'inactiveAreas',
        JSON.stringify(Array.from(new Set(this.disabledAreas))),
        365,
        '/',
        '',
        true,
        'Strict'
      );
    }
  }

  private resetLayerStyles(): void {
    this.layersRef.forEach((layerRef) => {
      if (layerRef.type === 'rqa') {
        layerRef.layer.setStyle(setStyleForRqaLayer(layerRef.styles.activated));
      } else if (layerRef.type === 'aup') {
        layerRef.layer.setStyle(setStyleForAupLayer(layerRef.styles.activated));
      } else {
        layerRef.layer.setStyle(layerRef.styles.activated); // default style
      }
    });
  }

  private listenSelectLayerOnTable(): void {
    this.mapService
      .getLayerSelect()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectedLayer) => {
        this.selectedLayers = [];
        this.resetLayerStyles();
        if (selectedLayer) {
          const layerRef = this.layersRef.find((el) => el.name === selectedLayer);
          if (layerRef) {
            const styles = layersStyle[layerRef.type].styles;
            layerRef.layer.setStyle(styles.highlight);
            this.map.fitBounds(layerRef.layer.getBounds(), { maxZoom: 8 });
            layerRef.layer.bringToFront();
            this.selectedLayers = this.mapLayers().data.filter(
              (el: AirspaceElement) => el.designator === selectedLayer
            );
          }
        }
        this.activeTabIndex = this.selectedLayers.length === 1 ? 0 : null;
        this.cdr.detectChanges();
      });
  }

  private cleanDrawLayers(): void {
    const drawnItems = this.drawnItems.getLayers();
    if (drawnItems.length) {
      drawnItems.forEach((layer) => {
        this.map.removeLayer(layer);
      });
    }
    if (this.map) {
      this.map.setView(new L.LatLng(environment.center[0], environment.center[1]), 6);
    }
    this.adhocService.setGeoJson(null);
  }

  private listenDrawCircle(): void {
    this.adhocService
      .getCircleToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((circleData) => {
        this.cleanDrawLayers();
        if (circleData) {
          const latLngConverted = new L.LatLng(
            convertCoordinate(circleData.latitude),
            convertCoordinate(circleData.longitude)
          );
          const circleLayer = L.circle(latLngConverted, { radius: circleData.radius });
          circleLayer.addTo(this.map);
          this.drawnItems.addLayer(circleLayer);
          this.map.fitBounds(circleLayer.getBounds());
          this.adhocService.setGeoJson(circleToPolygon(circleLayer));
        }
      });
  }

  private listenDrawPoly(): void {
    this.adhocService
      .getPolyToDraw()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((polyData) => {
        this.cleanDrawLayers();
        const latLng: L.LatLng[] = [];
        if (polyData) {
          polyData.points.forEach((point: IPoint) => {
            if (point.lat && point.lon) {
              const coords = new L.LatLng(convertCoordinate(point.lat), convertCoordinate(point.lon));
              latLng.push(coords);
            }
          });
          const polyLayer = L.polygon(latLng);
          polyLayer.addTo(this.map);
          this.drawnItems.addLayer(polyLayer);
          this.map.fitBounds(polyLayer.getBounds());
          this.adhocService.setGeoJson(polyLayer.toGeoJSON());
        }
      });
  }

  private getDisabledAreas(): void {
    if (this.mapView === 'RQA') {
      this.disabledAreas = this.cookieService.get('inactiveAreas')
        ? Array.from(new Set(JSON.parse(this.cookieService.get('inactiveAreas'))))
        : [];
    }
  }

  private bringToFrontLayers(): void {
    this.layersRef
      .filter((layer) => layer.type === this.mapView.toLowerCase())
      .forEach((el) => {
        el.layer.bringToFront();
      });
  }
}
