import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';
import { AdhocStepperComponent } from './adhoc-stepper/adhoc-stepper.component';
import { AdhocMapComponent } from './adhoc-map/adhoc-map.component';

@Component({
  selector: 'rqa-adhoc',
  templateUrl: './adhoc.component.html',
  styleUrls: ['./adhoc.component.scss'],
  standalone: true,
  imports: [CommonModule, SplitterComponent, AdhocMapComponent, AdhocStepperComponent]
})
export class AdhocComponent {
  leftSideComponent = AdhocStepperComponent;
  rightSideComponent = AdhocMapComponent;
}
