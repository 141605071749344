<div class="toolbar">
  <ul>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onZoomIn()"
        tooltipPosition="left"
        [pTooltip]="'tooltips.zoomIn' | translate"
      >
        <rqa-icon iconName="plus-square"></rqa-icon>
      </p-button>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onZoomOut()"
        tooltipPosition="left"
        [pTooltip]="'tooltips.zoomOut' | translate"
      >
        <rqa-icon iconName="minus-square"></rqa-icon>
      </p-button>
    </li>
    <li class="has-submenu">
      <p-button
        [text]="true"
        [plain]="true"
        (click)="isLayerSubmenuVisible = !isLayerSubmenuVisible"
        tooltipPosition="left"
        [pTooltip]="'tooltips.layers' | translate"
      >
        <rqa-icon iconName="layers"></rqa-icon>
      </p-button>
      <ul class="submenu" *ngIf="isLayerSubmenuVisible" [@slideInFromRight]>
        @for (layer of filterLayerTypes; track layer) {
          <li class="pt-1 pb-1">
            <rqa-toggle-button
              [label]="layer.type"
              [checked]="disabledAreas.indexOf(layer.type) === -1"
              (changed)="onToggleLayerGroup($event, layer.type)"
            ></rqa-toggle-button>
          </li>
        }
      </ul>
    </li>
    <li>
      <p-button
        [text]="true"
        [plain]="true"
        (click)="onSearch()"
        tooltipPosition="left"
        [pTooltip]="'tooltips.search' | translate"
      >
        <rqa-icon iconName="search"></rqa-icon>
      </p-button>
    </li>
  </ul>
</div>
