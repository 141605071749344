import * as moment from 'moment';
import * as momenttz from 'moment-timezone';
import { UserConfigService } from './user-config.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor(private userConfig: UserConfigService) {}

  parseIsoDateToDateObj(isoDate: string): Date {
    const timeZone = this.userConfig.getTimezone();
    if (timeZone !== 'UTC') {
      const dateUTC = moment.utc(isoDate);
      return momenttz.tz(dateUTC, 'Europe/Warsaw').toDate();
    }
    return new Date(isoDate);
  }

  parseIsoDateToString(isoDate: string): string {
    const timeZone = this.userConfig.getTimezone();

    if (timeZone !== 'UTC') {
      const localDate = moment.utc(isoDate).tz('Europe/Warsaw');
      return localDate.format('YYYY-MM-DDTHH:mm:ss');
    }
    return isoDate;
  }

  parseDateToIsoFormat(dateString: string): string {
    return new Date(dateString).toISOString();
  }

  parseDateToReservationRow(dateString: string): string {
    const timeZone = this.userConfig.getTimezone();
    if (timeZone !== 'UTC') {
      const localDate = moment.tz(dateString, 'Europe/Warsaw');
      return localDate.utc().format('YYYY-MM-DDTHH:mm:ss');
    }
    return moment(dateString).format('YYYY-MM-DDTHH:mm:ss');
  }

  parseDateToString(date: Date, format: string): string {
    return moment(date).format(format);
  }

  setReservationEndDate(minDate: Date): string {
    const timeZone = this.userConfig.getTimezone();
    const resultDate = moment(minDate)
      .add(1, 'days')
      .set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
      .format('YYYY-MM-DDTHH:mm:ss');
    if (timeZone !== 'UTC') {
      const localDate = moment.utc(resultDate).tz('Europe/Warsaw');
      return localDate.format('YYYY-MM-DDTHH:mm:ss');
    }
    return new Date(resultDate).toISOString();
  }

  convertUtcToLocalTime(timeString: string): string {
    const timeZone = this.userConfig.getTimezone();
    if (timeZone !== 'UTC') {
      const utcTime = moment.tz(timeString, 'HH:mm', 'UTC');
      return utcTime.clone().tz('Europe/Warsaw').format('HH:mm');
    }
    return timeString;
  }

  convertLocalTimeToUtc(timeString: string): string {
    const timeZone = this.userConfig.getTimezone();
    if (timeZone !== 'UTC') {
      const localTime = moment.tz(timeString, 'HH:mm', 'Europe/Warsaw');
      return localTime.clone().utc().format('HH:mm');
    }
    return timeString;
  }

  adjustTimeToLocal(hoursUTC: string): string {
    const utcTime = moment.utc(`1970-01-01T${hoursUTC}:00Z`);
    const localTime = utcTime.tz('Europe/Warsaw');

    return localTime.format('HH:mm');
  }

  convertDateToUserTimezone(date: string | Date): string {
    try {
      const timeZone = this.userConfig.getTimezone();
      const momentDate = moment.utc(date);

      if (!momentDate.isValid()) {
        throw new Error('Invalid date format');
      }

      if (timeZone === 'UTC') {
        return momentDate.utc().format('YYYY-MM-DD HH:mm:ss');
      } else {
        return momentDate.tz('Europe/Warsaw').format('YYYY-MM-DD HH:mm:ss');
      }
    } catch (error) {
      throw new Error(`Error converting date: ${error}`);
    }
  }
}
