import { Layer } from 'leaflet';
import { ReservationDTO } from './reservation.model';
import { Styles } from './layers.model';

export class MapLayersModel {
  constructor(
    public baseLayers: BaseLayer[],
    public baseLayer: string,
    public overlayLayers: OverlayLayer[] = []
  ) {}
}

export interface BaseLayer {
  id: string;
  name: string;
  enabled: boolean;
  layer: Layer;
}

export interface OverlayLayer extends BaseLayer {
  color: string;
}

export interface AirspaceElement {
  id: number;
  type: string;
  designator: string;
  availabilities: Availability[];
  info: Info[];
  geometry?: Geometry;
  styles: Styles;
}

export interface EPDAirspaceDTO {
  designator: string;
  addition: boolean;
  value: number;
  altitude: number;
}

export interface Availability {
  startDate: string;
  endDate: string;
  lowerAltitude: string;
  upperAltitude: string;
}

export interface Info {
  header: string;
  content: string;
}

export interface Geometry {
  type: string;
  coordinates: [number, number][];
}

export interface LayersRef {
  type: string;
  layer: L.GeoJSON;
  name: string;
  styles: Styles;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export enum CoordinatesFormat {
  DMS = 'DMS',
  DECIMAL = 'DECIMAL'
}

export interface CopiedMapData {
  row?: ReservationDTO;
  designator?: string;
}

export interface FilterLayer {
  type: string;
  visible: boolean;
  checked?: boolean;
}
export type MapLayersType = 'RQA' | 'AUP' | 'UUP' | 'TEMPLATE' | 'ORDER';

export enum MapLayerTypeEnum {
  RQA = 'RQA',
  AUP = 'AUP',
  UUP = 'UUP',
  TEMPLATE = 'TEMPLATE',
  ORDER = 'ORDER'
}

export const DYNAMIC_LAYERS_TYPES: string[] = ['RQA', 'AUP', 'UUP', 'ORDER', 'TEMPLATE'];
