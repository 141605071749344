import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  EditMultipleDates,
  ReservationDTO,
  RqaCreateDTO,
  RqaReservationDTO,
  RqaStatus,
  TimeRange,
  WebrqaResponse,
  AupCalendarItem
} from '../model/reservation.model';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  private http = inject(HttpClient);
  readonly API_URL: string = '/api/rqa';
  private clearTableSubject = new BehaviorSubject<boolean>(false);
  clearTable$ = this.clearTableSubject.asObservable();
  holidaysInfo: WritableSignal<WebrqaResponse | null> = signal(null);
  commonDateInterval: WritableSignal<TimeRange | null> = signal(null);

  setClearTable(clearTable: boolean): void {
    this.clearTableSubject.next(clearTable);
  }

  getReservations(): Observable<ReservationDTO[]> {
    return this.http.get<ReservationDTO[]>(`${this.API_URL}/reservation`);
  }

  getReservableInterval(): Observable<TimeRange> {
    return this.http.get<TimeRange>(`${this.API_URL}/reservable-interval`);
  }

  getCommonReservableInterval(): Observable<TimeRange> {
    return this.http.get<TimeRange>(`${this.API_URL}/reservable-interval/common`).pipe(
      tap((range: TimeRange) => {
        this.commonDateInterval.set(range);
      })
    );
  }

  send(rqa: RqaCreateDTO) {
    return this.http.post<RqaStatus>(`${this.API_URL}`, rqa);
  }

  addReservation(reservation: ReservationDTO): Observable<ReservationDTO> {
    return this.http.post<ReservationDTO>(`${this.API_URL}/reservation`, reservation);
  }

  update(reservation: ReservationDTO) {
    return this.http.put<ReservationDTO>(`${this.API_URL}/reservation/${reservation.uuid}`, reservation);
  }

  updateMultiple(reservations: EditMultipleDates) {
    return this.http.patch<RqaReservationDTO[]>(`${this.API_URL}/reservation/update`, reservations);
  }

  delete(uuid: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.API_URL}/reservation/${uuid}`);
  }

  deleteMutliple(uuids: string[]): Observable<boolean> {
    return this.http.put<boolean>(`${this.API_URL}/reservation/delete`, uuids);
  }

  aggregate(uuids: string[]): Observable<ReservationDTO[]> {
    return this.http.patch<ReservationDTO[]>(`${this.API_URL}/reservation/aggregate`, uuids);
  }

  deaggregate(uuids: string[]): Observable<ReservationDTO[]> {
    return this.http.patch<ReservationDTO[]>(`${this.API_URL}/reservation/deaggregate`, uuids);
  }

  fetchAltitudes(id: number): Observable<string[]> {
    return this.http.get<string[]>(`/api/altitudes?id=${id}`);
  }

  getHolidays(): Observable<WebrqaResponse> {
    return this.http.get<WebrqaResponse>(`/api/holidays`).pipe(
      tap((res) => {
        this.holidaysInfo.set(res);
      })
    );
  }

  getAupList(): Observable<AupCalendarItem[]> {
    return this.http.get<AupCalendarItem[]>(`${this.API_URL}/reservation/aupList`);
  }
}
