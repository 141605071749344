@if (reservations) {
  <p-table
    [value]="reservations"
    [responsive]="true"
    [responsiveLayout]="'stack'"
    [breakpoint]="'960px'"
    [styleClass]="'pansa-table p-datatable-responsive'"
    dataKey="tempId"
    [(selection)]="selectedReservations"
    editMode="row"
    #dt
    [scrollable]="true"
    [scrollHeight]="tableHeight()"
    (onFilter)="onTableFilter()"
    [customSort]="true"
    (sortFunction)="customSortFunction($event)"
    [globalFilterFields]="['designator', 'startDate', 'endDate', 'upperAltitude', 'lowerAltitude', 'activityType']"
  >
    <ng-template pTemplate="caption">
      @if (isMobileDevice()) {
        <p-panel [toggleable]="true" [collapsed]="true" header="{{ 'table.filters' | translate }}">
          <div class="flex flex-column gap-2">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-search"></i>
              <input
                class="w-full"
                pInputText
                type="text"
                (input)="filterReservations($event, 'containsWithDateFormat')"
                [placeholder]="'table.search' | translate"
              />
            </span>
            <p-calendar
              class="w-full"
              [(ngModel)]="commonDate"
              [dateFormat]="aupCalendarDateFormat"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [readonlyInput]="true"
              [touchUI]="true"
              appendTo="body"
              [placeholder]="'table.commonDate' | translate"
              (onSelect)="onCommonDateSelect($event)"
              (onClear)="onCommonDateClear()"
              [minDate]="aupCalendarMinDate"
              [maxDate]="aupCalendarMaxDate"
              pTooltip="{{ 'tooltips.commonDateInfo' | translate }}"
              tooltipPosition="top"
            >
              <ng-template pTemplate="footer">
                <div class="flex justify-content-between">
                  <p-button
                    [label]="'primeng.clear' | translate"
                    [text]="true"
                    icon="pi pi-times"
                    (click)="onCommonDateClear()"
                  ></p-button>
                </div>
              </ng-template>
            </p-calendar>
            <input
              class="w-full"
              rqaRestrictInput
              type="text"
              pInputText
              [(ngModel)]="commonActivityType"
              oninput="this.value = this.value.toUpperCase()"
              [placeholder]="'table.commonActivityType' | translate"
              maxlength="20"
              tooltipPosition="top"
              [pTooltip]="'table.commonActivityTypeTooltip' | translate"
              tooltipEvent="focus"
            />
          </div>
        </p-panel>
      } @else {
        <div class="flex justify-content-between flex-column lg:flex-row">
          <div class="flex gap-2 w-full">
            <span class="p-input-icon-left width-30">
              <i class="pi pi-search"></i>
              <input
                class="w-full"
                pInputText
                type="text"
                (input)="filterReservations($event, 'containsWithDateFormat')"
                [placeholder]="'table.search' | translate"
              />
            </span>
            <p-calendar
              class="width-40"
              [(ngModel)]="commonDate"
              [dateFormat]="aupCalendarDateFormat"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [readonlyInput]="true"
              [touchUI]="false"
              appendTo="body"
              [placeholder]="'table.commonDate' | translate"
              (onSelect)="onCommonDateSelect($event)"
              (onClear)="onCommonDateClear()"
              [minDate]="aupCalendarMinDate"
              [maxDate]="aupCalendarMaxDate"
              pTooltip="{{ 'tooltips.commonDateInfo' | translate }}"
              tooltipPosition="top"
            >
              <ng-template pTemplate="footer">
                <div class="flex justify-content-between">
                  <p-button
                    [label]="'primeng.clear' | translate"
                    [text]="true"
                    icon="pi pi-times"
                    (click)="onCommonDateClear()"
                  ></p-button>
                </div>
              </ng-template>
            </p-calendar>
            <input
              class="width-30"
              rqaRestrictInput
              type="text"
              pInputText
              [(ngModel)]="commonActivityType"
              oninput="this.value = this.value.toUpperCase()"
              [placeholder]="'table.commonActivityType' | translate"
              maxlength="20"
              tooltipPosition="top"
              [pTooltip]="'table.commonActivityTypeTooltip' | translate"
              tooltipEvent="focus"
            />
          </div>
        </div>
      }
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="designator">
          {{ 'table.header.designator' | translate }} <p-sortIcon field="designator"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" [pSortableColumn]="'startDate'">
          {{ 'table.header.startDate' | translate }} <p-sortIcon field="startDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="endDate">
          {{ 'table.header.endDate' | translate }} <p-sortIcon field="endDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="lowerAltitude">
          {{ 'table.header.lowerAltitude' | translate }} <p-sortIcon field="lowerAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="upperAltitude">
          {{ 'table.header.upperAltitude' | translate }} <p-sortIcon field="upperAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="activityType">
          {{ 'table.header.activityType' | translate }} <p-sortIcon field="activityType"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-reservation let-editing="editing" let-ri="rowIndex" let-columns="columns">
      <tr
        [pEditableRow]="reservation"
        class="reservation-row text-sm"
        [ngClass]="{ 'row-error': reservation?.errors?.length }"
      >
        <td>
          <div class="flex align-items-center">
            <p-tableCheckbox [value]="reservation" [disabled]="isEditedRow"></p-tableCheckbox>
          </div>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.designator' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-autoComplete
                inputStyleClass="w-6rem"
                appendTo="body"
                [(ngModel)]="reservation.designator"
                [autoHighlight]="true"
                [suggestions]="filteredDesignators"
                (completeMethod)="searchDesignator($event)"
                (onSelect)="onDesignatorSelect($event)"
                [forceSelection]="false"
                (onKeyUp)="onKeyUpDesignator($event)"
                [completeOnFocus]="true"
              ></p-autoComplete>
            </ng-template>
            <ng-template pTemplate="output">
              <strong>{{ reservation.designator }}</strong>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.startDate' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <rqa-calendar
                [(ngModel)]="reservation.startDate"
                [hideOnDateTimeSelect]="false"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [showTime]="true"
                [touchUI]="isMobileDevice()"
              />
            </ng-template>
            <ng-template pTemplate="output">
              {{ reservation.startDate | date: 'yyyy-MM-dd HH:mm' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.endDate' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <rqa-calendar
                [(ngModel)]="reservation.endDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [hideOnDateTimeSelect]="false"
                [showTime]="true"
                [touchUI]="isMobileDevice()"
              />
            </ng-template>
            <ng-template pTemplate="output">
              {{ reservation.endDate | date: 'yyyy-MM-dd HH:mm' }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.lowerAltitude' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-autoComplete
                #lowerAltitudeAuto
                appendTo="body"
                inputStyleClass="w-4rem"
                [(ngModel)]="reservation.lowerAltitude"
                [suggestions]="filteredAltitudes"
                (completeMethod)="searchAltitude($event, reservation, 'lowerAltitude')"
                [forceSelection]="!isDTypeAirspace"
                [disabled]="!reservation.designator"
                [delay]="isDTypeAirspace ? 750 : 300"
                [completeOnFocus]="true"
              ></p-autoComplete>
            </ng-template>
            <ng-template pTemplate="output">
              {{ reservation.lowerAltitude }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.upperAltitude' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-autoComplete
                #upperAltitudeAuto
                inputStyleClass="w-4rem"
                appendTo="body"
                [(ngModel)]="reservation.upperAltitude"
                [suggestions]="filteredAltitudes"
                (completeMethod)="searchAltitude($event, reservation, 'upperAltitude')"
                [forceSelection]="!isDTypeAirspace"
                [disabled]="!reservation.designator"
                [delay]="isDTypeAirspace ? 750 : 300"
                [completeOnFocus]="true"
              ></p-autoComplete>
            </ng-template>
            <ng-template pTemplate="output">
              {{ reservation.upperAltitude }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.activityType' | translate }}</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input
                maxlength="25"
                pTooltip="{{ 'tooltips.activityTypeInfo' | translate }}"
                tooltipPosition="top"
                tooltipStyleClass="text-xs"
                rqaRestrictInput
                pInputText
                [(ngModel)]="reservation.activityType"
                [required]="isRequiredActivityType"
                (input)="handleUppercaseInput($event)"
              />
            </ng-template>
            <ng-template pTemplate="output">
              {{ reservation.activityType }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td class="p-1">
          <div class="flex align-items-center sm:justify-content-center justify-content-end w-full sm:w-auto">
            <p-menu
              #menu
              [model]="rowMenuItems"
              [popup]="true"
              [appendTo]="'body'"
              (onShow)="onShowRowMenu(reservation)"
            ></p-menu>
            <button
              [disabled]="isEditedRow"
              *ngIf="!editing"
              pButton
              pRipple
              type="button"
              (click)="menu.toggle($event)"
              icon="pi pi-ellipsis-v"
              class="p-button-rounded p-button-text h-2rem w-2rem"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pSaveEditableRow
              icon="pi pi-check"
              (click)="onRowEditSave(reservation)"
              class="p-button-rounded p-button-text p-button-success mr-2 h-2rem w-2rem"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pCancelEditableRow
              icon="pi pi-times"
              (click)="onRowEditCancel(reservation, ri)"
              class="p-button-rounded p-button-text p-button-danger h-2rem w-2rem"
            ></button>
          </div>
        </td>
      </tr>
      <tr *ngIf="reservation?.errors?.length || reservation?.warnings?.length" class="row-validation">
        <td colspan="8" class="p-2">
          <ul class="m-0 p-0">
            @for (err of reservation.errors; track err.field) {
              <li class="text-xs p-error">
                <strong *ngIf="err.field !== 'dab' && err.field !== 'overlapped'"
                  >{{ 'table.header.' + err.field | translate }}: </strong
                >{{ err.message }}
              </li>
            }
          </ul>
          <ul class="m-0 p-0">
            @for (msg of reservation.warnings; track $index) {
              <li class="text-xs p-warn">
                {{ msg }}
              </li>
            }
          </ul>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8" class="text-center">
          @if (!reservations.length) {
            {{ 'table.noResults' | translate }}
            <a href="#" (click)="onRowAdd()">{{ 'buttons.here' | translate }}</a>
          } @else {
            {{ 'table.noResultsFilter' | translate }}
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="table-buttons mt-1 flex justify-content-between flex-column lg:flex-row">
    <div class="flex flex-wrap">
      <p-button
        [label]="'buttons.deleteSelected' | translate"
        [disabled]="!selectedReservations.length || isEditedRow"
        (onClick)="onRemoveSelected()"
        [text]="true"
        [plain]="true"
      >
        <rqa-icon iconName="trash" class="p-button-icon-left"></rqa-icon>
      </p-button>
      <p-button
        [label]="'buttons.duplicate' | translate"
        [disabled]="selectedReservations.length !== 1 || isEditedRow"
        (onClick)="onRowDuplicate()"
        [text]="true"
        [plain]="true"
      >
        <rqa-icon iconName="duplicate" class="p-button-icon-left"></rqa-icon>
      </p-button>
      <p-button
        [label]="'buttons.editTime' | translate"
        [disabled]="!selectedReservations.length || isEditedRow"
        (onClick)="onEditMultiple()"
        [text]="true"
        [plain]="true"
      >
        <rqa-icon iconName="clock" class="p-button-icon-left"></rqa-icon>
      </p-button>
      <p-button
        [label]="'buttons.disaggregate' | translate"
        [disabled]="selectedReservations.length < 1 || isEditedRow"
        (onClick)="onDeaggregate()"
        [text]="true"
        [plain]="true"
      >
        <rqa-icon iconName="file-minus" class="p-button-icon-left"></rqa-icon>
      </p-button>
      <p-button
        [label]="'buttons.aggregate' | translate"
        [disabled]="selectedReservations.length < 2 || isEditedRow"
        (onClick)="onAggregate()"
        [text]="true"
        [plain]="true"
      >
        <rqa-icon iconName="file-plus" class="p-button-icon-left"></rqa-icon>
      </p-button>
    </div>
    <p-button
      [label]="'buttons.addRow' | translate"
      (onClick)="onRowAdd()"
      [disabled]="isEditedRow"
      [text]="true"
      class="add-row"
      [plain]="true"
      icon="pi pi-plus"
    ></p-button>
  </div>
}
