<div class="p-card w-full p-1 relative">
  <div class="flex justify-content-between align-items-center px-2 py-1">
    <h3 class="m-0 text-base">{{ data[0].designator }} <p-badge [value]="data.length" /></h3>
    <div class="legend">
      <div class="flex items-center gap-2">
        <div class="legend">
          <ul class="list-none m-0 p-0 flex items-center gap-2">
            <li *ngFor="let key of getLegendKeys()" class="flex align-items-center gap-1">
              <label class="graph-checkbox">
                <input type="checkbox" [checked]="selectedAnnotations[key]" (change)="onCheckboxChange(key, $event)" />
                <span
                  class="checkbox-graph"
                  [ngStyle]="{ 'background-color': legendData[key].color, 'border-color': legendData[key].borderColor }"
                >
                </span>
              </label>
              <span
                class="legend-label text-xs cursor-pointer"
                (click)="op.toggle($event)"
                tabindex="0"
                (keyup.enter)="op.toggle($event)"
                [ngClass]="{ 'text-red-500': legendData[key].overlap.length }"
                >{{ legendData[key].label }}</span
              >
              <p-overlayPanel #op [styleClass]="'p-1'" [baseZIndex]="2000">
                @if (legendData[key].overlap.length) {
                  @for (overlap of legendData[key].overlap; track index; let index = $index) {
                    <div class="flex align-items-center gap-1 text-xs border-bottom-1 py-1">
                      <strong>{{ overlap.label }}</strong> - {{ overlap.overlapHeight }} {{ overlap.overlapTime }}
                    </div>
                  }
                } @else {
                  <div class="text-xs">{{ 'aup.graph.noOverlap' | translate }}</div>
                }
              </p-overlayPanel>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="tooltip"></div>
  <div class="h-12rem">
    <canvas id="chartCanvas" width="100%" height="100%"></canvas>
  </div>
</div>
