import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RqaCreateComponent } from './pages/rqa-create/rqa-create.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { CanceledOrdersComponent } from './pages/orders/canceled-orders/canceled-orders.component';
import { SentOrdersComponent } from './pages/orders/sent-orders/sent-orders.component';
import { EditTemplateComponent } from './pages/templates/edit-template/edit-template.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { UserConfigComponent } from './pages/user-config/user-config.component';
import { AdhocComponent } from './pages/adhoc/adhoc.component';
import { HelpComponent } from './pages/help/help.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { AuthGuard } from './core/auth/auth.guard';
import { ObserverComponent } from './pages/observer/observer.component';
import { AupDetailsComponent } from './pages/observer/aup-details/aup-details.component';
import { MapResolver } from './map/map.resolver';

const routes: Routes = [
  {
    path: '',
    component: RqaCreateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] },
    pathMatch: 'full',
    resolve: { mapLayers: MapResolver }
  },
  {
    path: 'rqa',
    component: RqaCreateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] },
    pathMatch: 'full'
  },
  {
    path: 'observer',
    component: ObserverComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'observer/aup/:date',
    component: AupDetailsComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] },
    resolve: { mapLayers: MapResolver }
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] },
    children: [
      {
        path: '',
        redirectTo: 'sent',
        pathMatch: 'full'
      },
      {
        path: 'sent',
        component: SentOrdersComponent
      },
      {
        path: 'canceled',
        component: CanceledOrdersComponent
      }
    ]
  },
  {
    path: 'templates',
    component: TemplatesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'templates/edit/:id',
    component: EditTemplateComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'adhoc',
    component: AdhocComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_draw'] },
    resolve: { mapLayers: MapResolver }
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'user-config',
    component: UserConfigComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  {
    path: 'privacy-policy',
    component: CookiesPolicyComponent,
    canActivate: [AuthGuard],
    data: { roles: ['webrqa_user', 'webrqa_draw'] }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
