<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-3" *ngIf="aupDetails() as details">
  <section
    class="flex justify-content-between mb-2 align-items-center px-3 lg:px-0 aup-header flex-column lg:flex-row lg:mb-5"
  >
    <div>
      <h1 class="lg:text-2xl text-xl mx-0 my-0 title flex align-items-center">
        <p-button icon="pi pi-arrow-left" [rounded]="true" [text]="true" severity="secondary" (click)="goBack()" />{{
          details.name
        }}
        {{ details.dateFrom }} - {{ details.dateTo }} (UTC)
      </h1>
    </div>
    <div class="py-2">
      <rqa-toggle-button
        [label]="'aup.visualizationDesignators' | translate"
        [className]="'aup'"
        [checked]="visualizationChecked"
        (changed)="onToggleVisualization($event)"
      ></rqa-toggle-button>
    </div>
  </section>
  <section class="overflow-x-auto max-w-screen">
    <p-table
      #dt
      [value]="reservations()"
      [styleClass]="'pansa-table'"
      selectionMode="single"
      [scrollHeight]="tableScrollHeight"
      sortMode="single"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [breakpoint]="'960px'"
      (onRowSelect)="onRowSelect($event)"
      (onRowUnselect)="onRowUnselect($event)"
      rqaTableKeyboardNavigation
      [tabindex]="0"
      [scrollable]="true"
    >
      <ng-template pTemplate="caption">
        @if (!visualizationChecked) {
          <form
            [formGroup]="filtersForm"
            class="filters-form align-items-center justify-content-between justify-content-between flex w-full"
          >
            <div class="filters-container flex-column flex w-11 gap-4">
              <div class="w-full flex pt-2">
                <p-floatLabel class="w-full">
                  <p-multiSelect
                    #multiSelectDesignator
                    inputId="designator"
                    [options]="designatorOptions"
                    formControlName="designator"
                    display="chip"
                    styleClass="w-full"
                    [maxSelectedLabels]="filtersForm.get('designator')?.length"
                    panelStyleClass="w-15rem"
                  />
                  <label for="designator">{{ 'aup.table.designator' | translate }}</label>
                </p-floatLabel>
              </div>
              <div class="w-full flex">
                <p-floatLabel class="w-full">
                  <p-multiSelect
                    #multiSelectResponsibleUnit
                    inputId="responsibleUnit"
                    [options]="unitOptions"
                    formControlName="responsibleUnit"
                    display="chip"
                    styleClass="w-full"
                    [maxSelectedLabels]="filtersForm.get('responsibleUnit')?.length"
                    panelStyleClass="w-15rem"
                  />
                  <label for="responsibleUnit">{{ 'aup.table.responsibleUnit' | translate }}</label>
                </p-floatLabel>
              </div>

              <div class="flex w-full gap-2">
                <div
                  formGroupName="timeRange"
                  class="flex flex-wrap"
                  [ngClass]="{ 'w-4': details.name === 'UUP', 'w-6': details.name === 'AUP' }"
                >
                  <div class="flex flex-nowrap gap-2">
                    <p-floatLabel>
                      <p-inputMask id="from" mask="99:99" formControlName="from" [slotChar]="'_'" rqaTimeControl />
                      <label for="from">{{ 'aup.table.from' | translate }}</label>
                    </p-floatLabel>
                    <p-floatLabel>
                      <p-inputMask id="until" mask="99:99" formControlName="until" [slotChar]="'_'" rqaTimeControl />
                      <label for="until">{{ 'aup.table.until' | translate }}</label>
                    </p-floatLabel>
                  </div>
                  <div>
                    <rqa-validator [formGroup]="filtersForm.get('timeRange')"></rqa-validator>
                  </div>
                </div>

                <div
                  formGroupName="altitudeRange"
                  class="flex flex-wrap"
                  [ngClass]="{ 'w-4': details.name === 'UUP', 'w-6': details.name === 'AUP' }"
                >
                  <div class="flex flex-nowrap gap-2">
                    <p-floatLabel>
                      <p-autoComplete
                        appendTo="body"
                        formControlName="flMin"
                        [suggestions]="filteredAltitudes"
                        (completeMethod)="searchAltitude($event, 'flMin')"
                        [forceSelection]="true"
                        [completeOnFocus]="true"
                      ></p-autoComplete>
                      <label for="flMin">{{ 'aup.table.flMin' | translate }}</label>
                    </p-floatLabel>

                    <p-floatLabel>
                      <p-autoComplete
                        appendTo="body"
                        formControlName="flMax"
                        [suggestions]="filteredAltitudes"
                        (completeMethod)="searchAltitude($event, 'flMax')"
                        [forceSelection]="true"
                        [completeOnFocus]="true"
                      ></p-autoComplete>
                      <label for="flMax">{{ 'aup.table.flMax' | translate }}</label>
                    </p-floatLabel>
                  </div>
                  <div>
                    <rqa-validator [formGroup]="filtersForm.get('altitudeRange')"></rqa-validator>
                  </div>
                </div>

                <div class="flex flex-wrap w-4" *ngIf="details.name === 'UUP'">
                  <p-floatLabel class="w-full">
                    <p-dropdown
                      [options]="statusOptions"
                      formControlName="status"
                      inputId="status"
                      optionLabel="name"
                      [styleClass]="'w-full'"
                    />
                    <label for="status">{{ 'aup.table.status' | translate }}</label>
                  </p-floatLabel>
                </div>
              </div>
            </div>

            <div class="flex w-1 text-center">
              <p-button
                icon="pi pi-filter-slash"
                severity="danger"
                [rounded]="true"
                [text]="true"
                (click)="clearFilters()"
                class="w-full"
              />
            </div>
          </form>
        }
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th class="text-xs" pSortableColumn="designator">
            {{ 'aup.table.designator' | translate }} <p-sortIcon field="designator"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="flMin">
            {{ 'aup.table.flMin' | translate }} <p-sortIcon field="flMin"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="flMax">
            {{ 'aup.table.flMax' | translate }} <p-sortIcon field="flMax"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="from">
            {{ 'aup.table.from' | translate }} <p-sortIcon field="from"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="until">
            {{ 'aup.table.until' | translate }} <p-sortIcon field="until"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="responsibleUnit">
            {{ 'aup.table.responsibleUnit' | translate }} <p-sortIcon field="responsibleUnit"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="remarks">
            {{ 'aup.table.remarks' | translate }} <p-sortIcon field="remarks"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="status" *ngIf="details.name === 'UUP'">
            {{ 'aup.table.status' | translate }} <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th class="p-0">
            <p-button
              icon="pi pi-sort-alt-slash"
              [rounded]="true"
              [text]="true"
              severity="danger"
              (click)="clearSort()"
            />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr class="text-sm" [pSelectableRow]="item">
          <td>
            <span class="p-column-title">{{ 'aup.table.designator' | translate }}</span>
            {{ item.designator }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.flMin' | translate }}</span>
            {{ item.flMin }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.flMax' | translate }}</span>
            {{ item.flMax }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.from' | translate }}</span>
            {{ item.from }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.until' | translate }}</span>
            {{ item.until }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.responsibleUnit' | translate }}</span>
            {{ item.responsibleUnit }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.remarks' | translate }}</span>
            {{ item.remarks }}
          </td>
          <td *ngIf="details.name === 'UUP'" class="py-1">
            <span class="p-column-title">{{ 'aup.table.status' | translate }}</span>
            <rqa-status-info [statusName]="item.status" class="flex" />
          </td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <div class="p-2">
          {{ 'primeng.emptyFilterMessage' | translate }}
        </div>
      </ng-template>
    </p-table>
  </section>
  <section *ngIf="isGraphVisible">
    <rqa-designator-graph [data]="reservationsForChart" class="w-full"></rqa-designator-graph>
  </section>
</div>
