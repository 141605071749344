import * as turf from '@turf/turf';

export function convertToDms(dd: number, isLng: boolean): string {
  const dir = dd < 0 ? (isLng ? 'W' : 'S') : isLng ? 'E' : 'N';
  const pad = isLng ? 3 : 2;

  const absDd = Math.abs(dd);
  const deg = absDd | 0;
  const frac = absDd - deg;
  const min = Math.round(frac * 60) | 0;

  return deg.toString().padStart(pad, '0') + min.toString().padStart(2, '0') + dir;
}

export const decimalToDMS = (longitude: number, latitude: number) => {
  const longitudeDMS = convertToDms(longitude, true);
  const latitudeDMS = convertToDms(latitude, false);

  return `${latitudeDMS}${longitudeDMS}`;
};

export function ddmmssToDecimal(input: number): number {
  // Sprawdzenie, czy liczba jest sześciocyfrowa
  if (input < 100000 || input > 999999) {
    throw new Error('Input must be a 6-digit number.');
  }

  const degrees = Math.floor(input / 10000);
  const minutes = Math.floor((input % 10000) / 100);
  const seconds = input % 100;

  // Konwersja na format dziesiętny
  const decimal = degrees + minutes / 60 + seconds / 3600;

  return decimal;
}

export function circleToPolygon(circle: L.Circle, pointsCount = 64): turf.GeoJSONObject {
  const center = circle.getLatLng();
  const radius = circle.getRadius() / 1000;
  const options = { steps: pointsCount, units: 'kilometers' as turf.Units };

  const circleFeature = turf.circle([center.lng, center.lat], radius, options);
  return circleFeature;
}

export const COORDS_DMS_REGEX = /^\d{6}$/;
export const COORDS_DECIMAL_REGEX = /^\d{2}[.]\d{4}$/;

export function convertCoordinate(coord: string): number {
  if (COORDS_DECIMAL_REGEX.test(coord)) {
    return +coord.replace(',', '.');
  } else {
    return ddmmssToDecimal(+coord);
  }
}
