import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { OrdersService } from 'src/app/services/orders.service';
import { RqaReservationChangeDTO, RqaReservationDTO } from 'src/app/model/reservation.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { OrderStatus, OrderStatusOptions } from 'src/app/model/orders';
import * as moment from 'moment';
import { ConfirmationService, FilterService, MessageService, TreeNode } from 'primeng/api';
import { Route } from 'src/app/model/route.enum';
import { TreeTable, TreeTableModule } from 'primeng/treetable';
import { Router, RouterModule } from '@angular/router';
import { DateService } from 'src/app/services/date.service';

interface Column {
  field: string;
  header: string;
  type: 'string' | 'date';
}

@Component({
  selector: 'rqa-sent-orders',
  templateUrl: './sent-orders.component.html',
  styleUrls: ['./sent-orders.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    TranslateModule,
    CalendarModule,
    SelectButtonModule,
    IconComponent,
    TreeTableModule,
    RouterModule
  ],
  providers: [RouterModule]
})
export class SentOrdersComponent implements OnInit {
  @ViewChild('dt') pTable!: TreeTable;
  @ViewChild('calendar') pCalendar!: Calendar;
  private ordersService = inject(OrdersService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private filterService = inject(FilterService);
  private dateService = inject(DateService);
  private router = inject(Router);
  orders: TreeNode[];
  dateRange: Date[];
  orderFilterStatus: OrderStatus = OrderStatus.SENT;
  selectedOrders: RqaReservationChangeDTO[] = [];
  selectedSubOrders: RqaReservationChangeDTO[] = [];
  orderOptions: OrderStatusOptions[] = [
    {
      label: this.translateService.instant('buttons.sent'),
      value: OrderStatus.SENT
    },
    {
      label: this.translateService.instant('buttons.canceled'),
      value: OrderStatus.CANCELLED
    }
  ];
  selectedNodes: TreeNode[] = [] as TreeNode[];
  selectedOrderOption: OrderStatus = OrderStatus.SENT;
  OrderStatus = OrderStatus;
  cols: Column[] = [
    { field: 'designator', header: this.translateService.instant('table.header.designator'), type: 'string' },
    { field: 'startDate', header: this.translateService.instant('table.header.startDate'), type: 'date' },
    { field: 'endDate', header: this.translateService.instant('table.header.endDate'), type: 'date' },
    { field: 'lowerAltitude', header: this.translateService.instant('table.header.lowerAltitude'), type: 'string' },
    { field: 'upperAltitude', header: this.translateService.instant('table.header.upperAltitude'), type: 'string' },
    { field: 'activityType', header: this.translateService.instant('table.header.activityType'), type: 'string' }
  ];

  uuid: string;
  designator: string;
  status: string;
  startDate: Date;
  endDate: Date;
  lowerAltitude: string;
  upperAltitude: string;
  activityType: string;

  ngOnInit(): void {
    this.getSentOrders();
    this.configureFilterService();

    this.translateService.onLangChange.subscribe(() => {
      this.orderOptions = [
        {
          label: this.translateService.instant('buttons.sent'),
          value: OrderStatus.SENT
        },
        {
          label: this.translateService.instant('buttons.canceled'),
          value: OrderStatus.CANCELLED
        }
      ];
    });
  }

  filterByStatus(event: SelectButtonChangeEvent): void {
    if (event.value === OrderStatus.CANCELLED) {
      this.router.navigate([Route.CANCELED_ORDERS]);
    }
  }

  filterByDate(): void {
    if (this.dateRange.length && this.dateRange[1] !== null) {
      const startDate = moment(this.dateRange[0]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      let endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      if (startDate === endDate) {
        this.dateRange[1] = new Date(moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm'));
        endDate = moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format('YYYY-MM-DD HH:mm');
      }
      this.pTable.filter(startDate, 'startDate', 'dateSameAfter');
      this.pTable.filter(endDate, 'endDate', 'dateSameBefore');
      this.pCalendar.overlayVisible = false;
    }
  }

  clearFilters(): void {
    this.dateRange = [];
    this.pTable.filters = {};
    this.pTable._filter();
  }

  onRemoveSelected(): void {
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('dialogs.confirm'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translateService.instant('dialogs.reject'),
      header: this.translateService.instant('dialogs.deleteReservation.header'),
      message: this.translateService.instant('dialogs.deleteReservation.msg'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const payload: string[] = this.getSubReservationsForRemove();
        this.ordersService.cancelReservations(payload).subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
              life: 3000
            });
            this.getSentOrders();
            this.selectedNodes = [];
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant('notifications.deleteError'),
              life: 3000
            });
          }
        });
      }
    });
  }

  onEditSelected(): void {
    const payload = this.getReservationsForEdit(this.selectedNodes);
    this.ordersService.editSentOrders(payload).subscribe({
      next: () => {
        this.router.navigate([Route.HOME]);
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('notifications.deleteTemplateError'),
          life: 3000
        });
      }
    });
  }

  onDateSelect(): void {
    if (
      this.dateRange &&
      this.dateRange[0] &&
      this.dateRange[1] &&
      this.dateRange[0].getTime() === this.dateRange[1].getTime()
    ) {
      this.dateRange = [this.dateRange[0]];
    }
  }

  private getSentOrders(): void {
    this.ordersService.getSentReservations().subscribe((data) => {
      this.orders = this.mapOrdersToTreeNodes(data);
    });
  }

  private mapOrdersToTreeNodes(orders: RqaReservationDTO[]): TreeNode[] {
    return orders.map((order) => {
      if (order?.subReservationDTOList.length > 1) {
        const children = order.subReservationDTOList.filter((el) => el.status !== 'CANCELED');
        order.subReservationDTOList = [];
        return {
          data: {
            ...order,
            startDate: this.dateService.parseIsoDateToString(order.startDate),
            endDate: this.dateService.parseIsoDateToString(order.endDate)
          },
          children: children.map((el) => ({
            data: {
              ...el,
              startDate: this.dateService.parseIsoDateToString(el.startDate),
              endDate: this.dateService.parseIsoDateToString(el.endDate),
              designator: order.designator,
              lowerAltitude: order.lowerAltitude,
              upperAltitude: order.upperAltitude,
              activityType: order.activityType
            }
          }))
        };
      }
      return {
        data: {
          ...order,
          startDate: this.dateService.parseIsoDateToString(order.startDate),
          endDate: this.dateService.parseIsoDateToString(order.endDate),
          uuid: order.subReservationDTOList[0].uuid
        },
        changed: true
      };
    }) as TreeNode[];
  }

  private getSubReservationsForRemove(): string[] {
    return this.selectedNodes.filter((el) => !el?.children).map((el) => el.data.uuid);
  }

  private getReservationsForEdit(selectedReservations: TreeNode[]): RqaReservationChangeDTO[] {
    return selectedReservations
      .filter((obj) => !Object.prototype.hasOwnProperty.call(obj, 'children'))
      .map((el) => ({
        ...el.data,
        startDate: this.dateService.parseDateToReservationRow(el.data.startDate),
        endDate: this.dateService.parseDateToReservationRow(el.data.endDate),
        changed: true
      })) as RqaReservationChangeDTO[];
  }

  private configureFilterService(): void {
    this.filterService.register('dateSameAfter', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;

      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format('YYYY-MM-DD HH:mm') : null;
      return formattedDate ? moment(formattedDate).isSameOrAfter(moment(filter)) : true;
    });

    this.filterService.register('dateSameBefore', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;

      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format('YYYY-MM-DD HH:mm') : null;
      return formattedDate ? moment(formattedDate).isSameOrBefore(moment(filter)) : true;
    });
  }
}
