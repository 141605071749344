<form [formGroup]="rqaForm" #f="ngForm" (ngSubmit)="onNgSubmit()" novalidate>
  <div class="flex lg:gap-4 lg:flex-row flex-column">
    <div class="flex flex-column gap-2 lg:w-6">
      <label htmlFor="phoneNumber">{{ 'dashboard.phoneNumber' | translate }} <span class="p-error">*</span></label>
      <p-autoComplete
        appendTo="body"
        [completeOnFocus]="true"
        formControlName="phoneNumber"
        (completeMethod)="filterPhone($event)"
        [suggestions]="filteredPhoneNumbers"
      />
      <rqa-validator [control]="rqaForm.get('phoneNumber')"></rqa-validator>
    </div>
    <div class="flex flex-column gap-2 lg:w-6">
      <label htmlFor="contactPerson">{{ 'dashboard.nameAndSurname' | translate }}</label>
      <input type="text" pInputText formControlName="contactPerson" id="contactPerson" />
      <rqa-validator [control]="rqaForm.get('contactPerson')"></rqa-validator>
    </div>
  </div>
  <div class="flex gap-2 mt-3 align-content-center">
    <p-checkbox formControlName="addRemarks" [binary]="true" inputId="addRemarks"></p-checkbox>
    <label for="addRemarks">{{ 'dashboard.addRemarks' | translate }}</label>
  </div>
  @if (rqaForm.get('addRemarks')?.value) {
    <div class="flex flex-column gap-2 mt-4">
      <label htmlFor>{{ 'dashboard.remarks' | translate }}</label>
      <textarea
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="remarks"
        oninput="this.value = this.value.toUpperCase()"
      ></textarea>
      <div>{{ rqaForm.get('remarks')?.value.length }} / 1000</div>
      <rqa-validator [control]="rqaForm.get('remarks')"> </rqa-validator>
      <small *ngIf="rqaForm.get('remarks')?.value && rqaForm.get('remarks')?.errors?.values" class="p-error">
        {{ rqaForm.controls.remarks?.errors['values'] }}
      </small>
    </div>
  }
</form>
