import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import {
  validateDecimalLatitude,
  validateDecimalLongitude,
  validateLatitude,
  validateLongitude
} from 'src/app/shared/form/validators';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { CoordinatesFormat } from 'src/app/model/map.model';
import { InputTextModule } from 'primeng/inputtext';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { PointFormGroup, PolyFormGroup } from 'src/app/model/adhoc.model';
import { AdhocService } from 'src/app/services/adhoc.service';

@Component({
  selector: 'rqa-add-poly',
  templateUrl: './add-poly.component.html',
  styleUrls: ['./add-poly.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    TranslateModule,
    ValidatorComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddPolyComponent implements OnInit, OnChanges {
  @Input() coordinatesFormat: CoordinatesFormat;
  @Input() polyForm: PolyFormGroup;
  coordsMaxLength: number = 6;
  private adhocService = inject(AdhocService);
  private formBuilder = inject(FormBuilder);

  get points() {
    return this.polyForm.get('points') as FormArray;
  }

  ngOnInit(): void {
    this.setCoordinatesValidators(this.coordinatesFormat);
  }

  ngOnChanges(changes: SimpleChanges) {
    const coordinatesFormat = changes['coordinatesFormat']?.currentValue;
    this.coordsMaxLength = coordinatesFormat === CoordinatesFormat.DMS ? 6 : 7;
    if (this.polyForm) {
      this.setCoordinatesValidators(coordinatesFormat);
    }
    if (changes['resetForm']?.currentValue) {
      this.clear();
    }
  }

  clear(): void {
    this.polyForm.reset();
    this.adhocService.setPolyToDraw(null);
  }

  draw(): void {
    const coordinates = this.polyForm.getRawValue();
    this.adhocService.setPolyToDraw(coordinates);
  }

  addPoint(index: number) {
    if (this.points.length < 25) {
      const validatorsLength =
        this.coordinatesFormat === CoordinatesFormat.DMS ? Validators.maxLength(6) : Validators.maxLength(7);
      this.points.insert(
        index + 1,
        this.formBuilder.group({
          lat: new FormControl('', [
            Validators.required,
            validatorsLength,
            this.coordinatesFormat === CoordinatesFormat.DMS ? validateLatitude() : validateDecimalLatitude()
          ]),
          lon: new UntypedFormControl('', [
            Validators.required,
            validatorsLength,
            this.coordinatesFormat === CoordinatesFormat.DMS ? validateLongitude() : validateDecimalLongitude()
          ])
        }) as PointFormGroup
      );
    }
  }

  removePoint(index: number) {
    if (this.points.length > 3) {
      this.points.removeAt(index);
    }
  }

  private setCoordinatesValidators(formatType: CoordinatesFormat): void {
    if (formatType === CoordinatesFormat.DMS) {
      this.points.controls.forEach((fg) => {
        fg.get('lat')?.setValidators([Validators.required, validateLatitude(), Validators.minLength(6)]);
        fg.get('lon')?.setValidators([Validators.required, validateLongitude(), Validators.minLength(6)]);
      });
    }
    if (formatType === CoordinatesFormat.DECIMAL) {
      this.points.controls.forEach((fg) => {
        fg.get('lat')?.setValidators([Validators.required, validateDecimalLatitude(), Validators.minLength(7)]);
        fg.get('lon')?.setValidators([Validators.required, validateDecimalLongitude(), Validators.minLength(7)]);
      });
    }
  }
}
