import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  resizeMapSignal: WritableSignal<number> = signal(0);

  setResizeMap(): void {
    this.resizeMapSignal.update((value) => value + 1);
  }
}
