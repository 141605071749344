import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './ui/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeKeycloak } from './core/auth/keycloak-config';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SplitterModule } from 'primeng/splitter';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CustomFilterService } from './services/custom-filter.service';
import { LoaderInterceptor } from './core/loader.interceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserConfigService } from './services/user-config.service';
import { HolidaysInfoComponent } from './ui/holidays-info/holidays-info.component';
import { HeaderInterceptor } from './core/header.interceptor';
import { ApiErrorInterceptor } from './core/api-error.interceptor';
import { MapComponent } from './map/map.component';
import { CommonModule } from '@angular/common';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?cache=' + new Date().valueOf());
}

type TranslationSetupFunction = () => Promise<void>;

function setupTranslateFactory(service: TranslateService): TranslationSetupFunction {
  return () =>
    service
      .use('pl')
      .toPromise()
      .then(() => {});
}

function setupFilterFactory(filterConfigService: CustomFilterService): () => Promise<void> {
  return (): Promise<void> => {
    return filterConfigService.configureFilterService();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    HeaderComponent,
    HttpClientModule,
    SplitterModule,
    ToastModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    MapComponent,
    HolidaysInfoComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DialogService,
    ConfirmationService,
    MessageService,
    CustomFilterService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, UserConfigService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupFilterFactory,
      deps: [CustomFilterService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
