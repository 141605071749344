import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import { layersStyle, setStyleAdditionalLayer } from './layersStyle';
import { AirspaceElement, Coordinates, DYNAMIC_LAYERS_TYPES, FilterLayer, LayersRef } from '../model/map.model';
import * as Turf from '@turf/turf';
import { CookieService } from 'ngx-cookie-service';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogManagerService } from '../services/dialog-manager.service';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonComponent } from '../ui/toggle-button/toggle-button.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IconComponent } from '../ui/icon/icon.component';
import { AccordionModule } from 'primeng/accordion';
import { MapToolbarService } from './map-toolbar/map-toolbar.service';
import { MapToolbarAction, MapToolbarPayload } from './map-toolbar/map-toolbar-actions';
import { Position } from '@turf/turf';
import { SearchMapDialogComponent } from './search-map-dialog/search-map-dialog.component';
import { MapService } from './map.service';

@Component({
  selector: 'rqa-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    LeafletModule,
    IconComponent,
    ToggleButtonComponent,
    TooltipModule,
    AccordionModule,
    MapToolbarComponent
  ]
})
export class MapComponent {
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private cookieService = inject(CookieService);
  private dialogManager = inject(DialogManagerService);
  private toolbarService = inject(MapToolbarService);
  private mapService = inject(MapService);
  destroyRef = inject(DestroyRef);
  ref: DynamicDialogRef;

  isLayerSubmenuVisible: boolean = false;
  iconRetinaUrl = 'assets/marker-icon-2x.png';
  iconUrl = 'assets/marker-icon.png';
  shadowUrl = 'assets/marker-shadow.png';
  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],

      iconUrl: 'assets/img/marker-icon.png',
      shadowUrl: 'assets/img/marker-shadow.png'
    })
  };
  options: L.MapOptions = {
    layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: 'Open Street Map' })],
    zoom: 6,
    minZoom: 5,
    maxZoom: 17,
    center: new L.LatLng(environment.center[0], environment.center[1]),
    zoomControl: false
  };
  visibleLayerGroups: { [key: string]: L.FeatureGroup } = {};
  disabledAreas: string[] = [];
  map: L.Map = {} as L.Map;
  selectedLayers: AirspaceElement[] = [];
  layersRef: LayersRef[] = [];
  currentMarker: L.Marker;
  lastActiveLayer: AirspaceElement;
  mapLayers: AirspaceElement[] = [];
  filterLayerTypes: FilterLayer[] = [];

  constructor() {
    effect(
      () => {
        const action = this.toolbarService.actionSignal();
        if (action) {
          this.handleToolbarAction(action);
          this.toolbarService.clearAction();
        }
        const mapResize = this.mapService.resizeMapSignal();
        if (mapResize) {
          setTimeout(() => {
            this.map.invalidateSize(true);
          }, 300);
        }
      },
      { allowSignalWrites: true }
    );
  }

  onMapReady(map: L.Map): void {
    this.map = map;
    this.displayCoordinates(map);
    setTimeout(() => {
      this.map.invalidateSize(true);
    }, 300);
  }

  clickMap(e: L.LeafletMouseEvent): void {
    this.selectedLayers = [];
    this.addMarker(e.latlng);
    this.resetLayerStyles();
    const findLayers: L.Polygon[] = this.findIntersectingLayers(e.latlng);

    if (findLayers.length === 1) {
      this.updateLayerStyles(findLayers[0]);
    }
  }

  zoomIn(): void {
    this.map.zoomIn();
  }

  zoomOut(): void {
    this.map.zoomOut();
  }

  onSearch(): void {
    this.ref = this.dialogService.open(SearchMapDialogComponent, {
      header: this.translateService.instant('dialogs.searchMap.header'),
      contentStyle: { overflow: 'auto' },
      width: '40rem',
      baseZIndex: 10000
    });
    this.dialogManager.addDialog(this.ref);
    this.ref.onClose.subscribe((data: Coordinates) => {
      if (data) {
        this.addSearchMarker(data);
      }
      this.dialogManager.removeDialog(this.ref);
    });
  }

  trackByFn(index: number, layer: AirspaceElement): number {
    return layer.id;
  }

  toggleLayerGroupVisibility(visible: boolean, groupId: string): void {
    if (!visible) {
      this.disabledAreas.push(groupId);
    } else {
      this.disabledAreas = this.disabledAreas.filter((el) => el !== groupId);
    }
    this.setDisabledAreas();
    const group = this.visibleLayerGroups[groupId];
    if (this.map.hasLayer(group)) {
      group.remove();
    } else {
      group.addTo(this.map);
    }
    this.bringToFrontLayers();
  }

  createLayers(layers: AirspaceElement[]): void {
    this.clearAllLayers();
    this.layersRef = [];
    this.mapLayers = layers;
    const uniqueLayerTypes = [
      ...new Set(layers.filter((layer) => layer.geometry).map((layer) => layer.type.toLowerCase()))
    ];
    this.createLayersGroup(uniqueLayerTypes);
  }

  private clearAllLayers(): void {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.FeatureGroup) {
        this.map.removeLayer(layer);
      }
    });
  }

  private createLayersGroup(layerTypes: string[]): void {
    this.filterLayerTypes = layerTypes.map((type) => {
      const group = new L.FeatureGroup();
      this.addLayersByType(type, group);
      if (group.getLayers().length > 0) {
        group.addTo(this.map);
        this.visibleLayerGroups[type] = group;
        if (this.disabledAreas.includes(type)) {
          group.remove();
        }
      }
      return { type, visible: true };
    });
  }

  private addLayersByType(type: string, group: L.FeatureGroup): void {
    this.mapLayers
      .filter((layer) => layer.type.toLowerCase() === type)
      .forEach((layer) => {
        const createdLayer = this.createLayerForAirspaceElement(layer);
        createdLayer.addTo(group);
        this.layersRef.push({
          type,
          layer: createdLayer,
          name: layer.designator,
          styles: layer.styles
        });
      });
  }

  private createLayerForAirspaceElement(element: AirspaceElement): L.GeoJSON {
    const layer = new L.GeoJSON(element.geometry as Turf.GeometryObject);
    const style = DYNAMIC_LAYERS_TYPES.includes(element.type)
      ? setStyleAdditionalLayer(element.styles.activated)
      : element.styles.activated;
    layer.setStyle({ ...style, className: element.designator });
    layer.setZIndex(element.id);
    layer.on('click', () => this.map.fitBounds(layer.getBounds(), { maxZoom: 8 }));
    return layer;
  }

  protected findIntersectingLayers(latlng: L.LatLng): L.Polygon[] {
    const findLayers: L.Polygon[] = [];
    this.layersRef.forEach((layerRef) => {
      const isEnableType = this.disabledAreas.indexOf(layerRef.type) === -1;
      const layer = layerRef.layer.getLayers()[0];
      if (isEnableType && layer instanceof L.Polygon && layer.feature?.geometry) {
        const clickPoint = Turf.point([latlng.lng, latlng.lat]);
        const coordinates: Position[][] = layer.feature.geometry.coordinates as Position[][];
        try {
          const polygon = Turf.polygon(coordinates);
          if (Turf.booleanPointInPolygon(clickPoint, polygon)) {
            const layerFind: AirspaceElement = this.mapLayers.find(
              (el: AirspaceElement) => el.designator === layer.options.className
            ) as AirspaceElement;
            findLayers.push(layer);
            this.selectedLayers.push(layerFind);
          }
        } catch (error) {
          console.error('Error creating polygon in Turf.js', error);
        }
      }
    });
    return findLayers;
  }

  protected addMarker(latlng: L.LatLng): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    this.currentMarker = L.marker(latlng, this.markerIcon)
      .bindTooltip(`${latlng.lat.toFixed(4)} ${latlng.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
  }

  protected updateLayerStyles(layer: L.Polygon): void {
    if (this.lastActiveLayer) {
      const lastToggledLayer = this.layersRef.find((el) => el.name === this.lastActiveLayer.designator);
      lastToggledLayer?.layer?.setStyle(layersStyle[this.selectedLayers[0].type.toLowerCase()].styles.activated);
    }
    const watchStyle = layersStyle[this.selectedLayers[0].type.toLowerCase()].styles;
    layer.setStyle(watchStyle.highlight);
    layer.bringToFront();
    this.lastActiveLayer = this.selectedLayers[0];
  }

  private addSearchMarker(latLng: Coordinates): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    const latLngConverted = new L.LatLng(latLng.latitude, latLng.longitude);
    this.currentMarker = L.marker(latLngConverted, this.markerIcon)
      .bindTooltip(`${latLngConverted.lat.toFixed(4)} ${latLngConverted.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
    this.map.setView(latLngConverted, 10);
  }

  private displayCoordinates(map: L.Map): void {
    const Coordinates = L.Control.extend({
      onAdd: (mapCoordinates: L.Map) => {
        const container = L.DomUtil.create('div');
        mapCoordinates.addEventListener('mousemove', (e: L.LeafletMouseEvent) => {
          container.innerHTML = `
            <div class='latlngInfo'>
            ${this.translateService.instant('map.coordinates')}<br>
            ${this.translateService.instant('map.latitude')}: <strong>${e.latlng.lat.toFixed(4)}</strong>
            ${this.translateService.instant('map.longitude')}: <strong>${e.latlng.lng.toFixed(4)}</strong>  
            </div>
            `;
        });
        return container;
      }
    });
    map.addControl(new Coordinates({ position: 'bottomright' }));
  }

  private setDisabledAreas(): void {
    this.cookieService.set(
      'inactiveAreas',
      JSON.stringify(Array.from(new Set(this.disabledAreas))),
      365,
      '/',
      '',
      true,
      'Strict'
    );
  }

  protected resetLayerStyles(): void {
    this.layersRef.forEach((layerRef) => {
      if (DYNAMIC_LAYERS_TYPES.includes(layerRef.type.toUpperCase())) {
        layerRef.layer.setStyle(setStyleAdditionalLayer(layerRef.styles.activated));
      } else {
        layerRef.layer.setStyle(layerRef.styles.activated); // default style
      }
    });
  }

  protected getDisabledAreas(): void {
    this.disabledAreas = this.cookieService.get('inactiveAreas')
      ? Array.from(new Set(JSON.parse(this.cookieService.get('inactiveAreas'))))
      : [];
  }

  private bringToFrontLayers(): void {
    this.layersRef.forEach((el) => {
      el.layer.bringToFront();
    });
  }

  private handleToolbarAction(event: { action: MapToolbarAction; payload?: MapToolbarPayload }): void {
    switch (event.action) {
      case 'zoomIn':
        this.zoomIn();
        break;
      case 'zoomOut':
        this.zoomOut();
        break;
      case 'search':
        this.onSearch();
        break;
      case 'toggleLayerGroup':
        if (event.payload) {
          this.toggleLayerGroupVisibility(event.payload.visible, event.payload.groupId);
        }
        break;
    }
  }
}
