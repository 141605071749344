import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { Table, TableModule, TableRowExpandEvent } from 'primeng/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { FilterService, MessageService } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { RouterModule } from '@angular/router';
import { MessagesService } from 'src/app/services/messages.service';
import { MessageDTO, MessageType, MessageTypeOptions } from 'src/app/model/message-details';
import * as moment from 'moment';
import { DateService } from 'src/app/services/date.service';
import { DATE_TIME_FORMAT } from 'src/app/shared/constants/date.constants';

@Component({
  selector: 'rqa-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    TableModule,
    TranslateModule,
    CalendarModule,
    SelectButtonModule,
    IconComponent,
    RouterModule,
    ProgressBarModule
  ]
})
export class MessagesComponent implements OnInit {
  @ViewChild('dt') pTable!: Table;
  private messagesService = inject(MessagesService);
  private messageService = inject(MessageService);
  private filterService = inject(FilterService);
  private translateService = inject(TranslateService);
  private dateService = inject(DateService);
  messages: MessageDTO[];
  selectedMessages: MessageDTO[] = [];
  messageType = MessageType;
  dateRange: Date[];
  loadDetailsIdx: number | null;
  messageOptions: MessageTypeOptions[] = [
    {
      label: this.translateService.instant('messages.messagesFilter'),
      value: MessageType.MESSAGE
    },
    {
      label: this.translateService.instant('messages.notificationsFilter'),
      value: MessageType.NOTIFICATION
    }
  ];
  selectedFilterType: MessageType[] = [];

  ngOnInit(): void {
    this.getMessages();
    this.registerDateRangeFilter();
    this.translateService.onLangChange.subscribe(() => {
      this.messageOptions = [
        {
          label: this.translateService.instant('messages.messagesFilter'),
          value: MessageType.MESSAGE
        },
        {
          label: this.translateService.instant('messages.notificationsFilter'),
          value: MessageType.NOTIFICATION
        }
      ];
    });
  }

  onRemoveSelected(): void {
    const uuids: string[] = this.selectedMessages.map((el) => el.uuid);
    this.messagesService.delete(uuids).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('notifications.deletedMessages'),
          life: 3000
        });
        this.messages = this.messages.filter((val) => !this.selectedMessages?.includes(val));
        this.selectedMessages = [];
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('notifications.deleteError'),
          life: 3000
        });
      }
    });
  }

  onRowToggle(event: TableRowExpandEvent): void {
    if (event.data && event.data.uuid) {
      this.loadDetails(event.data);
    }
  }

  loadDetails(message: MessageDTO): void {
    const msgIdx = this.messages.findIndex((msg) => msg.uuid === message.uuid);
    const changeCount = !message.read ? -1 : 0;
    this.loadDetailsIdx = msgIdx;
    this.messagesService.loadDetails(message.uuid).subscribe((details) => {
      this.messages[msgIdx] = {
        ...this.messages[msgIdx],
        read: true,
        details: details.text
      };
      this.loadDetailsIdx = null;
      this.messagesService.notifyUnreadCountChange(changeCount);
      if (this.selectedFilterType.length) {
        this.filterByType();
      }
      this.filterByDate();
    });
  }

  filterByDate(): void {
    if (this.dateRange?.length && this.dateRange[1] !== null) {
      const startDate = moment(this.dateRange[0]).startOf('day').format(DATE_TIME_FORMAT);
      const endDate = moment(this.dateRange[1]).endOf('day').format(DATE_TIME_FORMAT);
      this.pTable.filter({ start: startDate, end: endDate }, 'recvTime', 'dateRangeFilter');
    }
  }

  clearFilters(): void {
    this.dateRange = [];
    delete this.pTable.filters['recvTime'];
    this.pTable._filter();
  }

  filterByType(): void {
    if (this.selectedFilterType.length) {
      this.pTable.filter(this.selectedFilterType, 'type', 'in');
    } else {
      this.pTable.filter('NOTVALID', 'type', 'equals');
    }
  }

  private getMessages(): void {
    this.messagesService.getMessages().subscribe((res) => {
      this.messages = res.map((msg) => ({
        ...msg,
        recvTime: this.dateService.parseIsoDateToString(msg.recvTime as string)
      }));
    });
  }

  registerDateRangeFilter() {
    this.filterService.register('dateRangeFilter', (value: Date, filter: { start: Date; end: Date }): boolean => {
      if (!filter || !filter.start || !filter.end || !value) {
        return true;
      }

      const valueDate = new Date(value);
      const startDate = new Date(filter.start);
      const endDate = new Date(filter.end);
      return valueDate >= startDate && valueDate <= endDate;
    });
  }
}
