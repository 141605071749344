<form [formGroup]="dateAndAltitudes">
  <div class="flex flex-row gap-2 mb-2 align-items-center" formGroupName="datesGroup">
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lat">{{ 'adhoc.timeFrom' | translate }}</label>
      <rqa-calendar formControlName="startDate" [minDate]="minDate" [showTime]="true" [hideOnDateTimeSelect]="false" />
      <div class="h-1rem">
        <rqa-validator [control]="dateAndAltitudes.get('datesGroup')?.get('startDate')"></rqa-validator>
        <rqa-validator [formGroup]="dateAndAltitudes.get('datesGroup')"></rqa-validator>
      </div>
    </div>
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lon">{{ 'adhoc.timeTo' | translate }}</label>
      <rqa-calendar formControlName="endDate" [minDate]="minDate" [showTime]="true" [hideOnDateTimeSelect]="false" />
      <rqa-validator [control]="dateAndAltitudes.get('datesGroup')?.get('endDate')" class="h-1rem"></rqa-validator>
    </div>
  </div>
  <div class="flex flex-row gap-2 mb-2 align-items-center" formGroupName="altitudesGroup">
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lat">{{ 'adhoc.heightFrom' | translate }}</label>
      <p-autoComplete
        appendTo="body"
        [suggestions]="filteredAltitudes"
        formControlName="lowerAltitude"
        (completeMethod)="searchAltitude($event, 'lowerAltitude')"
        [forceSelection]="true"
        [completeOnFocus]="true"
      ></p-autoComplete>
      <div class="h-1rem">
        <rqa-validator
          [control]="dateAndAltitudes.get('altitudesGroup')?.get('lowerAltitude')"
          class="h-1rem"
        ></rqa-validator>
        <rqa-validator [formGroup]="dateAndAltitudes.get('altitudesGroup')"></rqa-validator>
      </div>
    </div>
    <div class="flex flex-column gap-2 lg:w-5">
      <label for="lon">{{ 'adhoc.heightTo' | translate }}</label>
      <p-autoComplete
        appendTo="body"
        formControlName="upperAltitude"
        [suggestions]="filteredAltitudes"
        (completeMethod)="searchAltitude($event, 'upperAltitude')"
        [forceSelection]="true"
        [completeOnFocus]="true"
      ></p-autoComplete>
      <rqa-validator
        [control]="dateAndAltitudes.get('altitudesGroup')?.get('upperAltitude')"
        class="h-1rem"
      ></rqa-validator>
    </div>
  </div>
</form>
