import { Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'rqa-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [CalendarModule, ButtonModule, InputNumberModule, TranslateModule, FormsModule, DatePipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RqaCalendarComponent),
      multi: true
    }
  ]
})
export class RqaCalendarComponent implements ControlValueAccessor {
  @ViewChild('calendar') calendar!: Calendar;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() showTime: boolean = false;
  @Input() touchUI: boolean = false;
  @Input() dateFormat: string = 'yy-mm-dd';
  @Input() showIcon: boolean = true;
  @Input() iconDisplay: string = 'input';
  @Input() appendTo: string = 'body';
  @Input() hideOnDateTimeSelect: boolean = true;

  value: Date | null = null;
  disabled: boolean = false;

  private tempValue: Date | null = null;

  private onChange = (value: Date | null) => {};
  private onTouched = () => {};

  constructor(private translate: TranslateService) {}

  updateTime(value: string, type: 'hours' | 'minutes') {
    if (this.value) {
      const date = new Date(this.value);
      const numValue = parseInt(value);

      if (!isNaN(numValue)) {
        if (type === 'hours') {
          if (numValue >= 0 && numValue <= 23) {
            date.setHours(numValue);
          }
        } else {
          if (numValue >= 0 && numValue <= 59) {
            date.setMinutes(numValue);
          }
        }
        this.value = date;
        this.onChange(date);
      }
    }
  }

  onModelChange(value: Date) {
    this.value = value;
    this.onChange(value);
  }

  // ControlValueAccessor implementation
  writeValue(value: Date): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onCancelCalendar() {
    if (this.tempValue) {
      this.value = this.tempValue;
      this.onChange(this.tempValue);
    }
    this.calendar.hideOverlay();
  }

  onConfirmCalendar() {
    this.tempValue = this.value;
    this.calendar.hideOverlay();
  }
}
