import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rqa-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss',
  imports: [CommonModule, TranslateModule],
  standalone: true
})
export class ToggleButtonComponent {
  @Input() label: string;
  @Input() checked: boolean;
  @Input() className: string;
  @Output() changed = new EventEmitter<boolean>();
}
