import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { CoordinatesFormat } from 'src/app/model/map.model';
import { InputTextModule } from 'primeng/inputtext';
import { ValidatorComponent } from 'src/app/ui/validator/validator.component';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { Altitudes } from 'src/app/model/altitudes.model';
import { AdhocService } from 'src/app/services/adhoc.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { DateService } from 'src/app/services/date.service';
import { DateAndAltitudesFormGroup } from 'src/app/model/adhoc.model';
import { RqaCalendarComponent } from 'src/app/ui/calendar/calendar.component';
@Component({
  selector: 'rqa-add-times',
  templateUrl: './add-times.component.html',
  styleUrls: ['./add-times.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    AutoCompleteModule,
    InputTextModule,
    TranslateModule,
    ValidatorComponent,
    RqaCalendarComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class AddTimesComponent implements OnInit {
  @Input() coordinatesFormat: CoordinatesFormat;
  @Input() dateAndAltitudes: DateAndAltitudesFormGroup;
  form: FormGroup;
  altitudesEnum = Altitudes;
  altitudes: string[];
  filteredAltitudes: string[];
  minDate: Date;
  minTime: Date;
  private adhocService = inject(AdhocService);
  private reservationService = inject(ReservationService);
  private dateService = inject(DateService);

  ngOnInit(): void {
    this.altitudes = Object.keys(this.altitudesEnum).filter((k) => isNaN(Number(k)));
    this.adhocService.getMinTime().subscribe((response) => {
      this.minDate = this.dateService.parseIsoDateToDateObj(response.value);
    });
    this.reservationService.getReservableInterval().subscribe((value) => {
      this.minTime = this.dateService.parseIsoDateToDateObj(value.startDate);
    });
  }

  searchAltitude(event: AutoCompleteCompleteEvent, field: 'lowerAltitude' | 'upperAltitude'): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
    if (this.filteredAltitudes.length === 1) {
      this.form.get(field)?.setValue(this.filteredAltitudes[0]);
    }
  }
}
