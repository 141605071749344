<header class="flex align-items-center justify-content-between px-5 page-header">
  <div class="left-menu flex gap-5 justify-content-between align-items-center">
    <div>
      <img src="../../../assets/svg/pansa-logo.svg" alt="Pansa logo" />
    </div>
    <rqa-navbar [items]="menuItems"></rqa-navbar>
  </div>
  <!-- RIGHT MENU -->
  <rqa-right-navbar class="hidden lg:block"></rqa-right-navbar>
</header>
